import React from "react"
import PropTypes from "prop-types"
import useUUID from '../../hooks/useUUID'
import useImage from '../../hooks/useImage'
import { convertToId } from "../../helpers/utils"
import Image from "../UI/Image/Image"
import RichTextModel from "../RichTextModel/RichTextModel"
import { PILLARS_SECTION_TITLE_WITH_BG, PILLARS_SECTION_TITLE } from "../RichTextModel/RichTextModel-tw-styles"
import PillarCard from './PillarCard/PillarCard'
import { defaultStyles as styles } from "./Pillars-tw-styles"

const Pillars = props => {
    const { stickyMenuItem, title, logoImg, textWithBg, cardsCollection } = props
    const { foregroundImg } = useImage(logoImg)

    const menuId = stickyMenuItem && convertToId(stickyMenuItem?.title, true)

    const cards = Array.isArray(cardsCollection?.items) ? cardsCollection.items : []
    const cardsKeys = useUUID(cards.length)

    return (
        <section id={menuId} className={styles?.wrapper}>
            <div className={styles?.background} />

            <div className={styles?.container}>
                <RichTextModel {...title} style={PILLARS_SECTION_TITLE} />

                <div className={styles?.logoWrapper}>
                    <Image contentfulImage={foregroundImg} className={styles?.logoImage} />
                    <RichTextModel richText={textWithBg} style={PILLARS_SECTION_TITLE_WITH_BG} />
                </div>

                <div className={styles?.cardsContainer}>
                    {cards.map((card, index) => (
                        <PillarCard key={cardsKeys[index]} {...card} />
                    ))}
                </div>
            </div>
        </section>
    )
}

Pillars.propTypes = {
    stickyMenuItem: PropTypes.object,
    title: PropTypes.object,
    logoImg: PropTypes.object,
    textWithBg: PropTypes.object,
    cardsCollection: PropTypes.object,
}

export default Pillars
