import * as React from "react"

const VideoIcon = props => (
    <svg
        width="22" height="18" viewBox="0 0 22 18" fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path fillRule="evenodd" clipRule="evenodd"
              d="M1.75 1.854C1.61193 1.854 1.5 1.96593 1.5 2.104V16.104C1.5 16.2421 1.61192 16.354 1.75 16.354H19.75C19.8881 16.354 20 16.2421 20 16.104V2.104C20 1.96593 19.8881 1.854 19.75 1.854H1.75ZM0 2.104C0 1.13751 0.783501 0.354004 1.75 0.354004H19.75C20.7165 0.354004 21.5 1.13751 21.5 2.104V16.104C21.5 17.0705 20.7165 17.854 19.75 17.854H1.75C0.783509 17.854 0 17.0705 0 16.104V2.104Z"
              fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.75 0.354004C17.1642 0.354004 17.5 0.68979 17.5 1.104V17.104C17.5 17.5182 17.1642 17.854 16.75 17.854C16.3358 17.854 16 17.5182 16 17.104V1.104C16 0.68979 16.3358 0.354004 16.75 0.354004Z"
              fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M4.75 0.354004C5.16421 0.354004 5.5 0.68979 5.5 1.104V17.104C5.5 17.5182 5.16421 17.854 4.75 17.854C4.33579 17.854 4 17.5182 4 17.104V1.104C4 0.68979 4.33579 0.354004 4.75 0.354004Z"
              fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M17 6.104C17 5.68979 17.3358 5.354 17.75 5.354H20.75C21.1642 5.354 21.5 5.68979 21.5 6.104C21.5 6.51822 21.1642 6.854 20.75 6.854H17.75C17.3358 6.854 17 6.51822 17 6.104Z"
              fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M17 12.104C17 11.6898 17.3358 11.354 17.75 11.354H20.75C21.1642 11.354 21.5 11.6898 21.5 12.104C21.5 12.5182 21.1642 12.854 20.75 12.854H17.75C17.3358 12.854 17 12.5182 17 12.104Z"
              fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0 6.104C0 5.68979 0.335786 5.354 0.75 5.354H3.75C4.16421 5.354 4.5 5.68979 4.5 6.104C4.5 6.51822 4.16421 6.854 3.75 6.854H0.75C0.335786 6.854 0 6.51822 0 6.104Z"
              fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0.75 4.354C1.16421 4.354 1.5 4.68979 1.5 5.104V7.104C1.5 7.51822 1.16421 7.854 0.75 7.854C0.335786 7.854 0 7.51822 0 7.104V5.104C0 4.68979 0.335786 4.354 0.75 4.354Z"
              fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M2.5 1.104C2.5 0.68979 2.83579 0.354004 3.25 0.354004H6.25C6.66421 0.354004 7 0.68979 7 1.104C7 1.51822 6.66421 1.854 6.25 1.854H3.25C2.83579 1.854 2.5 1.51822 2.5 1.104Z"
              fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M2.5 17.104C2.5 16.6898 2.83579 16.354 3.25 16.354H6.25C6.66421 16.354 7 16.6898 7 17.104C7 17.5182 6.66421 17.854 6.25 17.854H3.25C2.83579 17.854 2.5 17.5182 2.5 17.104Z"
              fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M14.5 1.104C14.5 0.68979 14.8358 0.354004 15.25 0.354004H18.25C18.6642 0.354004 19 0.68979 19 1.104C19 1.51822 18.6642 1.854 18.25 1.854H15.25C14.8358 1.854 14.5 1.51822 14.5 1.104Z"
              fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M14.5 17.104C14.5 16.6898 14.8358 16.354 15.25 16.354H18.25C18.6642 16.354 19 16.6898 19 17.104C19 17.5182 18.6642 17.854 18.25 17.854H15.25C14.8358 17.854 14.5 17.5182 14.5 17.104Z"
              fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0 12.104C0 11.6898 0.335786 11.354 0.75 11.354H3.75C4.16421 11.354 4.5 11.6898 4.5 12.104C4.5 12.5182 4.16421 12.854 3.75 12.854H0.75C0.335786 12.854 0 12.5182 0 12.104Z"
              fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0.75 10.354C1.16421 10.354 1.5 10.6898 1.5 11.104V13.104C1.5 13.5182 1.16421 13.854 0.75 13.854C0.335786 13.854 0 13.5182 0 13.104V11.104C0 10.6898 0.335786 10.354 0.75 10.354Z"
              fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M20.75 10.354C21.1642 10.354 21.5 10.6898 21.5 11.104V13.104C21.5 13.5182 21.1642 13.854 20.75 13.854C20.3358 13.854 20 13.5182 20 13.104V11.104C20 10.6898 20.3358 10.354 20.75 10.354Z"
              fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M20.75 4.354C21.1642 4.354 21.5 4.68979 21.5 5.104V7.104C21.5 7.51822 21.1642 7.854 20.75 7.854C20.3358 7.854 20 7.51822 20 7.104V5.104C20 4.68979 20.3358 4.354 20.75 4.354Z"
              fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8.88642 5.94802C9.12489 5.81585 9.4163 5.8235 9.6475 5.96801L13.6475 8.46801C13.8668 8.60506 14 8.84541 14 9.104C14 9.3626 13.8668 9.60295 13.6475 9.74L9.6475 12.24C9.4163 12.3845 9.12489 12.3922 8.88642 12.26C8.64796 12.1278 8.5 11.8766 8.5 11.604V6.604C8.5 6.33136 8.64796 6.08019 8.88642 5.94802ZM10 7.95719V10.2508L11.8349 9.104L10 7.95719Z"
              fill="currentColor" />
    </svg>
)

export default VideoIcon
