export const defaultStyle = {
    wrapper: "text-primary",
    heading: "lg:max-w-[50%] lg:mx-auto font-bold text-center text-[24px] leading-[36px] normal-case",
    perex: "mt-[40px] mb-[5px] font-medium text-[14px] leading-[21px] md:text-[18px] md:leading-[27px] text-left md:text-center",
    form: "md:mt-[40px]",
    inputRow: "flex flex-wrap md:flex-nowrap md:gap-[24px] md:mt-[20px] first-of-type:mt-0",
    inputCol: "w-full md:w-[50%] mt-[20px] md:mt-0",
    inputText:
        "w-full py-[12px] pr-[45px] pl-[20px] mt-[10px] border-[2px] border-primary border-solid rounded-[30px] bg-transparent font-semibold text-[16px] leading-[24px]",
    inputError: "!border-cranberry",
    textLabel: "block font-medium text-[12px] leading-[18px] cursor-pointer",
    selectsContainer: "flex gap-[12px]",
    selectWrapper: "custom-select relative w-full mt-[10px]",
    select: "relative w-full py-[14px] pr-[40px] pl-[20px] border-[2px] border-primary border-solid rounded-[30px] bg-transparent appearance-none font-bold text-[14px] leading-[20px] z-[1] cursor-pointer",
    checkboxWrapper: "flex pl-[20px] md:px-[30px] xl:px-[60px] first-of-type:mt-[40px] mt-[20px]",
    checkboxLabel: "ml-[13px] md:ml-[24px] font-medium text-[12px] leading-[18px] cursor-pointer",
    disclaimer: "mt-[40px] mb-[20px] font-medium text-[12px] leading-[18px]",
    captchaWrapper: "flex justify-center",
    submitButtonWrapper: "text-center",
    submitButton: "btn-primary min-w-[218px] md:min-w-[160px] mt-[40px]",
    errorMsg: "mt-[5px] text-cranberry font-bold text-[12px] leading-[18px]",
    thanksWrapper: "text-center",
}
