const query = (id, isPreview) => `
{
    statistics(id: "${id}", preview: ${isPreview}) {
        stickyMenuItem {
            title
        }
        mainTitle
        descriptionPercentageStart
        descriptionPercentageEnd
        descriptionTitle
        descriptionText
        descriptionImg {
            altText
            imageDesktop {
                title
                description
                url
                width
                height
            }
            imageTablet {
                title
                description
                url
                width
                height
            }
            imageMobile {
                title
                description
                url
                width
                height
            }
        }
        tonsStart
        tonsEnd
        tonsText
        farmersStart
        farmersEnd
        farmersText
        hectaresStart
        hectaresEnd
        hectaresText
        biodiversityStart
        biodiversityEnd
        biodiversityText
        bees
        beesText
        andText
        butterflies
        butterfliesText
        fallowsText
        tabsCollection {
            items {
                sys {
                    id
                }
                label
                iconLabel
                iconImg {
                    altText
                    imageDesktop {
                        title
                        description
                        url
                        width
                        height
                    }
                    imageTablet {
                        title
                        description
                        url
                        width
                        height
                    }
                    imageMobile {
                        title
                        description
                        url
                        width
                        height
                    }
                }
                columnsCollection {
                    items {
                        sys {
                            id
                        }
                        name
                        content  {
                            json
                        }
                    }
                }
            }
        }
        tabsImg {
            altText
            imageDesktop {
                title
                description
                url
                width
                height
            }
            imageTablet {
                title
                description
                url
                width
                height
            }
            imageMobile {
                title
                description
                url
                width
                height
            }
        }
        accordion {
            name
            content {
                json
            }
            readMoreContent
            readMoreContentLong
            desktopContent {
                json
            }
            variant
        }
        disclaimer  {
            json
        }
    }
}
`
module.exports = { query }
