export const defaultStyle = {
    Listcontainer: "Listcontainer w-full lg:w-auto lg:inline-block px-10",
    footerlinsMob: "text-center mx-[40px]",
    listmob: "py-20",
    className2: "lg:inline-block lg:w-auto w-full",
    className3: "text-[0.70rem] proximaNova-Regular,serif text-slate-50 px-2",
    className4:
        "text-[0.70rem] proximaNova-Regular,serif lg:before:content-['/'] before:pr-4 before:text-blue-500 text-slate-50 px-2 ",
}

export const footerMainLinks = {
    Listcontainer: "",
    footerlinsMob: "text-center",
    listmob: "flex gap-[48px] max-md:gap-[24px] max-md:flex-col max-md:mt-[48px] md:justify-center",
    listLink: "text-[20px] leading-[150%]",
}

export const footerHelpLinks = {
    Listcontainer: "",
    footerlinsMob: "",
    listmob: "flex gap-[24px] flex-col md:text-right",
    listLink: "text-[20px] leading-[150%] font-semibold",
}

export const socialLinks = {
    Listcontainer: "Listcontainer w-full lg:w-auto lg:inline-block px-10",
    footerlinsMob: "text-center mx-[40px]",
    listmob: "py-20",
    className2: "lg:inline-block lg:w-auto w-full",
    className3: "text-[0.70rem] proximaNova-Regular,serif text-slate-50 px-2",
    className4:
        "text-[0.70rem] proximaNova-Regular,serif lg:before:content-['/'] before:pr-4 before:text-blue-500 text-slate-50 px-2 ",
}

export const brandLinks = {
    Listcontainer: "Listcontainer w-full lg:w-[175px] lg:inline-block mx-auto my-0",
    footerlinsMob: "text-center mx-[40px]",
    listmob: "pt-16 pb-6",
}
