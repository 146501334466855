export const defaultStyles = {
    wrapper: "pillars-section-wrapper relative z-[1]",
    background:
        "pillars-section-bg sticky top-0 w-full h-[100vh] bg-pillars-normal max-md:bg-pillars-mobile max-slg:bg-pillars-tablet xlg:bg-pillars-big bg-no-repeat bg-top bg-[length:100%_100%] z-[-1]",
    container:
        "pillars-section-content w-full slg:max-[1280px]:max-w-[900px] max-w-[1140px] xlg:max-w-[1440px] xlg:pb-[96px] slg:pb-[48px] mx-auto",

    logoWrapper: "flex justify-center",
    logoImage: "w-[101px] h-[101px] md:w-[116px] md:h-[116px] slg:w-[145px] slg:h-[145px] xlg:w-[174px] xlg:h-[174px]",

    cardsContainer:
        "flex flex-wrap items-end gap-x-[4.2%] xlg:gap-x-[8.2%] gap-y-[98px] mlg:gap-y-[150px] xlg:gap-y-[96px] max-mlg:max-w-[539px] max-mlg:px-[16px] mx-auto mt-[120px] md:mt-[96px] mlg:mt-[80px]",
}
