export const defaultStyle = {
    ingredientsText:
        "flex flex-wrap text-[15px] ingredientsText mt-40px mb-20px bg-white  lg:w-[1170px] mx-auto py-20 opacity-[.4] text-left lg:max-w-[94%] px-[6%]",
    richTextWrapper:
        "text-left text-[15px] text-darkBlue opacity-[.4] mb-20px lg:w-[972px] my-[45px] lg:mx-auto mx-15 leading-5",
    title: "text-center text-25 leading-25 py-25 tracking-[1px]",
}

export const FOOT_NOTE = {
    richTextWrapper:
        "text-left mb-20px lg:w-[972px] lg:my-[45px] lg:mb-[85px] m-10 lg:mx-auto my-0 opacity-50 text-15 tracking-[0] leading-[20px] md:mx-40 ",
}

export const COOKIES_INFO = {
    richTextWrapper:
        "container lg:w-[90%] xl:w-9/12 mx-auto lg:px-[30px] py-[0px] last:pt-[0] first:pb-[30px] md:pt-[50px] md:pb-[30px] text-primary",
    title: "",
    heading1: "",
    heading2: "text-[28px] leading-[42px] font-bold lg:px-0 py-[30px] normal-case	",
    heading3: "",
    heading4: "text-[22px] leading-[33px] font-medium lg:px-[120px] normal-case	",
    paragraph: "text-[14px] leading-[21px] md:px-[20px] md:px-[60px] lg:px-[120px] py-[10px]",
    ulList: " list-disc text-[14px] leading-[21px] px-[45px] lg:px-[145px] py-[10px]",
    olList: "list-decimal text-[14px] leading-[21px] px-[45px] lg:px-[145px] py-[10px]",
    mainLink: "text-white",
}

export const ERROR_PAGE = {
    richTextWrapper: "text-center mb-[120px]",
    paragraph: "text-[20px] slg:text-[24px] leading-[150%] font-medium mb-[48px]",
    mainLink: "underline font-bold text-[24px] leading-[150%]",
}

export const NOTICE_SECTION = {
    richTextWrapper: "w-full max-w-[954px] mx-auto text-primary px-[16px] mb-[48px]",
    title: "style-h5 mb-[8px]",
    heading1: "style-h5 mb-[8px]",
    heading2: "style-h5 mb-[8px]",
    heading3: "style-h5 mb-[8px]",
    heading4: "style-h5 mb-[8px]",
    paragraph: "body-s mb-[48px]",
    ulList: " list-disc body-s px-[20px] mb-[48px]",
    olList: "list-decimal body-s px-[20px] mb-[48px]",
    mainLink: "text-primary",
}

export const HOMEPAGE_HERO = {
    richTextWrapper:
        "anim-title / mlg:body-xl max-mlg:body-m mlg:max-w-[934px] max-mlg:max-w-[500px] mx-auto",
}

export const CIRCLE_TEXT = {
    richTextWrapper: "max-w-[280px] md:max-w-[295px] slg:max-w-[360px]",
    paragraph: "text-[12px] md:text-[14px] slg:text-[16px] leading-[150%] font-medium text-center"
}

export const CIRCLE_SECTION_TITLE = {
    richTextWrapper: "typewriter / flex flex-col rotate-[-7.08deg] max-w-[320px] slg:max-w-[502px] mx-auto font-bite " +
        "[&_>_p_+_p]:mt-[5px] [&_>_p_+_p]:ml-[10px]",
    paragraph: "p-[0_25px] bg-primary text-white tracking-[0.01em] text-[21px] slg:text-[40px] leading-[150%] flex self-start"
}

export const SECTION_TITLE = {
    richTextWrapper: "slg:pt-[80px] slg:pb-[72px] max-slg:py-[48px] max-md:pt-[72px] max-md:pb-[48px] mx-auto max-w-[1440px] " +
        "text-center max-slg:px-[16px]",
    heading1: "anim-title / xlg:text-[64px] xlg:leading-[100%] style-h2",
    heading2: "anim-title / xlg:text-[64px] xlg:leading-[100%] style-h2",
    heading3: "anim-title / xlg:text-[64px] xlg:leading-[100%] style-h2",
    paragraph: "anim-title / mlg:max-w-[450px] mx-auto body-l max-mlg:pt-[24px] pt-[32px]",
}

export const PILLARS_SECTION_TITLE = {
    ...SECTION_TITLE,
    richTextWrapper:
        "slg:pt-[80px] slg:pb-[32px] max-slg:py-[48px] max-md:pt-[72px] max-md:pb-[48px] mx-auto max-w-[1440px] " +
        "text-center max-slg:px-[16px]",
    paragraph: "anim-title / mx-auto body-xl mlg:!font-normal pt-[24px] mlg:pt-[32px]",
}

export const PILLARS_SECTION_TITLE_WITH_BG = {
    richTextWrapper:
        "flex flex-col rotate-[-7.08deg] max-w-[320px] slg:max-w-[502px] font-bite [&_>_p_+_p]:mt-[5px] ml-[-30px] xlg:ml-[-26px] md:mt-[5px] slg:mt-[-4px] z-[-1]",
    paragraph:
        "p-[0_25px_0_40px] bg-primary text-white tracking-[0.01em] text-[21px] slg:text-[32px] xlg:text-[40px] leading-[150%] flex self-start",
}

export const PILLAR_CARD_DESCRIPTION = {
    richTextWrapper: "",
    heading1: "text-[18px] mlg:text-[20px] leading-[150%] font-bold font-poppins md:text-center",
    heading2: "text-[18px] mlg:text-[20px] leading-[150%] font-bold font-poppins md:text-center",
    heading3: "text-[18px] mlg:text-[20px] leading-[150%] font-bold font-poppins md:text-center",
    heading4: "text-[18px] mlg:text-[20px] leading-[150%] font-bold font-poppins md:text-center",
    paragraph:
        "mt-[8px] md:mt-[16px] text-[16px] leading-[150%] font-medium font-poppins text-ellipsis overflow-hidden whitespace-nowrap",
}

export const PILLAR_CARD_DESCRIPTION_EXPANDED = {
    ...PILLAR_CARD_DESCRIPTION,
    paragraph: "mt-[8px] md:mt-[16px] text-[16px] leading-[150%] font-medium font-poppins",
}

export const YELLOW_BACKGROUND = {
    richTextWrapper: "frame-divider-yellow / relative bg-secondary slg:pt-[80px] xlg:pb-[72px] slg:pb-[152px] " +
        "max-slg:pt-[48px] max-slg:pb-[144px] text-center max-slg:px-[16px] max-md:bg-[0_250px] max-slg:bg-[50px_200px] " +
        "max-slg:bg-[length:188px]",
    heading1: "anim-title / xlg:text-[64px] xlg:leading-[100%] style-h2",
    heading2: "anim-title / xlg:text-[64px] xlg:leading-[100%] style-h2",
    heading3: "anim-title xlg:text-[64px] xlg:leading-[100%] style-h2",
    paragraph: "anim-title / slg:max-w-[710px] mx-auto slg:text-[24px] text-[18px] leading-[150%] font-semibold max-mlg:pt-[24px] pt-[32px]",
    decorImage: "decor / absolute bottom-[60px] left-[10%]",
}

export const SECTION_TITLE_SMALLER_PADDINGS = {
    richTextWrapper: "slg:pt-[96px] slg:pb-[48px] max-slg:pt-[48px] max-slg:pb-[24px] max-md:pt-[72px] max-md:pb-[48px] mx-auto max-w-[1440px] " +
        "text-center max-slg:px-[16px]",
    heading1: "anim-title / xlg:text-[64px] xlg:leading-[100%] style-h2",
    heading2: "anim-title / xlg:text-[64px] xlg:leading-[100%] style-h2",
    heading3: "anim-title / xlg:text-[64px] xlg:leading-[100%] style-h2",
}
