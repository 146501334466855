import React from "react"
import PropTypes from "prop-types"
import * as styles from "./CarouselSlide-tw-styles"
import ImageCard from "../ImageCard/ImageCard"
import Link from "../Link/Link"
import Typography from "../Typography/Typography"
import { get } from "../../../helpers/utils"
import "./css/slider.css"
import Image from "../Image/Image"
import { gaEventClasses } from "../../../constants/gtm.constants"

const CarouselSlide = props => {
    const { cardsCollection = {}, text, title, variant, link, slideTitle, slideText } = props
    const style = styles[variant] || styles.defaultStyle
    const cards = get(cardsCollection, "items", [])

    return (
        <div className={style?.container}>
            <div className={style?.imageContainer}>
                {title && <Typography className={style?.heading} content={title} />}
                {text && <Typography className={style?.description} content={text} />}
                {cards.length > 0 && (
                    <ul className={style?.cardsWrapr}>
                        {cards?.map((card, index) => {
                            return (
                                <li className={style?.liWrpr} key={index}>
                                    {card.link ? (
                                        <Link
                                            {...card.link}
                                            gaEventClass={gaEventClasses.button_click}
                                            className={style?.linkWrpr}
                                        >
                                            <span className={style?.gradientTop}></span>
                                            <ImageCard {...card} />
                                        </Link>
                                    ) : (
                                        <ImageCard {...card} />
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                )}
            </div>
            <div className={style?.bgImgWrper}></div>
            <div className={style?.textContainer}>
                {slideTitle && <Typography className={style?.textTitle} content={slideTitle} />}
                {slideText && <Typography className={style?.textSubTitle} content={slideText} />}
            </div>

            <div className={style?.roundLinkWrpr}>{link?.image && <Image contentfulImage={link.image} />}</div>
        </div>
    )
}

CarouselSlide.propTypes = {
    cardsCollection: PropTypes.object,
    text: PropTypes.string,
    title: PropTypes.string,
    variant: PropTypes.string,
    link: PropTypes.object,
    slideTitle: PropTypes.string,
    slideText: PropTypes.string,
}

export default CarouselSlide
