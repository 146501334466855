import * as React from "react"

const Pinterest = (className, name) => (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} data-action-detail={name}>
        <title>{"Pinterest"}</title>
        <path d="M20.8584 0C9.83579 0 0.900208 8.95447 0.900208 19.9999C0.900208 28.473 6.16072 35.7164 13.5852 38.6305C13.4106 37.048 13.253 34.6144 13.6545 32.8869C14.0171 31.3261 15.9948 22.9456 15.9948 22.9456C15.9948 22.9456 15.3977 21.7476 15.3977 19.9762C15.3977 17.1953 17.0062 15.1191 19.0092 15.1191C20.7118 15.1191 21.5344 16.4003 21.5344 17.9364C21.5344 19.6525 20.4442 22.2179 19.8814 24.5954C19.4113 26.5861 20.8777 28.2095 22.8369 28.2095C26.3844 28.2095 29.1111 24.4614 29.1111 19.051C29.1111 14.2624 25.6772 10.9143 20.7744 10.9143C15.0955 10.9143 11.7622 15.1826 11.7622 19.5936C11.7622 21.3127 12.4229 23.1558 13.2475 24.1578C13.4104 24.3558 13.4345 24.5295 13.386 24.7315C13.2343 25.3633 12.8978 26.7216 12.8316 26.9996C12.7445 27.3657 12.5423 27.4434 12.1641 27.2671C9.67137 26.1043 8.11298 22.4525 8.11298 19.5191C8.11298 13.2101 12.6872 7.41634 21.3002 7.41634C28.2237 7.41634 33.6043 12.36 33.6043 18.9671C33.6043 25.8601 29.2673 31.4072 23.2476 31.4072C21.2252 31.4072 19.3239 30.3544 18.6732 29.1108C18.6732 29.1108 17.6723 32.9294 17.4297 33.8651C16.9789 35.6024 15.7627 37.7799 14.9491 39.1081C16.8167 39.6876 18.8012 40 20.8584 40C31.8815 40 40.817 31.0459 40.817 19.9999C40.817 8.95447 31.8815 0 20.8584 0Z" fill="currentColor"/>
    </svg>
)

export default Pinterest
