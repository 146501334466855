import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import Button from "../Button/Button"
import Image from "../Image/Image"
import useIntersectionObserver from "../../../hooks/useIntersection"
import { imageConstants } from "../../../constants/image.constants"
import { gaEventClasses } from "../../../constants/gtm.constants"

const InlineView = props => {
    const { poster, videoLink, isIframe, title, videoId, image, style, isImageToggle } = props
    const [showPlay, setShowPlay] = useState(true)
    const [isPlayVideo, setVideoState] = useState(false)
    const videoRef = useRef(null)

    // if iframe is in viewport, then render
    const ref = useRef(null)
    const entry = useIntersectionObserver(ref, {})
    const isVisible = !!entry?.isIntersecting

    const togglePlayPause = event => {
        event.preventDefault()
        const video = videoRef.current
        video.paused ? video.play() : video.pause()
        setShowPlay(video.paused)
    }

    const playPauseVideo = () => {
        setVideoState(!isPlayVideo)
    }

    useEffect(() => {
        let video = document.getElementById(videoId)
        let videostatus = isPlayVideo ? "playVideo" : "pauseVideo"
        const elem = video?.contentWindow
        elem?.postMessage(
          '{"event":"command", "func":"' + videostatus + '", "args":""}',
          "*"
        )
      }, [isPlayVideo])

    const showPlayCls = () => {
        if (!isPlayVideo) {
            return style?.showImg
        } else {
            return style?.hideImg
        }
    }
    const showVideoCls = () => {
        if (isPlayVideo) {
            return style?.videoFrame
        } else {
            return style?.videoFrameHide
        }
    }
    function renderHomePageVideo() {
        return (
            <>
                {isImageToggle && (
                    <Button className={showPlayCls()} onClick={playPauseVideo} aria-label="Play video">
                        <Image contentfulImage={poster} className={style?.playBtnImg} />
                        <Image contentfulImage={imageConstants.playBtn} className={style?.playButton} />
                    </Button>
                )}
                <div className={isImageToggle ? showVideoCls() : style?.videoframe} ref={ref}>
                    {isVisible && (
                        <iframe
                            src={`${videoLink}`}
                            frameBorder="0"
                            width={isImageToggle ? "700" : "100%"}
                            height={isImageToggle ? "400" : "100%"}
                            allowFullScreen
                            title={title || "YouTube video player"}
                            allow={"autoplay"}
                            id={isImageToggle ? videoId : "youtubeVideo"}
                            className={[style?.videoItem, gaEventClasses.video_play].join(" ")}
                            data-action-detail={`${videoLink}`}
                            csp="*.youtube.com"
                        />
                    )}
                </div>
            </>
        )
    }

    const renderIframe = () => {
        return <div className={style?.videoCntr}>{renderHomePageVideo()}</div>
    }

    const getPlayClass = () => {
        if (showPlay) {
            return "absolute playbtn"
        }
        return "hidden"
    }

    return (
        <div>
            {isIframe ? (
                <> {renderIframe()} </>
            ) : (
                <Button className={style?.btnImg} onClick={togglePlayPause} aria-label="Play video">
                    <video
                        ref={videoRef}
                        poster={poster?.url}
                        controls
                        width={"100%"}
                        height={"100%"}
                        className={"video-player"}
                    >
                        <source src={videoLink} type="video/mp4" />
                        {"Your browser does not support the video tag."}
                    </video>

                    <Image contentfulImage={image} className={getPlayClass()} />
                </Button>
            )}
        </div>
    )
}

InlineView.propTypes = {
    poster: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isIframe: PropTypes.bool,
    title: PropTypes.string,
    youtubeVideoId: PropTypes.string,
    videoId: PropTypes.string,
    style: PropTypes.object,
    isImageToggle: PropTypes.bool,
    videoLink: PropTypes.string,
    image: PropTypes.object,
}

export default InlineView
