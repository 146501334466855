import React from "react"
import PropTypes from "prop-types"
import * as styles from "./ListComponent-tw-styles"
import Link from "../Link/Link"
import { gaEventClasses } from "../../../constants/gtm.constants"

function ListComponent({ linksCollection, label, variant }) {
    const style = styles[variant] || styles.defaultStyle
    const { listmob, Listcontainer, listLink } = style
    return (
        <>
            {label && <h4 className="font-bold pb-10">{label}</h4>}
            <ul className={`${listmob} list-component`}>
                {linksCollection?.map((link, index) => (
                    <li className={Listcontainer} key={index}>
                        <Link
                            {...link}
                            className={listLink}
                            gaEventClass={link?.gaEventClass || gaEventClasses?.footer_click}
                            gaEventLabel={link?.gaEventLabel}
                        />
                    </li>
                ))}
            </ul>
        </>
    )
}

ListComponent.propTypes = {
    linksCollection: PropTypes.array,
    variant: PropTypes.string,
    label: PropTypes.string,
}

export default ListComponent
