import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

const DURATION = 1000

const NumberCounter = props => {
    const { start, end, duration = DURATION, prefix = '', className } = props
    const [currentNumber, setCurrentNumber] = useState(start)
    const wrapperRef = useRef()

    const animate = () => {
        if (!start && !end) return

        let startTimestamp = null

        const step = timestamp => {
            if (!startTimestamp) {
                startTimestamp = timestamp
            }

            const progress = Math.min((timestamp - startTimestamp) / duration, 1)

            setCurrentNumber(Math.floor(progress * (end - start) + start))

            if (progress < 1) {
                window.requestAnimationFrame(step)
            }
        }

        window.requestAnimationFrame(step)
    }

    useEffect(() => {
        const checkTheTop = () => {
            if (wrapperRef?.current) {
                const yOffset = wrapperRef.current.getBoundingClientRect().top
                const screenHeight = window.screen.availHeight

                if (yOffset < screenHeight) {
                    animate()
                    document.removeEventListener("scroll", checkTheTop)
                }
            }
        }

        if (start && end) {
            document.addEventListener("scroll", checkTheTop)
        }

        return () => {
            document.removeEventListener("scroll", checkTheTop)
        }
    }, [])

    if (!start && !end) return null
    if (!start) return <div>{end}</div>

    return (
        <div ref={wrapperRef} className={clsx(className)}>
            {prefix}
            {currentNumber}
        </div>
    )
}

NumberCounter.propTypes = {
    start: PropTypes.number,
    end: PropTypes.number,
    duration: PropTypes.number,
    prefix: PropTypes.string,
    className: PropTypes.string,
}

export default NumberCounter
