export const defaultStyle = {
    accordionWrapper: "w-full",
    accordionInner: "px-[16px] mx-auto",
    accordionContainer: "relative outline-2 outline rounded-[30px] my-[16px] max-w-[954px] mx-auto z-10",
    accordionTitle: "text-center text-[60px] text-primary font-bite",
    accordionQuestion: "font-bite text-[18px] slg:text-[24px] leading-[100%] font-normal",
    accordionHeader:
        "cursor-pointer w-full min-h-[64px] outline-2 outline rounded-[30px] flex gap-[18px] md:gap-[54px] px-[8px] " +
        "pt-[8px] pb-[8px] select-none flex-row items-center justify-left text-left bg-[#FFDF5E]",
    accordionHeaderOpen:
        "w-full min-h-[64px] cursor-pointer rounded-[30px] flex gap-[18px] md:gap-[54px] px-[8px] pt-[8px] pb-[8px] " +
        "select-none flex-row items-center justify-left text-left " +
        "transition-all duration-200 ease-out z-1",
    expandedContainer: " relative cursor-pointer overflow-hidden transition-height duration-200 ease-in-out z-[-1]",
    accordionIconClosed: "bg-collapsed-icon bg-no-repeat min-w-[44px] w-[44px] h-[44px]",
    accordionIconOpen: "bg-expanded-icon bg-no-repeat min-w-[44px] w-[44px] h-[44px]",
    expandedTextContainer: "pt-[20px] pb-[30px]",
    richTextModel: {
        richTextWrapper:
            "relative font-[500] border-top-none rounded-t-none rounded-[30px] px-[30px] md:pl-[106px] " +
            "md:pr-[212px] text-[14px] leading-[150%] [&>ul>li]:list-disc [&>ul>li]:mt-[24px] [&>ul>li]:ml-[24px] [&>p+p]:mt-[24px]",
    },
}

export const READ_MORE = {
    accordionWrapper: "w-full",
    button: "font-bold underline",
    accordionInner: "px-[16px] mx-auto",
    accordionContainer: "",
    mobileContainer: "",
    desktopContainer: "relative flex slg:flex-col",
    accordionTitle: "text-center text-[60px] text-primary font-bite",
    accordionQuestion: "font-bite text-[18px] slg:text-[24px] leading-[100%] font-normal",
    expandedContainer: " relative cursor-pointer overflow-hidden transition-height duration-200 ease-in-out",
    expandedTextContainer: "",
    richTextModel: {
        richTextWrapper: ""
    },
}
