import React, { useEffect, useState } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import { gaEventClasses, gaEventLabels } from "../../../constants/gtm.constants"
import { setCookie } from '../../../helpers/cookieHelper'
import { useGlobalContext } from '../../../context/GlobalContext'
import Link from "../../UI/Link/Link"
import { desktop as desktopStyles } from "./CountrySelector-tw-styles"

const CountrySelectorDesktop = props => {
    const { countriesCollection, classname, languageCode } = props
    const [isCountrySelectorOpen, setIsCountrySelectorOpen] = useState(false)
    const labels = useGlobalContext()

    const countries = [...countriesCollection?.items]

    const currentCountryIndex = countries.findIndex(item => item?.page?.languageCode === languageCode)
    const currentCountry = countries[currentCountryIndex]

    const [activeIcon, setActiveIcon] = useState(currentCountry)

    const setLanguageCookie = (languageCode) => {
        setCookie("HARMONY_LANG", languageCode)
    }

    const handleCloseOnKeyPress = event => {
        if (event.key === "Escape" || event.key === "Esc") {
            setIsCountrySelectorOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleCloseOnKeyPress)

        return () => {
            document.removeEventListener("keydown", handleCloseOnKeyPress)
        }
    }, [])

    return (
        <section className={clsx(classname, desktopStyles.wrapper)}>
            <button
                className={clsx(
                    desktopStyles.button,
                    gaEventClasses.button_click,
                    isCountrySelectorOpen && desktopStyles.buttonOpened
                )}
                onClick={() => setIsCountrySelectorOpen(!isCountrySelectorOpen)}
                data-action-detail={gaEventLabels.country}
            >
                <img
                    src={activeIcon?.icon.url}
                    alt={activeIcon?.name}
                    width={36}
                    height={36}
                    className={desktopStyles.imageBorder}
                />
                <span className={desktopStyles.buttonTitle}>{currentCountry?.name ?? "Unknown"}</span>

                {!isCountrySelectorOpen && (
                    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.19526 1.17574C1.45561 0.941421 1.87772 0.941421 2.13807 1.17574L7 5.55147L11.8619 1.17574C12.1223 0.941421 12.5444 0.941421 12.8047 1.17574C13.0651 1.41005 13.0651 1.78995 12.8047 2.02426L7.4714 6.82426C7.21105 7.05858 6.78895 7.05858 6.5286 6.82426L1.19526 2.02426C0.934913 1.78995 0.934913 1.41005 1.19526 1.17574Z"
                            fill="#0B612D"
                            stroke="#0B612D"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                )}
                {isCountrySelectorOpen && (
                    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.19526 6.82426C1.45561 7.05858 1.87772 7.05858 2.13807 6.82426L7 2.44853L11.8619 6.82426C12.1223 7.05858 12.5444 7.05858 12.8047 6.82426C13.0651 6.58995 13.0651 6.21005 12.8047 5.97574L7.4714 1.17574C7.21105 0.941422 6.78895 0.941422 6.5286 1.17574L1.19526 5.97574C0.934913 6.21005 0.934913 6.58995 1.19526 6.82426Z"
                            fill="#0B612D"
                            stroke="#0B612D"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                )}
            </button>
            <section
                className={clsx(desktopStyles.expandedSection, isCountrySelectorOpen ? "grid-rows-[1fr]" : "opacity-0")}
            >
                <div className={desktopStyles.linksWrapper}>
                    {countries?.map((country, index) => {
                        if (index !== currentCountryIndex)
                            return (
                                <Link
                                    href={country?.page?.slug}
                                    target="_self"
                                    key={country.name + index}
                                    onMouseOver={() => setActiveIcon(country)}
                                    onMouseOut={() => setActiveIcon(currentCountry)}
                                    onClick={() => setLanguageCookie(country?.page?.languageCode)}
                                    gaEventClass={labels.getLabel(
                                        "eventClasses.countrySelectorItemClick",
                                        "event_internal_link"
                                    )}
                                    gaEventClassFallback={gaEventClasses?.image_click}
                                    gaEventLabel={country.name}
                                    className={desktopStyles.item}
                                    tabIndex={isCountrySelectorOpen ? 0 : -1}
                                >
                                    <span className={desktopStyles.countryName}>{country.name}</span>
                                </Link>
                            )
                    })}
                </div>
            </section>
        </section>
    )
}

CountrySelectorDesktop.propTypes = {
    countriesCollection: PropTypes.object,
    classname: PropTypes.string,
    languageCode: PropTypes.string,
}

const CountrySelector = props => <CountrySelectorDesktop {...props} />

CountrySelector.propTypes = {
    title: PropTypes.string,
    countriesCollection: PropTypes.object,
    classname: PropTypes.string,
    languageCode: PropTypes.string,
}

export default CountrySelector
