import { useEffect, useState } from "react"

const useIntersectionObserver = (elementRef, { threshold = 0, root = null, rootMargin = "0%", triggerOnce = true }) => {
    const [entry, setEntry] = useState()

    const frozen = entry?.isIntersecting && triggerOnce

    const updateEntry = ([entry]) => {
        setEntry(entry)
    }

    useEffect(() => {
        const node = elementRef?.current // DOM Ref
        const hasIOSupport = !!window.IntersectionObserver

        if (!hasIOSupport || frozen || !node) return

        const observerParams = { threshold, root, rootMargin }
        const observer = new IntersectionObserver(updateEntry, observerParams)

        observer.observe(node)

        return () => observer.disconnect()
    }, [elementRef?.current, JSON.stringify(threshold), root, rootMargin, frozen])

    return entry
}

export default useIntersectionObserver
