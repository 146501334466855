const query = isPreview => `{
  footerCollection(preview: ${isPreview}, limit: 12) {
    items {
      languageCode
      socialLinksCollection(limit: 5) {
        items {
          label
          title
          url
          target
          imageType
          gaEventClass
          gaEventLabel
          image {
            title
            description
            url
            width
            height
          }
        }
      }
      mainLinksCollection(limit: 3) {
        items {
          label
          linksCollection(limit: 10) {
            items {
              label
              title
              url
              target
              scrollToElementId
              gaEventClass
              gaEventLabel
              image {
                title
                description
                url
                width
                height
              }
            }
          }
        }
      }
      stickyMenuText
      stickyMenuItemsCollection(limit: 10) {
        items {
          title
        }
      }
      helpText
      helpLinksCollection(limit: 1) {
        items {
          label
          linksCollection(limit: 10) {
            items {
              label
              title
              url
              target
              scrollToElementId
              gaEventClass
              gaEventLabel
              image {
                title
                description
                url
                width
                height
              }
            }
          }
        }
      }
    }
  }
}

  `
module.exports = { query }
