export const defaultStyle = {
    container: "",
    imgWrpr:
        "h-min overflow-hidden rounded-[2rem] px-auto text-sm tracking-wide text-darkBlue font-medium before:pr-5 w-[195px] grid",
    heading: "text-xl text-white text-center",
    description: "text-xl text-white px-auto text-center",
    linkWrpr: "",
    btnStyle: "hidden",
    contentWrpr: "relative",
    img: "rounded-[2rem]",
    cardsSliderWrapr: "",
    cardWrapr: "cardWrapr text-white  text-[40px] px-auto w-full text-center pt-50 font-bold",
    cardDesc: "cardDesc text-white  text-[18px] px-auto lg:w-1/3 mx-auto w-full text-center pb-40",
    cardWrpr: "lg:w-[1170px] mx-auto max-w-full",
    dotList:
        "carousel-dots inline-flex justify-center w-full mlg:pt-[32px] xlg:pt-[44px] max-slg:pt-[24px] max-md:pt-[48px]",
    dotListItem: "w-[12px] h-[12px] rounded-full bg-primary text-[0] text-primary opacity-[0.8]",
}

export const imageWithText = {
    cardsWrapr: "cardsWrapr grid grid-cols-1 lg:grid-cols-3 gap-4 lg:w-[1170px] mx-auto py-20",
    imgWrpr: "imgWrpr flex  rounded-20 ",
    cardWrpr: "lg:w-[1170px] mx-auto",
    gradTop: "absolute bottom-[0px]  w-[195px] rounded-b-[2rem] h-[140px] bg-toTopGradient",
}

export const FLIP_QUOTES = {
    ...defaultStyle,
    containerWrpr: "farmers-carousel / w-full py-[94px]",
    container: "relative max-w-[1920px] max-mlg:max-w-[539px] max-mlg:px-[16px] mx-auto",
    heading: "anim-title / w-full slg:max-[1280px]:max-w-[900px] max-w-[1140px] xlg:max-w-[1440px] mx-auto text-center style-h2",
    description: "anim-title / w-full mt-[32px] mx-auto text-center body-xl mlg:max-w-[790px]",
    cardWrpr: "w-full slg:max-[1280px]:max-w-[900px] max-w-[1140px] xlg:max-w-[1440px] mx-auto",
    sliderMob: "py-[30px]",
}

export const FLIP_TEXT_CARDS = {
    ...defaultStyle,
    containerWrpr: "w-full py-[48px]",
    container: "relative xlg:max-w-[1920px] max-mlg:max-w-[539px] slg:max-mlg:px-[16px] max-md:px-[16px] mx-auto",
    cardsWrapr: "w-full flex max-mlg:flex-wrap gap-[20px] slg:max-[1280px]:max-w-[900px] max-w-[1140px] xlg:max-w-[1440px] mx-auto",
    linkCardsWrpr: "w-full",
}

export const BRAND_LOGOS = {
    ...defaultStyle,
    containerWrpr: "frame-divider-rotated / relative w-full max-slg:pb-[24px] pb-[52px]",
    container:
        "relative max-mlg:max-w-[992px] -top-[100px] max-w-[1920px] max-slg:max-w-[704px] slg:max-mlg:px-[16px] max-md:px-[16px] mx-auto",
    cardsWrapr:
        "w-full flex flex-wrap max-mlg:gap-[24px] gap-[48px] max-mlg:max-w-[992px] slg:max-[1280px]:max-w-[900px] max-w-[1140px] xlg:max-w-[1440px] mx-auto " +
        "justify-center items-center pt-[132px] max-mlg:pt-[96px] max-mlg:max-h-[300px] max-[390px]:max-h-[380px]",
    textContentWrapper: "w-full slg:max-[1280px]:max-w-[900px] max-w-[1140px] xlg:max-w-[1440px] mx-auto slg:flex slg:justify-center lg:justify-start",
    textContentContainer: "slg:flex slg:flex-col",
    linkCardsWrpr: "brand-logo / w-full max-w-[120px] xlg:max-w-[144px] max-slg:max-w-[96px] max-md:max-w-[72px]",
    description:
        "rotated-section-title typewriter / w-full relative left-[7px] top-[5px] max-slg:top-[7px] max-slg:left-[6px]",
    subtext:
        "rotated-section-title typewriter / w-full relative top-[13px] md:top-[14px] slg:top-[11px] xlg:top-[10px] left-[11px] slg:left-[13px] xlg:left-[13px]",
    heading: "rotated-section-title typewriter w-full",
    decorImage: "decor / absolute -right-[100px] max-xlg:hidden",
}

export const AMBITION_CARDS = {
    ...defaultStyle,
    containerWrpr: "w-full pt-[24px] pb-[225px] max-slg:pb-[130px] slg:max-mlg:max-w-[704px] slg:max-mlg:mx-auto",
    container: "relative max-w-[1920px] max-mlg:px-[16px] mx-auto pb-[24px]",
    heading: "w-full",
    description: "w-full",
    cardWrpr: "w-full slg:max-[1280px]:max-w-[900px] max-w-[1140px] xlg:max-w-[1440px] mx-auto",
    linkCardsWrpr: "ambition-card",
    sliderMob: "ambition-cards",
}
