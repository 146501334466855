// /**
//  * Layout component that queries for data
//  * with Gatsby's useStaticQuery component
//  *
//  * See: https://www.gatsbyjs.com/docs/use-static-query/
//  */

import * as React from "react"
import "../../styles/fonts.css"
import PropTypes from "prop-types"
import Header from "../Globals/Header/Header"
import Footer from "../Globals/Footer/Footer"
import { get } from "../../helpers/utils"
import ScriptWrapper from "../Wrappers/ScriptWrapper/ScriptWrapper"
import clsx from "clsx"

const Layout = ({ children, seoData, globalComponents, className, languageCode, pageType }) => {
    const FooterProps = globalComponents?.find(component => component?.Footer)
    const HeaderProps = globalComponents?.find(component => component?.Header)
    const CountrySelectorProps = globalComponents?.find(component => component?.CountrySelector)

    const countrySelectorCollection = get(CountrySelectorProps, "CountrySelector.countrySelectorCollection.items[0]", {})
    const headerCollection = HeaderProps.Header.headerCollection?.items.find(item => item.languageCode === languageCode) || {}
    const footerCollection = FooterProps.Footer.footerCollection?.items.find(item => item.languageCode === languageCode) || {}
    const isHygienePage = pageType?.includes("Hygiene")

    return (
        <>
            <ScriptWrapper seoHead={seoData} />

            <div media="print" className={clsx("main-container text-darkBlue font-poppins", className)}>
                <Header
                    languageCode={languageCode}
                    countrySelectorCollection={countrySelectorCollection}
                    {...headerCollection}
                    isHygienePage={isHygienePage}
                />

                <div className={clsx("content-container w-full", isHygienePage && " hygiene-page")}>
                    <main>{children}</main>
                </div>

                <Footer
                    languageCode={languageCode}
                    countrySelectorCollection={countrySelectorCollection}
                    {...footerCollection}
                    isHygienePage={isHygienePage}
                />

                <div id="base-modal"></div>
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    seoData: PropTypes.object,
    globalComponents: PropTypes.array,
    className: PropTypes.string,
    languageCode: PropTypes.string,
    pageType: PropTypes.string
}

export default Layout
