import React from "react"
import PropTypes from "prop-types"
import Facebook from "../../../assets/icons/Facebook"
import Instagram from "../../../assets/icons/Instagram"
import Pinterest from "../../../assets/icons/Pinterest"
import Twitter from "../../../assets/icons/Twitter"
import MondelezLogo from "../../../assets/icons/MondelezLogo"
import PlayMediaIcon from "../../../assets/icons/PlayMediaIcon"

const Icons = {
    Facebook,
    Instagram,
    Pinterest,
    Twitter,
    MondelezLogo,
    PlayMediaIcon,
}

const Icon = ({ name, className }) => {
    if (!name) {
        return null
    }
    const SelectedIcon = Icons[name] || null
    return SelectedIcon ? SelectedIcon(className, name) : null
}

Icon.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
}

export default Icon
