export const defaultStyles = {
    wrapper: "h-full pb-[160px] md:pb-[120px]",
    mainTitle: "flex justify-center slg:pt-[80px] pt-[40px] pb-[48px] md:pb-[52px] slg:pb-[160px]",
    circleWrapper: "relative flex items-center justify-center slg:h-[600px] slg:w-[600px] mx-auto",
    arrowWrapper: "max-slg:hidden w-full absolute max-w-[840px] mx-auto left-0 right-0",
    leftBtn: "absolute max-slg:w-[44px] max-slg:h-[44px] -translate-y-[50%] top-[50%] -left-[50px] slg:-left-[100px]",
    rightBtn: "absolute max-slg:w-[44px] max-slg:h-[44px] -translate-y-[50%] top-[50%] -right-[50px] slg:-right-[100px]",
    circle: "relative z-[1] flex items-center justify-center w-[358px] h-[358px] md:h-[400px] md:w-[400px] slg:h-[600px] " +
        "slg:w-[600px] bg-circle-texture bg-[length:358px] md:bg-[length:400px] slg:bg-[length:600px] bg-no-repeat",
    buttonWrapper: "circle-item / max-slg:w-[96px] max-slg:h-[96px] slg:absolute flex flex-col items-center z-[2]",
    circleBtn: "relative flex justify-center",
    circleTitle: "circle-title / min-w-[90px] slg:min-w-[110px] circle-text-shadow text-white text-[12px] slg:text-[20px] " +
        "font-bite absolute bottom-[15px] slg:bottom-[25px] rotate-[-7.08deg]",
    mobileWrapper: "flex justify-between absolute -bottom-[140px] md:-bottom-[45px] max-md:max-w-[290px] max-md:flex-wrap",
}
