export const defaultStyle = {
    headerContainer: "pointer-events-none headerContainer w-full fixed mlg:pt-[48px] pt-[24px] max-slg:px-[32px] max-md:px-[16px] z-[100]",
    headerContent: "flex headerContent min-h-[40px] items-center justify-between relative mx-auto " +
        "xlg:max-w-[1440px] lg:max-w-[1376px] mlg:max-w-[1140px] slg:max-mlg:px-[32px] mlg:max-[1280px]:max-w-[900px]",
    headerLogo: "relative z-[1] flex mlg:max-w-[170px] max-w-[96px] pointer-events-auto",
    scrolledLogoLink: "harmony-logo / ml-[18px] fixed z-[101] pointer-events-auto hidden",
    logoScrollImg: "relative w-[60px] h-[60px]",
    hygienePageLogo: "!fixed top-[calc(24px_+_0.75rem)] mlg:top-[48px] w-[36px] h-[36px] slg:w-[60px] slg:h-[60px]",
    logoImg: "max-h-full",
}
