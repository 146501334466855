import React, { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { defaultStyles as styles } from "./HomepageHero-tw-styles"
import { convertToId, get } from "../../helpers/utils"
import useImage from "../../hooks/useImage"
import HomepageHeroItem from "./HomepageHeroItem/HomepageHeroItem"
import useUUID from "../../hooks/useUUID"
import { HERO_SLIDE_PROPTYPES, IMAGES_PROPTYPES } from "../../constants/shapes.constants"
import { subscribeEvent, unsubscribeEvent } from "../../helpers/events.helpers"
import useScreenSize from "../../hooks/useScreenSize"

const HomepageHero = (props) => {
    const { stickyMenuItem, background, slidesCollection } = props
    const slides = get(slidesCollection, "items", [])
    const slidesCount = slides?.length
    const { foregroundImg } = useImage(background)
    const heroWrapper = useRef(null)
    const heroItemKeys = useUUID(slidesCount)
    const [height, setHeight] = useState(0)
    const screen = useScreenSize()
    const isMobile = screen?.isMobile || screen?.isTablet || screen?.isHandheld
    const menuId = stickyMenuItem && convertToId(stickyMenuItem?.title, true)

    const handleClick = (index) => {
        const nextSection = heroWrapper.current.querySelector(`#hero-section-${index + 1}`)
        if (!nextSection) return

        nextSection.scrollIntoView({ behavior: "smooth" })
    }

    const changedPositionToAbsolute = (el) => {
        el.classList.remove("fixed")
        el.classList.add("absolute")
    }

    const changedPositionToFixed = (el) => {
        el.classList.remove("absolute")
        el.classList.add("fixed")
    }

    useEffect(() => {
        if (!heroWrapper.current) return

        const lastSlide = heroWrapper.current.querySelector(`#hero-section-${slidesCount - 1}`)
        const header = document.querySelector(".headerContainer")
        const slideBottomBg = heroWrapper.current.querySelector(".hero-bottom-bg")
        const mobileMenu = document.querySelector(".mobile-menu")
        const lastSlideMobileHeight = lastSlide.clientHeight - window.innerHeight
        const heroBackground = heroWrapper.current.querySelector(".hero-background")
        const harmonyLogo = document.querySelector(".harmony-logo")

        const handleScroll = () => {
            if (!lastSlide || !slideBottomBg || !header || !heroBackground) return

            const { bottom } = lastSlide.getBoundingClientRect()
            const { top } = heroWrapper.current.getBoundingClientRect()

            const distanceFromTop = window.scrollY + top
            const rawPercentScrolled =
                (window.scrollY - distanceFromTop) / (heroWrapper.current.scrollHeight - window.innerHeight)
            const percentScrolled = Math.min(Math.max(rawPercentScrolled, 0), 1) * 100

            setHeight(Math.ceil(percentScrolled))

            if ((!isMobile && bottom <= window.innerHeight) ||
                (isMobile && bottom - lastSlideMobileHeight <= window.innerHeight) ||
                (isMobile && percentScrolled > 16)
            ) {
                changedPositionToAbsolute(header)
                header.style.top = "0vh"
                mobileMenu.classList.remove("hidden")
                mobileMenu.classList.add("show-menu")
                mobileMenu.classList.remove("hide-menu")
            } else {
                if ((!isMobile && bottom <= window.innerHeight) ||
                    (isMobile && bottom - lastSlideMobileHeight <= window.innerHeight)
                ) return
                changedPositionToFixed(header)
                header.style.top = "0vh"
                if (!window.scrollY) {
                    mobileMenu.classList.add("hidden")
                    mobileMenu.classList.remove("hide-menu")
                } else {
                    mobileMenu.classList.remove("show-menu")
                    mobileMenu.classList.add("hide-menu")
                }
            }

            if (bottom <= window.innerHeight) {
                changedPositionToAbsolute(slideBottomBg)
                changedPositionToAbsolute(heroBackground)
                if (harmonyLogo && !isMobile) {
                    harmonyLogo.classList.remove("hidden")
                    harmonyLogo.classList.add("show-logo")
                    harmonyLogo.classList.remove("hide-logo")
                }
            } else {
                if (bottom <= window.innerHeight) return
                changedPositionToFixed(slideBottomBg)
                changedPositionToFixed(heroBackground)
                if (harmonyLogo && !isMobile) {
                    harmonyLogo.classList.add("hide-logo")
                }

                if (!window.scrollY) {
                    harmonyLogo.classList.add("hidden")
                }
            }
        }

        handleScroll()

        subscribeEvent("scroll", handleScroll)

        return () => unsubscribeEvent("scroll", handleScroll)
    }, [isMobile])


    return (
        <section id={menuId} ref={heroWrapper} className={styles.section}>
            <div
                className={styles.wrapper}
            >
                <div
                    className={styles.heroBackground}
                    style={{ backgroundImage: `url(${foregroundImg?.url})` }}
                />
                {slides.map((slide, index) =>
                    <HomepageHeroItem
                        key={heroItemKeys[index]}
                        handleClick={handleClick}
                        index={index}
                        slide={slide}
                        slidesCount={slidesCount}
                    />
                )}
                <div className={styles.bottomBg} />
            </div>
        </section>
    )
}

HomepageHero.propTypes = {
    stickyMenuItem: PropTypes.object,
    background: PropTypes.shape(IMAGES_PROPTYPES),
    slidesCollection: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape(HERO_SLIDE_PROPTYPES)
        )
    })
}

export default HomepageHero
