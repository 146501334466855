export const defaultStyle = {
    rangeCardWrpr: "rangeCardWrpr",
    imageBoxWrpr: "imageBoxWrpr w-full ",
    imgWrpr: "imgWrpr mx-auto w-[80%]",
}

export const HERO_CARD = {
    imageBoxWraper: "hero-card / mlg:[&:nth-child(2)]:mt-[64px] w-full cursor-pointer",
    imgWrpr: "imgWrpr / w-full object-cover",
    heading: "style-h3",
    imageBoxWrpr:
        "hero-card-wrapper / relative before:block before:absolute before:top-[50px] before:bottom-0 before:left-0 " +
        "before:right-0 before:bg-hero-card-texture before:bg-repeat-y before:-z-[1] before:bg-[-100px_top] before:rounded-b-[24px]",
    contentWrapr: "p-[24px_16px_8px_16px]",
    description: "desc / [&>p]:text-left [&>p]:body-m",
}

export const AMBITION_CARD = {
    imageBoxWrpr: "text-center",
    description: "desc / [&>p]:text-center [&>p]:text-[16px] [&>p]:leading-[150%]",
    link: "link-with-texture / mt-[24px]",
    imgContainer: "flex justify-center mb-[24px]",
    imgWrpr: "max-h-[140px] max-w-[255px]",
}
