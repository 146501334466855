import React from "react"
import PropTypes from "prop-types"
import Image from "../Image/Image"
import Typography from "../Typography/Typography"
import useImage from "../../../hooks/useImage"
import HeroCard from "./HeroCard/HeroCard"
import FlipCard from "./FlipCard/FlipCard"
import Link from "../..//UI/Link/Link"
import * as styles from "./ImageCard-tw-styles"
import VideoIcon from "../../../assets/icons/VideoIcon"
import ExternalPageIcon from "../../../assets/icons/ExternalPageIcon"

const ImageCard = props => {
    const { text, title, images, image, variant, link, gaEventClass, gaEventLabel } = props
    const { foregroundImg } = useImage(images)
    const style = styles[variant] || styles.defaultStyle

    switch (variant) {
        case "HERO_CARD":
            return <HeroCard {...props} />
        case "FLIP_QUOTE":
        case "FLIP_TEXT_CARD":
            return <FlipCard {...props} />

        default:
            return (
                <div className={style?.imageBoxWraper}>
                    <div className={style?.imageBoxWrpr}>
                        <div className={style?.imgContainer}>
                            <Image
                                contentfulImage={image || foregroundImg}
                                className={style?.imgWrpr}
                                eventClass={gaEventClass}
                                eventLabel={gaEventLabel}
                            />
                        </div>
                        <div className={style?.contentWrapr}>
                            {title && <Typography className={style?.heading} content={title} />}
                            {text && <Typography className={style?.description} content={text} />}
                        </div>
                        {variant === "AMBITION_CARD" && link &&
                            <Link {...link} className={style?.link}>
                                {link?.variant === "VIDEO" && <VideoIcon />}
                                {link?.variant === "EXTERNAL_PAGE" && <ExternalPageIcon />}
                                <span>{link?.title || link?.label}</span>
                            </Link>
                        }
                    </div>
                </div>
            )
    }
}

ImageCard.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.object,
    image: PropTypes.object,
    images: PropTypes.object,
    variant: PropTypes.string,
    gaEventClass: PropTypes.string,
    gaEventLabel: PropTypes.string
}

export default ImageCard
