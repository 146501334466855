import { useMemo } from 'react'
import { v4 as uuid } from "uuid"

const useUUID = (arrayLength = 1) => {
    const ids = []

    for (let i = 0; i < arrayLength; i = i + 1) {
        ids.push(uuid())
    }

    return useMemo(() => ids, [])
}

export default useUUID
