export const defaultStyles = {
    wrapper: "xlg:max-w-[1440px] slg:max-[1280px]:max-w-[900px] max-w-[1140px] flex flex-wrap justify-between mx-auto w-full xlg:py-[120px] mlg:py-[96px] py-[72px] text-center",
    firstWrapper: "mlg:pt-[136px] mlg:pb-[92px] pt-[164px] pb-[72px]",
    image: "mx-auto mlg:mb-[48px] max-mlg:mb-[24px] max-md:max-h-[88px] max-slg:max-h-[140px]",
    imageCardWrapper: "flex mt-[48px] gap-[24px] max-mlg:flex-wrap px-[16px]",
    buttonWrapper: "w-full flex justify-center",
    textWithBgWrapper: "flex w-full justify-end max-w-[934px] mx-auto mt-[5px] max-md:mt-[32px]",
    textWithBg: "text-with-bg / flex bg-primary text-white tracking-[0.01em] rotate-[-7.08deg] p-[8px_16px] max-w-max",
    cardWrapperWithCards: "max-mlg:max-w-[539px] max-mlg:mx-auto",
    cardWrapperNoCards: "max-mlg:px-[16px]",
}
