import gsap from "gsap"

export const animIn = (el) =>
    gsap.to(el, {
        duration: 0.3,
        height: "auto"
    })

export const animOut = (el) =>
    gsap.timeline().to(el, {
        duration: 0.3,
        height: 0
    })

export const anim = (wrapper, el, duration = .3, y = 300) => {
    const cards = wrapper.querySelectorAll(el)
    const tl = gsap.timeline()

    if (!cards) return

    cards.forEach((card) => {
        tl.fromTo(card, { y: y, autoAlpha: 0, opacity: 0 }, { y: 0, opacity: 1, duration: duration, autoAlpha: 1})
    })

    return tl
}

export const decorAnim = elms => {
    const tl = gsap.timeline()

    elms.forEach(el => {
        tl.to(el, {
            x: '-=100px', delay: 0.5, scrollTrigger: {
                trigger: el, start: 'top bottom', end: 'bottom top', scrub: 1,
            }
        })
    })

    return tl
}
