import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import Image from "../../Image/Image"
import Typography from "../../Typography/Typography"
import useImage from "../../../../hooks/useImage"
import * as styles from "../ImageCard-tw-styles"
import ArrowLeftUp from "../../../../assets/icons/ArrowLeftUp"
import useScreenSize from "../../../../hooks/useScreenSize"
import { animIn, animOut } from "../../../../helpers/anim.helpers"
import clsx from "clsx"
import { useGlobalContext } from '../../../../context/GlobalContext'

const HeroCard = props => {
    const { text, title, images, image, variant, gaEventClass, gaEventLabel } = props
    const { foregroundImg } = useImage(images)
    const style = styles[variant] || styles.defaultStyle
    const [isOpen, setIsOpen] = useState(false)
    const [height, setHeight] = useState('0')
    const windowSize = useScreenSize()
    const expandedContent = useRef(null)
    const globalContext = useGlobalContext()

    let localisedDescriptionClass

    if (
        globalContext.locale === "de-de" ||
        globalContext.locale === "fr-fr" ||
        globalContext.locale === "it-it" ||
        globalContext.locale === "ro-ro" ||
        globalContext.locale === "nl-be" ||
        globalContext.locale === "fr-be"
    ) {
        localisedDescriptionClass = "mlg:[&>p]:text-[14px] xlg:[&>p]:text-[16px]"
    }

    useEffect(() => {
        if (isOpen) {
            setHeight('auto')
            animIn(expandedContent.current).play()
        } else {
            animOut(expandedContent.current).play()
            const timer = setTimeout(() => {
                setHeight('0')
            }, 300)

            return () => clearTimeout(timer)
        }
    }, [isOpen, windowSize])


    return (
        <div className={style?.imageBoxWraper} onClick={() => setIsOpen(!isOpen)}>
            <div className={style?.imageBoxWrpr}>
                <div className={style?.imgContainer}>
                    <Image
                        contentfulImage={image || foregroundImg}
                        className={style?.imgWrpr}
                        eventClass={gaEventClass}
                        eventLabel={gaEventLabel}
                    />
                </div>
                <div className={style?.contentWrapr}>
                    {title && (
                        <Typography
                            className={clsx(style?.heading, isOpen ? "mb-[16px]" : "mb-[8px]")}
                            content={title}
                        />
                    )}
                    {text && (
                        <>
                            <div className="overflow-hidden" style={{ height }}>
                                <div ref={expandedContent}>
                                    <Typography
                                        className={clsx(style?.description, localisedDescriptionClass)}
                                        content={text}
                                    />
                                </div>
                            </div>
                            <div className="cards-icon / flex justify-end">
                                <ArrowLeftUp />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

HeroCard.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.object,
    image: PropTypes.object,
    images: PropTypes.object,
    variant: PropTypes.string,
    gaEventClass: PropTypes.string,
    gaEventLabel: PropTypes.string,
}

export default HeroCard
