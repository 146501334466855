import React from "react"
import PropTypes from "prop-types"
import Image from "../Image/Image"
import { Link as GatsbyLink, navigate } from "gatsby"

import {
    getEventFinalClass,
    getHref,
    getUrlWithTrailingSlash,
    getLinkGALabel,
    pushLinkToDataLayer,
} from "../../../helpers/link.helper"

const Content = props => {
    const { image, gaEventClass, gaEventLabel, label } = props
    return (
        <>
            {image ? (
                <Image contentfulImage={image} eventClass={gaEventClass} eventLabel={gaEventLabel} />
            ) : (
                label || null
            )}
        </>
    )
}

Content.propTypes = {
    label: PropTypes.string,
    image: PropTypes.object,
    gaEventClass: PropTypes.string,
    gaEventLabel: PropTypes.string,
}

const Link = props => {
    const {
        label,
        url: _url,
        href: _href,
        target,
        title,
        className = "",
        children,
        onClick,
        onFocus,
        onBlur,
        onMouseOver,
        onMouseOut,
        id,
        scrollToElementId,
        gaEventClass,
        gaEventLabel = "",
        image,
        tabIndex = 0,
        ariaLabel,
    } = props
    const url = _url || _href
    const regex = new RegExp(
        "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
    )
    const isExternallink = regex.test(url)
    let pageUrl = getUrlWithTrailingSlash(url, isExternallink)
    let href = getHref(pageUrl, scrollToElementId)

    const targetWindow = target || (isExternallink ? "_blank" : "_self")

    // if image is there, pass down event to image element
    const eventClassFinal = getEventFinalClass(gaEventClass, isExternallink)

    const classNameFinal = `${className} ${gaEventClass}`
    const classNameFinalAnchor = className ? [className, eventClassFinal].join(" ") : eventClassFinal

    const linkGALabel = getLinkGALabel(gaEventLabel, label, href)

    // // THE GTM EVENTS WERE FIRING IN THE NEXT PAGE BECAUSE OF ASYNCHRONOUS NATURE OF GATSBY LINK
    const customOnClick = e => {
        e.preventDefault()
        pushLinkToDataLayer(window, eventClassFinal, linkGALabel)

        if (onClick) {
            onClick()
        }

        const hrefSplitted = href.split("#")
        if (typeof navigate === "function") {
            navigate(hrefSplitted[0])
        }

        setTimeout(() => {
            if (typeof window !== "undefined") {
                const targetSection = document.getElementById(hrefSplitted[1])
                const y = targetSection?.getBoundingClientRect().top + window.scrollY || 0

                window.scrollTo({ top: y, behavior: "smooth" })
            }
        }, 100)
    }

    const content = children || (
        <Content contentfulImage={image} eventClass={gaEventClass} eventLabel={gaEventLabel} label={label} />
    )

    if (!url && !scrollToElementId) {
        return <div className={classNameFinal}>{content}</div>
    }

    if (isExternallink) {
        return (
            <a
                href={href}
                target={targetWindow}
                className={classNameFinalAnchor}
                onFocus={onFocus}
                onBlur={onBlur}
                onClick={onClick}
                data-action-detail={linkGALabel}
                title={title}
                draggable={false}
                id={id}
                tabIndex={tabIndex}
                aria-label={ariaLabel || null}
            >
                {content || children}
            </a>
        )
    } else {
        return (
            <GatsbyLink
                to={href}
                target={targetWindow}
                className={classNameFinal}
                onFocus={onFocus}
                onBlur={onBlur}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                title={title}
                draggable={false}
                id={id}
                onClick={e => {
                    customOnClick(e)
                }}
                tabIndex={tabIndex}
                aria-label={ariaLabel || null}
                data-action-detail={gaEventLabel || null}
            >
                {content || children}
            </GatsbyLink>
        )
    }
}

Link.propTypes = {
    label: PropTypes.string,
    url: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    onBlur: PropTypes.func,
    scrollToElementId: PropTypes.string,
    image: PropTypes.object,
    id: PropTypes.string,
    gaEventClass: PropTypes.string,
    gaEventLabel: PropTypes.string,
    tabIndex: PropTypes.number,
    ariaLabel: PropTypes.string,
}

export default Link
