import * as React from "react"

const PlayMediaIcon = className => (
    <svg
        className={className}
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <rect opacity="0.5" width="60" height="60" rx="30" fill="#FFF7E4" />
            <path
                d="M35.9128 29.0309C37.2691 29.7961 37.2691 31.7494 35.9128 32.5146L26.9828 37.5532C25.6496 38.3054 24 37.3421 24 35.8113L24 25.7341C24 24.2033 25.6496 23.24 26.9828 23.9923L35.9128 29.0309Z"
                fill="#FFF7E4"
            />
        </g>
    </svg>
)

export default PlayMediaIcon
