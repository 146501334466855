import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import useUUID from "../../../hooks/useUUID"
import { defaultStyles as styles } from "./TextSpinner-tw-styles"

const DURATION = 2200

const TextSpinner = props => {
    const { values, className, infinite = false } = props
    const [currentIndex, setCurrentIndex] = useState(0)
    const [placeholder, setPlaceholder] = useState("")
    const [animationOn, setAnimationOn] = useState(false)
    const wrapperRef = useRef()

    const finalValues = Array.isArray(values) ? values : values.split(";").map(val => val.trim())
    const valuesKeys = useUUID(finalValues?.length)
    const stepDuration = parseInt(DURATION / finalValues.length)

    useEffect(() => {
        const checkTheTop = () => {
            if (wrapperRef?.current) {
                const yOffset = wrapperRef.current.getBoundingClientRect().top
                const screenHeight = window.screen.availHeight

                if (yOffset < screenHeight * 0.4) {
                    if (!animationOn) {
                        document.removeEventListener("scroll", checkTheTop)
                        setAnimationOn(true) // starts animation
                    }
                }
            }
        }

        if (Array.isArray(finalValues)) {
            if (finalValues.length > 1) {
                const longest = finalValues.reduce((a, b) => (a.length > b.length ? a : b), "")
                setPlaceholder(longest)

                document.addEventListener("scroll", checkTheTop)
            }
        }

        return () => {
            document.removeEventListener("scroll", checkTheTop)
        }
    }, [])

    useEffect(() => {
        if (!animationOn) return

        let timer

        if (Array.isArray(finalValues)) {
            if (finalValues.length > 1) {
                if (!infinite && currentIndex === finalValues.length - 1) {
                    clearTimeout(timer)
                    return
                }

                timer = setTimeout(() => {
                    if (currentIndex === finalValues.length - 1) {
                        setCurrentIndex(0)
                    } else {
                        setCurrentIndex(currentIndex + 1)
                    }
                }, stepDuration)
            }
        }

        return () => {
            if (timer) clearTimeout(timer)
        }
    }, [animationOn, currentIndex])

    if (!Array.isArray(finalValues) || finalValues.length <= 0) return null

    if (finalValues.length === 1) return <div className={clsx(styles.wrapper, className)}>{finalValues[0]}</div>

    return (
        <div ref={wrapperRef} className={clsx(styles.wrapper, className)}>
            <div className={styles.placeholder}>{placeholder}</div>

            {finalValues.map((value, index) => (
                <div
                    key={valuesKeys[index]}
                    style={{
                        transitionDuration: `${stepDuration}ms`
                    }}
                    className={clsx(
                        styles.item,
                        index === currentIndex ? styles.active : "",
                        (currentIndex === 0 && index === finalValues.length - 1) ||
                            (currentIndex > 0 && index === currentIndex - 1)
                            ? styles.top
                            : "",
                        (currentIndex === finalValues.length - 1 && index === 0) || index === currentIndex + 1
                            ? styles.bottom
                            : ""
                    )}
                >
                    {value}
                </div>
            ))}
        </div>
    )
}

TextSpinner.propTypes = {
    values: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    infinite: PropTypes.bool,
    className: PropTypes.string,
}

export default TextSpinner
