export const defaultStyles = {
    wrapper:
        "relative p-[16px] mlg:px-[48px] mlg:py-[32px] border-[1px] border-orange border-dashed rounded-[24px] mt-[32px] md:mt-[24px] mlg:mt-[48px] bg-white",

    list: "flex mlg:justify-center flex-wrap gap-y-[10px]",
    listItem:
        "py-[14px] px-[24px] mlg:py-[4px] border-[1px] border-transparent rounded-[46px] text-[16px] leading-[100%] mlg:text-[18px] font-bite opacity-80",
    listItemActive: "!border-orange bg-darkBeige opacity-100",
    listItemButton: "",

    tabWrapper: "flex flex-col md:flex-row md:items-center gap-[20px] mt-[32px] md:mt-[32px] mlg:mt-[32px]",
    tabIcon: "flex gap-[8px] md:flex-col items-center md:min-w-[160px]",
    tabIconLabel: "order-2 md:order-1 text-[18px] leading-[100%] font-bite text-center min-h-[36px]",
    tabIconImage: "order-1 md:order-2",
    tabColumnsContainer: "flex flex-col md:flex-row justify-between gap-[14px] w-full mt-[20px] md:mt-0",
    tabColumn: "flex md:flex-col gap-[4px] w-full text-[20px] leading-[100%] font-bite text-left md:text-center",
    tabColumnNumber: "min-w-[70px] md:min-w-auto",
    tabColumnText: "text-[14px] md:text-[16px] leading-[150%] font-poppins",

    bottomImage: "absolute bottom-[-55px] right-[-183px] hidden mlg:block",
}
