import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import Button from "../Button/Button"
import Image from "../Image/Image"
import useIntersectionObserver from "../../../hooks/useIntersection"
import { imageConstants } from "../../../constants/image.constants"
import { gaEventClasses } from "../../../constants/gtm.constants"

const VideoPlayer = props => {
    const { posterImage, title, youtubeVideoId, image, style, activeVideo } = props
    const [isPlayVideo, setVideoState] = useState(false)
    const [playerVal, setplayerVal] = useState(null)
    const [playerState, setplayerState] = useState(null)

    const location = typeof window !== "undefined" ? window?.location?.origin : ""
    const videoLink = `https://www.youtube-nocookie.com/embed/${youtubeVideoId}?html5=1&showinfo=0&rel=0&modestbranding=0&playsinline=1&enablejsapi=1&origin=${location}`

    const playPauseVideo = () => {
        setVideoState(!isPlayVideo)
    }
    var player
    function payIframeAPIReady() {
        player = new YT.Player(youtubeVideoId, {
            height: "390",
            width: "640",
            videoId: youtubeVideoId,
            events: {
                onReady: onPlayerReady,
                onStateChange: onPlayerStateChange,
            },
        })
    }
    function onPlayerReady(event) {
        event.target.playVideo()
        setplayerVal(event?.target)
    }
    function onPlayerStateChange(event) {
        playerVal == null && setplayerVal(event?.target) //if onPlayerReady doesnt work
        setplayerState(event?.data)
        if (event.data == 0 || event.data == 2) {
            setVideoState(!isPlayVideo)
        }
    }

    const showPlayCls = () => {
        if (!isPlayVideo) {
            return style?.showImg
        } else {
            return style?.hideImg
        }
    }
    const showVideoCls = () => {
        if (isPlayVideo) {
            return style?.videoFrame
        } else {
            return style?.videoFrameHide
        }
    }

    useEffect(() => {
        //hide image while buffering
        if (!isPlayVideo && playerVal && playerVal != undefined && playerState == 3) {
            setVideoState(!isPlayVideo)
        }
        //stop all videos running in background
        if (!activeVideo) {
            if (typeof playerVal?.pauseVideo !== "undefined" && playerVal != null) {
                playerVal?.pauseVideo()
            }
            //pause all videos if player is not loaded completly
            else {
                let video = document.getElementById(youtubeVideoId)
                let videostatus = "pauseVideo"
                const elem = video?.contentWindow
                elem?.postMessage(
                    '{"event":"command", "func":"' + videostatus + '", "args":""}', "*")
            }
            setVideoState(false)
        }
    })
    //to maintain play pause toggle functionality pased on
    useEffect(() => {
        isPlayVideo && payIframeAPIReady()
        if (isPlayVideo && playerVal && (playerState == 2 || playerState == 0)) {
            playerVal?.playVideo != undefined && playerVal.playVideo()
        }
    }, [isPlayVideo])

    return (
        <div className={style?.videoCntr}>
            <Button className={showPlayCls()} onClick={playPauseVideo}>
                <Image contentfulImage={posterImage} className={style?.playBtnImg} />
                <Image contentfulImage={imageConstants.playBtn} className={style?.playButton} />
            </Button>
            <div className={showVideoCls()}>
                <iframe
                    src={videoLink}
                    width={"700"}
                    height={"400"}
                    allowFullScreen
                    title={title || "YouTube video player"}
                    allow={"autoplay"}
                    id={youtubeVideoId}
                    className={[style?.videoItem, gaEventClasses.video_play].join(" ")}
                    data-action-detail={`${videoLink}`}
                    csp="*.youtube.com"
                />
            </div>
        </div>
    )
}

VideoPlayer.propTypes = {
    posterImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isIframe: PropTypes.bool,
    title: PropTypes.string,
    youtubeVideoId: PropTypes.string,
    videoId: PropTypes.string,
    style: PropTypes.object,
    isImageToggle: PropTypes.bool,
    image: PropTypes.object,
    activeVideo: PropTypes.bool,
}

export default VideoPlayer
