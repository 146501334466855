import React from "react"
import PropTypes from "prop-types"
import ModalWrapper from "../../../Wrappers/ModalWrapper/ModalWrapper"
import { labels } from "../../../../constants/label.constants"
import clsx from "clsx"

const VideoModal = props => {
    const { isModalOpen, closeVideoModal, isIframe, src, title, gaEventClass, gaEventLabel } = props
    return (
        <ModalWrapper
            closeOnBodyclick={true}
            isModalOpen={isModalOpen}
            closeModal={closeVideoModal}
            variant={"HOMEPAGE_VIDEO_MODAL"}
        >
            <div className={"relative w-[100%] flex justify-center"}>
                {isIframe ? (
                    <iframe
                        // loading="lazy"
                        src={src}
                        frameBorder="0"
                        width="100%"
                        height="100%"
                        allowFullScreen
                        title={title || "YouTube video player"}
                        allow="autoplay; encrypted-media"
                        csp="*.youtube.com"
                    />
                ) : (
                    <video
                        // poster={poster}
                        controls
                        autoPlay={true}
                        id="video-player"
                        width={"100%"}
                        height={"100%"}
                        data-action-detail={gaEventLabel}
                        className={clsx(
                            gaEventClass,
                            "video-player w-[80%] h-[80%] relative overflow-hidden aspect-[9/5]"
                        )}
                    >
                        <source src={src} type="video/mp4" />
                        {labels?.videoNotSupported}
                    </video>
                )}
            </div>
        </ModalWrapper>
    )
}

VideoModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    closeVideoModal: PropTypes.func.isRequired,
    isIframe: PropTypes.bool,
    src: PropTypes.string,
    title: PropTypes.string,
    gaEventClass: PropTypes.string,
    gaEventLabel: PropTypes.string,
}

export default VideoModal
