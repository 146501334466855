export const defaultStyles = {
    wrapper:
        "pillar-card relative max-w-pillarCard min-[540px]:max-w-[507px] mlg:max-w-[47.9%] xlg:max-w-[45.9%] px-[16px] " +
        "md:px-[24px] xlg:px-[32px] pt-[100px] md:pt-[64px] mlg:pt-[96px] xlg:pt-[140px] pb-[48px] md:pb-[54px] " +
        "cursor-pointer first:z-[1] rounded-[48px] bg-hero-card-texture bg-repeat-y bg-[-100px] shadow-[0px_16px_16.2px_0px_rgba(24,48,33,0.23)]",

    imageWrapper: "relative",
    image: "absolute bottom-[12px] left-[50%] translate-x-[-50%] max-w-[320px] md:max-w-[240px] mlg:max-w-[320px] xlg:max-w-[100%]",

    contentWrapper: "pillars-transition overflow-hidden",

    label: "flex items-center gap-[4px] md:gap-[8px] py-[4px] px-[8px] md:px-[16px] rounded-[12px] mt-[8xp] md:mt-[16px] bg-lightBeige",
    labelIcon: "w-[48px] h-[48px] md:w-[64px] md:h-[64px]",
    labelText: "text-[16px] leading-[100%] md:text-[20px] font-bite text-primary",

    actionIcon: "cards-icon flex justify-end absolute right-[12px] bottom-[12px] md:right-[24px] md:bottom-[8px]",
}
