import React, { useRef } from "react"
import PropTypes from "prop-types"
import * as styles from "./Header-tw-styles"
import { useGlobalContext } from "../../../context/GlobalContext"
import Image from "../../UI/Image/Image"
import Link from "../../UI/Link/Link"
import { gaEventClasses, gaEventLabels } from "../../../constants/gtm.constants"
import clsx from "clsx"
import CountrySelector from "../CountrySelector/CountrySelector"
import { scrollOnTop } from "../../../helpers/utils"

const Header = props => {
    const { logo, logoScrolled, countrySelectorCollection, languageCode, isHygienePage } = props
    const labels = useGlobalContext().labels
    const headerWrapper = useRef(null)
    const {
        headerContainer,
        headerContent,
        headerLogo,
        logoImg,
        logoScrollImg,
        hygienePageLogo,
        scrolledLogoLink,
    } = styles.defaultStyle
    const isHomepage = isHygienePage ? `/${languageCode}` : "#"

    return (
        <header className={clsx(headerContainer, isHygienePage && "absolute !px-0")} ref={headerWrapper}>
            {!isHygienePage && (
                <Link
                    url={isHomepage}
                    onClick={() => scrollOnTop(event)}
                    title={labels?.header?.homepage}
                    gaEventClass={labels?.eventClasses?.headerLogoClick}
                    gaEventLabel={labels?.eventLabels?.headerLogoClick}
                    className={scrolledLogoLink}
                >
                    <Image
                        contentfulImage={logoScrolled}
                        className={logoScrollImg}
                        alt={logoScrolled?.title || logoScrolled?.description || "Logo"}
                    />
                </Link>
            )}
            <div className={clsx(headerContent, isHygienePage && "!max-w-[1440px] xlg:px-0 px-[16px]")}>
                <div className={headerLogo}>
                    <Link
                        url={isHomepage}
                        onClick={() => scrollOnTop(event)}
                        title={labels?.header?.homepage}
                        gaEventClass={labels?.eventClasses?.headerLogoClick}
                        gaEventLabel={labels?.eventLabels?.headerLogoClick}
                    >
                        {isHygienePage ? (
                            <Image
                                contentfulImage={logoScrolled}
                                className={hygienePageLogo}
                                alt={logoScrolled?.title || logoScrolled?.description || "Logo"}
                            />
                        ) : (
                            <Image
                                quality={10}
                                className={logoImg}
                                contentfulImage={logo}
                                width={labels?.header?.logo?.width}
                                height={labels?.header?.logo?.height}
                                eventClass={gaEventClasses.logo}
                                eventLabel={gaEventLabels.logo}
                            />
                        )}
                    </Link>
                </div>

                <CountrySelector languageCode={languageCode} {...countrySelectorCollection} />
            </div>
        </header>
    )
}

Header.propTypes = {
    logo: PropTypes.object,
    logoScrolled: PropTypes.object,
    countrySelectorCollection: PropTypes.object,
    languageCode: PropTypes.string,
    isHygienePage: PropTypes.bool
}

export default Header
