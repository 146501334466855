import { useEffect, useState } from "react"

const useScreenSize = () => {
    const LARGE_SCREEN = 1600
    const SMALL_DESKTOP_BREAKPOINT = 1280
    const DESKTOP_BREAKPOINT = 1024
    const TABLET_BREAKPOINT = 768
    const SMALL_SCREEN = 992

    const [windowSize, setWindowSize] = useState({
        width: undefined,
        isMobile: undefined,
        isTablet: undefined,
        isHandheld: undefined,
        isSmallDesktop: undefined,
        isDesktop: undefined,
        isLargeScreen: undefined,
        isSmallScreen: undefined,
    })

    useEffect(() => {
        const handleResize = () =>
            setWindowSize({
                width: window.innerWidth,
                isMobile: window.innerWidth < TABLET_BREAKPOINT,
                isTablet: window.innerWidth >= TABLET_BREAKPOINT && window.innerWidth < DESKTOP_BREAKPOINT,
                isSmallDesktop: window.innerWidth < SMALL_DESKTOP_BREAKPOINT,
                isHandheld: window.innerWidth < DESKTOP_BREAKPOINT,
                isDesktop: window.innerWidth >= DESKTOP_BREAKPOINT,
                isLargeScreen: window.innerWidth >= LARGE_SCREEN,
                isSmallScreen: window.innerWidth >= SMALL_SCREEN && window.innerWidth <= SMALL_DESKTOP_BREAKPOINT,
            })

        // Add event listener
        window.addEventListener("resize", handleResize)

        // Call handler right away so state gets updated with initial window size
        handleResize()
        return () => {
            // Remove event listener on cleanup
            window.removeEventListener("resize", handleResize)
        }
    }, []) // Empty array ensures that effect is only run on mount

    return windowSize
}

export default useScreenSize
