import React from "react"
import PropTypes from "prop-types"
import InlineView from "./InlineView"
import ModalView from "./ModalView"

const Video = props => {
    const {
        videoFile,
        youtubeVideoId,
        posterImage,
        style,
        disabled = false,
        isModal,
        title,
        isImageToggle,
        isContentDisplayed,
        hidePlayButton,
        variant,
        gaEventClass,
        gaEventLabel,
    } = props
    const isIframe = !!youtubeVideoId
    function getVideoLink() {
        if (!!videoFile) {
            return videoFile.url
        } else if (isIframe) {
            return `https://www.youtube-nocookie.com/embed/${youtubeVideoId}?enablejsapi=1`
        }
        return null
    }

    const videoLink = getVideoLink()

    const renderVideo = () => {
        if (videoFile && videoFile.url) {
            return (
                <Video
                    className={style?.videoframe}
                    src={videoFile?.url}
                    loop={true}
                    muted={true}
                    autoPlay={true}
                    controls={false}
                    variant={variant}
                ></Video>
            )
        } else {
            return (
                <InlineView
                    poster={posterImage}
                    style={style}
                    isIframe={isIframe}
                    videoLink={videoLink}
                    title={title}
                    videoId={youtubeVideoId}
                    isImageToggle={isImageToggle}
                />
            )
        }
    }
    return isModal ? (
        <ModalView
            isContentDisplayed={isContentDisplayed}
            poster={posterImage}
            style={style}
            isIframe={isIframe}
            videoLink={videoLink}
            title={title}
            disabled={disabled}
            hidePlayButton={hidePlayButton}
            variant={variant}
            src={videoFile}
            gaEventLabel={gaEventLabel}
            gaEventClass={gaEventClass}
        />
    ) : (
        renderVideo()
    )
}

Video.propTypes = {
    videoFile: PropTypes.string,
    youtubeVideoId: PropTypes.string,
    posterImage: PropTypes.object,
    style: PropTypes.object,
    isModal: PropTypes.bool,
    title: PropTypes.string,
    isImageToggle: PropTypes.bool,
    isContentDisplayed: PropTypes.bool,
    hidePlayButton: PropTypes.bool,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    gaEventClass: PropTypes.string,
    gaEventLabel: PropTypes.string,
}

export default Video
