import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import ContentfulComponents from "../../helpers/ContentfulComponents"
import useImage from "../../hooks/useImage"
import { convertToId } from "../../helpers/utils"
import * as styles from "./SectionWrapper-tw-styles"
import Image from "../UI/Image/Image"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"
import { decorAnim } from "../../helpers/anim.helpers"

const SectionWrapper = props => {
    const { stickyMenuItem, backgroundImage, content, variant, decorImage } = props
    const { foregroundImg } = useImage(backgroundImage)
    const menuId = stickyMenuItem && convertToId(stickyMenuItem?.title, true)
    const style = styles[variant] || styles.defaultStyles
    const sectionWrapper = useRef(null)

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)

        const decors = sectionWrapper.current.querySelectorAll(".decor")
        if (!decorImage || !decors) return
        decorAnim(decors)

    }, [])

    return Array.isArray(content) && content?.length > 0 ? (
        <div
            ref={sectionWrapper}
            id={menuId}
            className={style?.background}
            style={foregroundImg ? { backgroundImage: `url(${foregroundImg?.url})` } : {}}
        >
            {decorImage && <Image contentfulImage={decorImage} className={style?.decorImage} />}
            <ContentfulComponents componentsProps={content} />
        </div>
    ) : null
}

SectionWrapper.propTypes = {
    backgroundImage: PropTypes.object,
    decorImage: PropTypes.object,
    content: PropTypes.array,
    stickyMenuItem: PropTypes.object,
    variant: PropTypes.string
}

export default SectionWrapper
