import React, { useState } from "react"
import PropTypes from "prop-types"
import Image from "../Image/Image"
import Icon from "../Icon/Icon"
import VideoModal from "./VideoModal/VideoModal"
import Button from "../Button/Button"
import { gaEventClasses } from "../../../constants/gtm.constants"

const ModalView = props => {
    const { poster, isIframe, title, src, gaEventClass, gaEventLabel } = props
    const [isModalOpen, setIsModalOpen] = useState(false)

    return (
        <>
            <Button
                onClick={() => {
                    setIsModalOpen(true)
                }}
                className={`${gaEventClasses.button_click} ${gaEventClasses.video_play}`}
                dataActionDetail={title || "video"}
                aria-label="Play video"
            >
                <Image contentfulImage={poster} />

                <Icon
                    name="PlayMediaIcon"
                    className={
                        "bg-play-meddia-icon absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]"
                    }
                />
            </Button>

            {isModalOpen && (
                <VideoModal
                    isModalOpen={isModalOpen}
                    closeVideoModal={() => {
                        setIsModalOpen(false)
                    }}
                    isIframe={isIframe}
                    src={src}
                    title={title}
                    gaEventLabel={gaEventLabel}
                    gaEventClass={gaEventClass}
                />
            )}
        </>
    )
}

ModalView.propTypes = {
    poster: PropTypes.object,
    videoLink: PropTypes.string,
    isIframe: PropTypes.bool,
    title: PropTypes.string,
    src: PropTypes.string,
    gaEventClass: PropTypes.string,
    gaEventLabel: PropTypes.string,
}

export default ModalView
