import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { gaEventClasses } from '../../../constants/gtm.constants'

const TabSwitcher = props => {
    const { currentTab = 0, onTabChange, tabs, className } = props

    return (
        <ul className={clsx("bg-greenHeaders flex rounded-[50px]", className)}>
            {tabs.map((tab, index) => (
                <li key={index} className={"flex-1 md:flex-none"}>
                    <button
                        type={"button"}
                        className={clsx(
                            "rounded-[50px] border-[4px] border-greenHeaders font-medium text-[16px] leading-none h-full",
                            currentTab === index &&
                                "bg-warmWhite py-[20px] px-[25px] xm:py-[24px] xm:px-[32px] md:p-[38px]",
                            currentTab !== index &&
                                "text-warmWhite underline py-[20px] px-[20px] xm:py-[24px] xm:px-[28px] md:p-[38px]",
                            gaEventClasses.button_click,
                        )}
                        onClick={() => {
                            onTabChange(index)
                        }}
                        data-action-detail={tabs[index]}
                    >
                        {tabs[index]}
                    </button>
                </li>
            ))}
        </ul>
    )
}

TabSwitcher.propTypes = {
    currentTab: PropTypes.number,
    tabs: PropTypes.arrayOf(PropTypes.string),
    onTabChange: PropTypes.func,
    className: PropTypes.string,
}

export default TabSwitcher
