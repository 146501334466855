import { useEffect, useState } from "react"
import useScreenSize from "./useScreenSize"

const useImage = images => {
    const { imageDesktop, imageTablet, imageMobile, bgDesktop, bgTablet, bgMobile, altText } = images || {}
    const [foregroundImg, setForegroundImg] = useState(null)
    const [backgroundImg, setBackgroundImg] = useState(null)
    const screen = useScreenSize()

    useEffect(() => {
        if (screen?.isMobile) {
            setBackgroundImg(bgMobile || bgTablet || bgDesktop)
            setForegroundImg(imageMobile || imageTablet || imageDesktop)
        } else if (screen?.isSmallDesktop) {
            setBackgroundImg(bgTablet || bgMobile || bgDesktop)
            setForegroundImg(imageTablet || imageMobile || imageDesktop)
        } else {
            setBackgroundImg(bgDesktop || bgTablet || bgMobile)
            setForegroundImg(imageDesktop || imageTablet || imageMobile)
        }
    }, [screen])

    return {
        altText,
        foregroundImg,
        backgroundImg,
    }
}

export default useImage
