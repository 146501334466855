import React from "react"
import PropTypes from "prop-types"
import Video from "../UI/Video/Video"
import * as styles from "../VideoSection/VideoSection-tw-styles"
import Typography from "../UI/Typography/Typography"

const VideoSection = props => {
    const { text, variant, video, gaEventClass, gaEventLabel, onClick = () => {} } = props
    const style = styles.defaultStyle

    return (
        <div className={style?.imageBoxWraper}>
            <div className={style?.contentWrapper}>
                <Typography className={style?.heading} content={text} allow_br_tag={true} />
            </div>
            <div className={style?.imageBoxWrpr}>
                <div className={style?.videoWrapper} onClick={onClick}>
                    <Video
                        posterImage={video?.placeholderImage}
                        isModal={true}
                        title={video?.placeholderImage?.title}
                        className={style?.imgWrpr}
                        gaEventClass={gaEventClass}
                        gaEventLabel={gaEventLabel}
                        videoFile={props?.video?.video?.url}
                        style={style?.videoStyles}
                        variant={variant}
                    />
                </div>
            </div>
        </div>
    )
}

VideoSection.propTypes = {
    text: PropTypes.string,
    videoId: PropTypes.string,
    variant: PropTypes.string,
    video: PropTypes.object,
    gaEventClass: PropTypes.string,
    gaEventLabel: PropTypes.string,
    onClick: PropTypes.func,
}

export default VideoSection
