import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { convertToId, get } from "../../helpers/utils"
import Image from "../UI/Image/Image"
import clsx from "clsx"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useGlobalContext } from "../../context/GlobalContext"
import useUUID from "../../hooks/useUUID"
import useScreenSize from "../../hooks/useScreenSize"
import { styles } from "./StickyProgressMenu-tw-styles"

const StickyProgressMenu = props => {
    const { menuItemsCollection } = props
    const menuItems = get(menuItemsCollection, "items", [])
    const [height, setHeight] = useState(0)
    const [active, setActive] = useState(0)
    const [transform, setTransform] = useState(0)
    const menuItemsKeys = useUUID(menuItems?.length)
    const mobileProgressRef = useRef(null)
    const screen = useScreenSize()
    const labels = useGlobalContext()
    const isMobile = screen?.isMobile || screen?.isTablet || screen?.isHandheld
    const isLarge = screen?.isLargeScreen
    const isSmall = screen?.isSmallScreen

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)

        const mainWrapper = document.querySelector(".main-wrapper")
        const footer = document.querySelector("footer")
        const allSections = gsap.utils.toArray(".main-wrapper > div, .main-wrapper > section")
        const allMenuItems = gsap.utils.toArray(".menu-item")
        const ratio = isLarge ? 0.6 : 0.3
        const maxHeight = () => {
            if (isSmall) {
                return 74 / 100
            } else if (isLarge) {
                return 164 / 100
            }

            return 124 / 100
        }

        const mobileProgressBar = mobileProgressRef.current

        if (!mainWrapper || !allSections || !mobileProgressBar) return

        if (!isMobile) {
            allSections.forEach((section, index) => {
                if (!allMenuItems[index]) return

                const progress = allMenuItems[index].querySelector(".progress")

                ScrollTrigger.create({
                    trigger: section,
                    start: "top top",
                    end: `+=${section.offsetHeight}px`,
                    scrub: 0.3,
                    onUpdate: self => {
                        if (!progress || allSections.length !== menuItems.length) return

                        if (index !== menuItems.length - 1) {
                            progress.style.height = `${Math.round(maxHeight() * self.progress * 100)}px`
                        }

                        if (ScrollTrigger.isInViewport(section, ratio)) {
                            setActive(index)
                        }
                    },
                })
            })
        }

        if (!isMobile) return

        const mobileMenu = document.querySelector(".mobile-menu>div")

        ScrollTrigger.create({
            trigger: mainWrapper,
            start: 0,
            end: `bottom +=${footer.clientHeight}px`,
            scrub: false,
            onUpdate: self => {
                if (!mobileMenu || allSections.length !== menuItems.length) return
                setHeight(self.progress * 100)

                allSections.forEach((el, index) => {
                    if (ScrollTrigger.isInViewport(el, 0.5)) {
                        setActive(index)
                    }

                    if (window.innerWidth < 768) {
                        setTransform(self.progress * 100)
                    }
                })
            },
        })
    }, [isMobile, isLarge, isSmall])

    const handleClick = index => {
        const allSections = document.querySelectorAll(".main-wrapper > div, .main-wrapper > section")
        if (!allSections || allSections.length !== menuItems.length) return

        const element = allSections[index]
        const yOffset = isMobile ? -60 : 20
        const y = element.getBoundingClientRect().top + window.scrollY + yOffset

        window.scrollTo({ top: y, behavior: "smooth" })
    }

    if (menuItems.length === 0) return null

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.innerWrapper}>
                    <div className={styles.progressNotScrolled} />
                    {menuItems.map((item, index) => (
                        <div key={menuItemsKeys[index]} className={styles.menuItemWrapper}>
                            <div className={styles.progressScrolled} />
                            <button
                                key={menuItemsKeys[index]}
                                onClick={() => handleClick(index)}
                                className={clsx(
                                    styles.button,
                                    index === active && styles.buttonActive,
                                    index < active && styles.buttonPassed,
                                    labels.getLabel("eventClasses.progressMenuClick", "event_menu_click")
                                )}
                                data-action-detail={convertToId(item.title)}
                            >
                                <span
                                    className={clsx(
                                        styles.icon,
                                        active === index ? styles.iconActive : styles.iconNotActive
                                    )}
                                >
                                    {item?.icon && (
                                        <Image
                                            contentfulImage={item?.icon}
                                            className={clsx(
                                                active !== index ? styles.desktopIconSize : styles.desktopIconSizeActive
                                            )}
                                        />
                                    )}
                                </span>
                                {item?.title && (
                                    <span className={styles.title} dangerouslySetInnerHTML={{ __html: item.title }} />
                                )}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.mobileWrapper}>
                <div className={styles.mobileScrolledSection} style={{ left: `${transform}%`, transform: `translateX(-${transform}%)` }}>
                    <div ref={mobileProgressRef} className={styles.mobileProgressBar} style={{ width: `${height}%` }} />
                    {menuItems.map((item, index) => (
                        <button
                            key={menuItemsKeys[index]}
                            onClick={() => handleClick(index)}
                            className={clsx(
                                styles.mobileButton,
                                styles.button,
                                index === active && styles.buttonActiveMobile,
                                labels.getLabel("eventClasses.progressMenuClick", "event_menu_click")
                            )}
                            data-action-detail={labels.getLabel(
                                "eventLabels.progressMenuClick",
                                "event_informational_action"
                            )}
                        >
                            <span className={styles.mobileIcon}>
                                {item?.icon && (
                                    <Image contentfulImage={item?.icon} className={clsx(styles.mobileIconsSize)} />
                                )}
                            </span>
                            {item?.title && (
                                <span className={styles.mobileTitle} dangerouslySetInnerHTML={{ __html: item.title }} />
                            )}
                        </button>
                    ))}
                </div>
            </div>
        </>
    )
}

export default StickyProgressMenu

StickyProgressMenu.propTypes = {
    menuItemsCollection: PropTypes.object,
}
