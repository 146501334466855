export const defaultStyle = {
    footerLinksMob: "w-full print-noshow bg-lightBeige mt-[88px] max-md:pt-[64px]",
    container: "w-full slg:max-[1280px]:max-w-[900px] max-w-[1140px] xlg:max-w-[1440px] mx-auto slg:max-mlg:max-w-[962px] md:max-mlg:px-[32px] max-md:px-[16px]",
    contentWrapper: "flex flex-wrap",
    languageWrapper: "flex w-full justify-between mb-[24px]",
    countrySelectorWrapper: "[&_section>button]:py-0",
    menuWrapper: "w-full flex justify-between max-md:flex-col",
    menuText: "mb-[24px] font-bite max-md:text-[32px] max-md:leading-[150%] max-slg:text-[28px] max-slg:leading-[100%] " +
        "max-xlg:text-[32px] max-xlg:leading-[150%] xlg:text-[28px] xlg:leading-[100%]",
    menuItemText: "text-[20px] leading-[150%] font-semibold",
    mobileLogoWrapper: "w-full mb-[48px] flex flex-col items-center justify-center slg:hidden",
    logoWrapper: "flex-1 relative -top-[88px] flex flex-col pl-[20px] xlg:pl-[180px] max-slg:hidden",
    harmonyLogo: "max-slg:w-[120px] max-slg:h-[120px] w-[140px] h-[140px]",
    mondelezLogo: "max-slg:w-[130px] max-slg:h-[30px] max-w-[140px] max-h-[32px] mt-[16px]",
    copyrightContainer: "text-[10px] text-center leading-[15px] inline-block w-full pt-[48px] pb-[16px]",
    beforeFooter: "relative max-slg:before:block  max-slg:before:absolute max-slg:before:bg-footer-texture-mobile  max-slg:before:h-[80px] " +
        "max-slg:before:-top-[120px] max-slg:before:w-full max-slg:before:left-0 max-slg:before:right-0 " +
        "after:block max-md:after:-top-[80px] after:-top-[40px] after:absolute after:left-0 " +
        "max-slg:after:bg-secondary " +
        "after:right-0 after:w-full after:h-[80px] " +
        "after:bg-footer-texture after:bg-no-repeat after:bg-center",
    additionalContainer: "pt-[48px] max-md:px-[24px] max-slg:pt-[32px]",

}
