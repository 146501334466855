import React from 'react'
import PropTypes from "prop-types"
import { get } from "../../../helpers/utils"
import useImage from "../../../hooks/useImage"
import Image from "../../UI/Image/Image"
import RichTextModel from "../../RichTextModel/RichTextModel"
import { CIRCLE_TEXT } from "../../RichTextModel/RichTextModel-tw-styles"

const LogoImage = ({ logoImage }) => {
    const logo =  useImage(logoImage)

    return (
        <Image contentfulImage={logo?.foregroundImg} className="max-slg:w-[72px] max-slg:h-[72px]"/>
    )
}

LogoImage.propTypes = {
    logoImage: PropTypes.object
}

const CircleCarouselText = ({ activeItem }) => {
    const descLogo = get(activeItem?.descriptionImagesCollection, "items", [])

    return (
        <div className="flex flex-col">
            {descLogo?.length > 0 && (
                <div className="flex gap-[20px] justify-center">
                    {descLogo.map((item, index) => <LogoImage key={`logo-img-${activeItem}-${index}`} logoImage={item} />)}
                </div>
            )}
            <RichTextModel richText={activeItem?.description} style={CIRCLE_TEXT}/>
        </div>
    )
}

export default CircleCarouselText

CircleCarouselText.propTypes = {
    activeItem: PropTypes.shape({
        description: PropTypes.object,
        descriptionImagesCollection: PropTypes.object
    })
}
