import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import ReCAPTCHA from "react-google-recaptcha"
import { Formik } from "formik"
import axios from "axios"
import * as yup from "yup"
import { v4 as uuidv4 } from "uuid"
import Typography from "../UI/Typography/Typography"
import { formSubmitObj, gaEventClasses, getCheckBoxEvent } from "../../constants/gtm.constants"
import {
    getArrayWithRangeOfNumbers,
    getDateForNewsletter,
    getDaysInMonth,
    isDate18orMoreYearsOld,
} from "../../helpers/utils"
import * as styles from "./NewsletterForm-tw-styles"
import { useGlobalContext } from "../../context/GlobalContext"

const postCampaignData = async payload => {
    try {
        return axios.post(process.env.GATSBY_FORM_API, payload)
    } catch (error) {
        throw new Error(error)
    }
}

const emailRegex = /^[\w\\.\d-_]+@[\w\\.\d-_]+\.\w{2,4}$/i

const formSchema = yup.object().shape({
    firstName: yup
        .string()
        .required("Please enter your first name")
        .min(0, "First name must have at least 0 and no more than 256 characters.")
        .max(255, "First name must have at least 0 and no more than 256 characters."),
    email: yup
        .string()
        .required("Please enter your email address")
        .matches(emailRegex, "Email has to be in correct format"),
    confirmEmail: yup
        .string()
        .oneOf([yup.ref("email")], "Emails have to match")
        .required("Please enter your email address"),
    day: yup.string(),
    month: yup.string(),
    year: yup.string(),
    confirmNewsletter: yup.boolean().required().oneOf([true]),
})

const NewsletterForm = props => {
    const {
        showThanksButton = false,
        title,
        perex,
        firstNameLabel,
        emailAddressLabel,
        confirmEmailAddressLabel,
        dateOfBirthLabel,
        newsletterLabel,
        useOfDataLabel,
        disclaimer,
        buttonLabel,
        thanksTitle,
        thanksPerex,
        thanksButtonLabel,
        styleOverride,
    } = props
    const [isOldEnough, setIsOldEnough] = useState(true)
    const [isValidDate, setIsValidDate] = useState(true)
    const [recaptchaValue, setRecaptchaValue] = useState("")
    const [recaptchaDone, setRecaptchaDone] = useState(false)
    const [showRecaptchaError, setShowRecaptchaError] = useState(false)
    const [numOfDaysInMonth, setNumOfDaysInMonth] = useState(31)
    const [showThanks, setShowThanks] = useState(false)
    const labels = useGlobalContext().labels
    const style = styles.defaultStyle

    const handleDateChange = (day, month, year) => {
        if (!day || !month || !year) {
            setIsValidDate(false)
            setIsOldEnough(true)

            return
        }

        setIsValidDate(true)
        setIsOldEnough(isDate18orMoreYearsOld(day, month, year))
    }

    const handleRecaptcha = value => {
        setRecaptchaValue(value)
        value ? setRecaptchaDone(true) : setRecaptchaDone(false)
        value ? setShowRecaptchaError(false) : setShowRecaptchaError(true)
    }

    const handleCheckboxDatalayer = (category, action, value) => {
        if (window !== undefined && window.dataLayer) {
            const checkBoxEventObject = getCheckBoxEvent(category, action, value ? "true" : "false")

            window.dataLayer.push(checkBoxEventObject)
        }
    }

    const submitHandler = async params => {
        if (recaptchaDone) {
            setShowRecaptchaError(false)

            const payload = {
                requestDomain: "www.belvitasnacks.com",
                requestCountry: "uk",
                requestLanguage: "en",
                requestCampaign: "BVUK230401 Belvita.UK Newsletter Registration",
                requestTransaction: "BVUK230401 Belvita.UK Newsletter Registration (IN)",
                requestLegalListName: "Privacy_Policy_EN",
                requestLegalTextName: "PrivacyPolicy_EN",
                "g-recaptcha-response": recaptchaValue,
                requestBody: {
                    firstName: params.firstName,
                    email: params.email,
                    dob: isValidDate ? getDateForNewsletter(params.day, params.month, params.year) : "",
                    confirmNewsletter: params.confirmNewsletter ? 1 : 0,
                    confirm3rdParty: params.confirm3rdParty ? 1 : 0,
                    group: uuidv4(),
                },
            }

            postCampaignData(payload)
                .then(response => {
                    setShowThanks(true)

                    if (window !== "undefined" && window.dataLayer) {
                        return window.dataLayer.push({ event: "email_event_capture", ...payload.requestBody })
                    }
                })
                .then(error => {
                    // failure: add custom object to window datalyer obj when form is submitted
                    formSubmitObj.GAeventAction = { ...payload.requestbody, submit: "failure" }
                    if (location && location.href) {
                        formSubmitObj.GAeventLabel = location.href
                    }
                    if (window !== "undefined" && window.dataLayer) {
                        return window.dataLayer.push({ ...formSubmitObj })
                    }
                })
        } else {
            setShowRecaptchaError(true)
        }
    }

    return (
        <>
            {!showThanks && (
                <div className={style?.wrapper}>
                    {title && <h2 className={clsx(style?.heading, styleOverride?.heading)}>{title}</h2>}
                    {perex && (
                        <Typography
                            className={clsx(style?.perex, styleOverride?.perex)}
                            allow_br_tag={true}
                            content={perex}
                        />
                    )}

                    <Formik initialValues={{}} validationSchema={formSchema} onSubmit={submitHandler}>
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            submitCount,
                            isSubmitting,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} className={style?.form}>
                                    <div className={clsx(style?.inputRow, styleOverride?.inputRow)}>
                                        <div className={clsx(style?.inputCol, styleOverride?.inputCol)}>
                                            <label className={style?.textLabel}>{firstNameLabel}</label>
                                            <input
                                                type="text"
                                                className={clsx(
                                                    style?.inputText,
                                                    (touched.firstName || submitCount > 0) && errors.firstName
                                                        ? style?.inputError
                                                        : "",
                                                    values.firstName ? "!bg-white" : ""
                                                )}
                                                name="firstName"
                                                value={values.firstName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {(touched.firstName || submitCount > 0) && errors.firstName && (
                                                <div className={style?.errorMsg}>{errors.firstName}</div>
                                            )}
                                        </div>

                                        <div className={clsx(style?.inputCol, styleOverride?.inputCol)}>
                                            <label className={style?.textLabel}>{emailAddressLabel}</label>
                                            <input
                                                type="email"
                                                className={clsx(
                                                    style?.inputText,
                                                    (touched.email || submitCount > 0) && errors.email
                                                        ? style?.inputError
                                                        : "",
                                                    values.email ? "!bg-white" : ""
                                                )}
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {(touched.email || submitCount > 0) && errors.email && (
                                                <div className={style?.errorMsg}>{errors.email}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className={clsx(style?.inputRow, styleOverride?.inputRow)}>
                                        <div className={clsx(style?.inputCol, styleOverride?.inputCol)}>
                                            <label className={style?.textLabel}>{confirmEmailAddressLabel}</label>
                                            <input
                                                type="email"
                                                className={clsx(
                                                    style?.inputText,
                                                    (touched.confirmEmail || submitCount > 0) && errors.confirmEmail
                                                        ? style?.inputError
                                                        : "",
                                                    values.confirmEmail ? "!bg-white" : ""
                                                )}
                                                name="confirmEmail"
                                                value={values.confirmEmail}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {(touched.confirmEmail || submitCount > 0) && errors.confirmEmail && (
                                                <div className={style?.errorMsg}>{errors.confirmEmail}</div>
                                            )}
                                        </div>

                                        <div className={clsx(style?.inputCol, styleOverride?.inputCol)}>
                                            <label className={style?.textLabel}>{dateOfBirthLabel}</label>

                                            <div className={style?.selectsContainer}>
                                                <label className={style?.selectWrapper}>
                                                    <select
                                                        className={clsx(style?.select, values.day ? "!bg-white" : "")}
                                                        name="day"
                                                        onChange={e => {
                                                            const newVal = e.target.value

                                                            handleDateChange(newVal, values.month, values.year)
                                                            handleChange(e)
                                                        }}
                                                        onBlur={handleBlur}
                                                    >
                                                        <option disabled selected value>
                                                            DD
                                                        </option>
                                                        {getArrayWithRangeOfNumbers(1, numOfDaysInMonth).map(val => (
                                                            <option key={`day-${val}`} value={val}>
                                                                {val}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </label>

                                                <label className={style?.selectWrapper}>
                                                    <select
                                                        className={clsx(style?.select, values.month ? "!bg-white" : "")}
                                                        name="month"
                                                        onChange={e => {
                                                            const newVal = e.target.value
                                                            const numberOfDays = getDaysInMonth(values.year, newVal)

                                                            if (numberOfDays < parseInt(values.day)) {
                                                                setFieldValue("day", 1)
                                                                handleDateChange(1, newVal, values.year)
                                                            } else {
                                                                handleDateChange(values.day, newVal, values.year)
                                                            }

                                                            handleChange(e)

                                                            if (numberOfDays) {
                                                                setNumOfDaysInMonth(numberOfDays)
                                                            }
                                                        }}
                                                        onBlur={handleBlur}
                                                    >
                                                        <option disabled selected value>
                                                            MM
                                                        </option>
                                                        {labels.months &&
                                                            labels.months.map((month, i) => (
                                                                <option key={`month-${month}`} value={i + 1}>
                                                                    {month}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </label>

                                                <label className={style?.selectWrapper}>
                                                    <select
                                                        className={clsx(style?.select, values.year ? "!bg-white" : "")}
                                                        name="year"
                                                        onChange={e => {
                                                            const newVal = e.target.value
                                                            const numberOfDays = getDaysInMonth(newVal, values.month)

                                                            if (numberOfDays < parseInt(values.day)) {
                                                                setFieldValue("day", 1)
                                                                handleDateChange(1, values.month, newVal)
                                                            } else {
                                                                handleDateChange(values.day, values.month, newVal)
                                                            }

                                                            handleChange(e)

                                                            if (numberOfDays) {
                                                                setNumOfDaysInMonth(numberOfDays)
                                                            }
                                                        }}
                                                        onBlur={handleBlur}
                                                    >
                                                        <option disabled selected value>
                                                            YYYY
                                                        </option>
                                                        {getArrayWithRangeOfNumbers(1900, new Date().getFullYear()).map(
                                                            val => (
                                                                <option key={`year-${val}`} value={val}>
                                                                    {val}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </label>
                                            </div>

                                            {!isOldEnough && (
                                                <div className={style?.errorMsg}>You must be at least 18 years old</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className={styleOverride?.textWrapper}>
                                        <label className={style?.checkboxWrapper}>
                                            <input
                                                type="checkbox"
                                                name="confirmNewsletter"
                                                className={
                                                    (touched.confirmNewsletter || submitCount > 0) &&
                                                    errors.confirmNewsletter
                                                        ? style?.inputError
                                                        : ""
                                                }
                                                value={values.confirmNewsletter}
                                                onChange={e => {
                                                    handleCheckboxDatalayer(
                                                        "sign up form",
                                                        "opt in email",
                                                        e.target.checked
                                                    )
                                                    handleChange(e)
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            <Typography
                                                className={style?.checkboxLabel}
                                                allow_br_tag={true}
                                                content={newsletterLabel}
                                            />
                                        </label>

                                        <label className={style?.checkboxWrapper}>
                                            <input
                                                type="checkbox"
                                                name="confirm3rdParty"
                                                value={values.confirm3rdParty}
                                                onChange={e => {
                                                    handleCheckboxDatalayer(
                                                        "sign up form",
                                                        "third party opt in",
                                                        e.target.checked
                                                    )
                                                    handleChange(e)
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            <Typography
                                                className={style?.checkboxLabel}
                                                allow_br_tag={true}
                                                content={useOfDataLabel}
                                            />
                                        </label>

                                        <Typography
                                            className={style?.disclaimer}
                                            allow_br_tag={true}
                                            content={disclaimer}
                                        />

                                        {process.env.GATSBY_APP_SITE_KEY && (
                                            <ReCAPTCHA
                                                className={style?.captchaWrapper}
                                                sitekey={process.env.GATSBY_APP_SITE_KEY}
                                                onChange={e => handleRecaptcha(e)}
                                            />
                                        )}

                                        {showRecaptchaError && (
                                            <div className={clsx(style?.errorMsg, "text-center")}>
                                                Please fill in reCAPTCHA
                                            </div>
                                        )}

                                        <div className={style?.submitButtonWrapper}>
                                            <button
                                                type="submit"
                                                disabled={isSubmitting}
                                                className={clsx(style?.submitButton, gaEventClasses.button_click)}
                                                data-action-detail="Submit"
                                            >
                                                {buttonLabel}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            )
                        }}
                    </Formik>
                </div>
            )}

            {showThanks && (
                <div className={style?.thanksWrapper}>
                    {thanksTitle && <h2 className={style?.heading}>{thanksTitle}</h2>}
                    {thanksPerex && (
                        <Typography className={style?.subheading} allow_br_tag={true} content={thanksPerex} />
                    )}
                    {showThanksButton && (
                        <button
                            type="button"
                            className={clsx(style?.submitButton, gaEventClasses.button_click)}
                            onClick={() => document.dispatchEvent(new Event("close_newsletter_modal"))}
                            data-action-detail={thanksButtonLabel}
                        >
                            {thanksButtonLabel}
                        </button>
                    )}
                </div>
            )}
        </>
    )
}
NewsletterForm.propTypes = {
    showThanksButton: PropTypes.bool,
    title: PropTypes.string,
    perex: PropTypes.string,
    firstNameLabel: PropTypes.string,
    emailAddressLabel: PropTypes.string,
    confirmEmailAddressLabel: PropTypes.string,
    dateOfBirthLabel: PropTypes.string,
    newsletterLabel: PropTypes.string,
    useOfDataLabel: PropTypes.string,
    disclaimer: PropTypes.string,
    buttonLabel: PropTypes.string,
    thanksTitle: PropTypes.string,
    thanksPerex: PropTypes.string,
    thanksButtonLabel: PropTypes.string,
    styleOverride: PropTypes.object,
}

export default NewsletterForm
