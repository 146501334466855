export const defaultStyle = {
    wrapper: "relative w-full",
    imgTxtContainer:
        "flex max-mlg:flex-wrap w-full max-mlg:max-w-[992px] slg:max-[1280px]:max-w-[900px] max-w-[1140px] xlg:max-w-[1440px] mx-auto gap-[48px] " +
        "xlg:gap-[142px] max-mlg:px-[16px]",
    reverse: "flex-row-reverse",
    imgWrapper: "w-full mlg:w-7/12  max-mlg:order-2",
    textImg: "w-full ",
    textWrpr: "w-full mlg:w-5/12 flex items-center  max-mlg:order-1",
    textContent: "w-1/2",
    textHead: "style-h5 text-primary",
    textDesc: "text-[24px] leading-[150%] max-slg:text-[18px] mlg:max-[1280px]:text-[18px] font-medium",
    textLink: "",
    imgBtn: "",
    center: "text-center",
}

export const ERROR_CONTAINER = {
    imgTxtContainer: "",
    imgWrapper: "",
    textImg: "",
    textWrpr: "mx-auto md:w-[437px] px-12 md:px-0 text-center",
    textContent: "text-lg leading-7 text-center w-[354px] m-w-[354px] text-[#7f7f7f] mx-auto my-auto",
    textHead: "md:text-[30px] text-[60px] tracking-[1px] md:leading-[30px] leading-[60px] my-15",
    textDesc: "text-[20px] leading-[28px] my-15",
    textLink:
        "uppercase font-ProxBold bg-white border-solid border-2 border-darkBlue block text-[18.75px] leading-[26.25px] mx-auto py-15 px-20 text-center w-[296px] h-[58px] max-w-[90%] rounded-[30px] my-15 hover:border-darkBlue hover:border-2 hover:text-white hover:bg-primary transition duration-500 ease-in-out",
    imgBtn: "",
    wrapper: "pt-[85px] md:pb-[250px] pb-[50px] min-h-full relative top-[-70px] bg-cover bg-no-repeat bg-left-top",
}

export const FARMERS = {
    wrapper: "relative w-full",
    imgTxtContainer: "flex max-mlg:flex-wrap w-full max-mlg:px-[16px] slg:max-[1280px]:max-w-[900px] max-w-[1140px] xlg:max-w-[1440px] mx-auto " +
        "gap-[48px] xlg:gap-[142px] max-slg:gap-[24px] md:max-slg:flex-row-reverse max-mlg:justify-center",
    imgWrapper: "max-slg:w-1/2 w-7/12 max-md:w-full max-md:order-2",
    textImg: "w-full ",
    textWrpr: "max-slg:w-1/2 w-5/12 max-md:w-full flex items-center max-md:order-1",
    textContent: "w-1/2",
    textHead: "style-h5 text-primary",
    textDesc: "image-with-text-farmers--desc",
    textLink: "",
    imgBtn: "",
    center: "text-center",
}

export const AMBITION_LOGO_LINK = {
    wrapper: "relative w-full",
    imgTxtContainer: "flex flex-col w-full max-mlg:px-[16px] slg:max-[1280px]:max-w-[900px] max-w-[1140px] xlg:max-w-[1440px] mx-auto " +
        "gap-[48px] max-slg:gap-[24px] justify-center items-center",
    imgWrapper: "",
    textImg: "w-full max-w-[240px] max-slg:max-w-[131px]",
    textWrpr: "flex items-center max-md:mx-[16px]",
    textContent: "",
    textHead: "style-h5 text-primary",
    textDesc: "",
    textLink: "link-with-texture / max-md:before:-left-[35px] max-md:after:-right-[35px]",
    imgBtn: "",
    center: "text-center",
}

export const AMBITION_BLOCK = {
    wrapper: "relative w-full py-[48px] max-slg:pt-[24px] max-slg:pb-0",
    imgTxtContainer: "flex max-mlg:flex-wrap w-full slg:max-[1280px]:max-w-[900px] max-w-[1140px] xlg:max-w-[1440px] mx-auto gap-[48px] max-slg:gap-[24px] " +
        "xlg:gap-[142px] max-mlg:px-[16px] slg:max-mlg:max-w-[704px] slg:max-mlg:mx-auto",
    reverse: "flex-row-reverse",
    imgWrapper: "w-full mlg:w-7/12  max-mlg:order-2 max-slg:max-w-[523px] max-slg:mx-auto",
    textImg: "w-full ",
    textWrpr: "w-full mlg:w-5/12 flex items-center  max-mlg:order-1",
    textContent: "w-1/2",
    textHead: "style-h5 text-primary",
    textDesc: "ambition-text-block / text-[20px] leading-[150%] max-slg:text-[16px]",
    textLink: "",
    imgBtn: "",
    center: "text-center",
    imageDesc: "image-desc / relative -top-[25px] accent-body-m text-center p-[16px] z-[1] max-[480px]:min-h-[140px] min-h-[95px] " +
        "xlg:min-h-[121px] [&>p]:px-[32px] flex items-center",
}

export const PART_OF_HARMONY = {
    ...defaultStyle,
    wrapper: "bg-no-repeat bg-bottom min-h-[680px] slg:bg-[length:100%_275px]",
    textImg: "md:max-slg:max-w-[526px] mx-auto",
}

export const TEXT_HERO = {
    ...defaultStyle,
    wrapper: "hygiene-hero / relative min-h-[275px] slg:min-h-[555px] bg-cover bg-no-repeat mb-[80px]",
    textWrpr: "max-w-[954px] w-full mx-auto px-[16px]",
    imgTxtContainer: "pt-[88px] slg:pt-[158px]",
    textDesc: "hygiene-hero-text",
}

export const TEXT_HERO_404 = {
    ...defaultStyle,
    wrapper: "hygiene-hero / relative min-h-[275px] slg:min-h-[555px] bg-cover bg-no-repeat mb-[80px]",
    textWrpr: "max-w-[954px] w-full mx-auto px-[16px]",
    imgTxtContainer: "pt-[108px] slg:pt-[158px]",
    textDesc: "text-404 max-slg:pb-[56px] max-slg:text-center",
}
