import {gaEventClasses} from "../constants/gtm.constants";

export const getUrlWithTrailingSlash = (url, isExternallink) => {
    if (url)
        return !url?.trim()?.endsWith("/") && !(url?.trim()?.endsWith(".pdf") || isExternallink) ? `${url}/` : url
    else return undefined
}

export const getHref = (pageUrl, scrollToElementId) => {
    if (pageUrl && scrollToElementId) {
        return `${pageUrl}#${scrollToElementId}`
    } else if (!pageUrl && scrollToElementId) {
        return `#${scrollToElementId}`
    } else return pageUrl
}

export const getEventFinalClass = (gaEventClass, isExternallink) => {
    if (gaEventClass) return gaEventClass
    else return isExternallink ? gaEventClasses.external_link : gaEventClasses.internal_link
}

export const getLinkGALabel = (gaEventLabel, label, href) => (gaEventLabel || label || href)

export const pushLinkToDataLayer = (window, eventClassFinal, linkGALabel) =>{
    if (window !== "undefined" && window.dataLayer) {
        window.dataLayer.push({
            event: "CustomEvent",
            GAeventCategory: gaEventClasses.informational_action,
            GAeventAction: eventClassFinal,
            GAeventLabel: linkGALabel,
            GAeventValue: 0,
            GAeventNonInteraction: false,
        })
    }
}
