import React from "react"

const Close = (className, name) => (
    <div className="flex items-center justify-center ">
        <div className="w-[20px] h-[20px] ">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.19239 17.0898C9.92606 14.3561 10.3082 10.306 10.0208 10.0187C9.73348 9.73136 5.68342 10.1135 2.94975 12.8471C0.216078 15.5808 -0.161023 19.6359 0.12132 19.9182C0.403663 20.2005 4.45872 19.8234 7.19239 17.0898Z"
                    fill="#006D35"
                />
                <path
                    d="M7.19133 2.94824C4.45766 0.214567 0.407604 -0.167531 0.120263 0.119809C-0.167077 0.407149 0.215021 4.45721 2.94869 7.19088C5.68236 9.92455 9.73741 10.3016 10.0198 10.0193C10.3021 9.73696 9.925 5.68191 7.19133 2.94824Z"
                    fill="#006D35"
                />
                <path
                    d="M17.0917 12.8506C14.3581 10.1169 10.308 9.73481 10.0207 10.0222C9.73331 10.3095 10.1154 14.3596 12.8491 17.0932C15.5828 19.8269 19.6378 20.204 19.9201 19.9216C20.2025 19.6393 19.8254 15.5843 17.0917 12.8506Z"
                    fill="#006D35"
                />
                <path
                    d="M17.0908 7.19133C19.8245 4.45766 20.2066 0.407604 19.9193 0.120263C19.6319 -0.167077 15.5819 0.215021 12.8482 2.94869C10.1145 5.68236 9.73741 9.73741 10.0198 10.0198C10.3021 10.3021 14.3572 9.925 17.0908 7.19133Z"
                    fill="#006D35"
                />
            </svg>
        </div>
    </div>
)

export default Close
