export const styles = {
    wrapper:
        "max-slg:hidden mlg:max-[1280px]:translate-y-[20px] h-full flex items-center fixed z-[9999] left-0 max-w-[1920px] mx-auto pointer-events-none",
    innerWrapper: "relative ml-[24px] min-w-[120px] justify-between flex flex-col pointer-events-auto",
    progressNotScrolled: "absolute left-[22px] w-[4px] bg-menu-dashed h-full z-[0]",
    menuItemWrapper:
        "menu-item / relative pb-[52px] mlg:max-[1280px]:pb-[30px] min-[1600px]:pb-[80px] last-of-type:pb-0",
    progressScrolled: "progress / top-0 absolute left-[22px] w-[4px] bg-primary z-[1]",
    button: "relative z-[1] flex [&>span:first-of-type]:bg-lightBeige",
    buttonActive: "[&>span:first-of-type]:text-white",
    buttonActiveMobile: "[&>span:first-of-type]:text-white [&>span:first-of-type]:bg-primary",
    buttonPassed: "[&>span:first-of-type]:bg-primary",
    icon:
        "will-change-auto transition-all duration-300 flex justify-center items-center w-[36px] h-[36px] rounded-full " +
        "border border-primary",
    iconNotActive: "m-[0_6px]",
    iconActive: "!w-[48px] !h-[48px]",
    desktopIconSize: "w-[20px] h-[20px]",
    desktopIconSizeActive: "w-[32px] h-[32px]",
    title: "ml-[4px] flex self-center max-w-[65px] font-bite text-[12px] leading-[15px] text-left",
    mobileWrapper: "mobile-menu / fixed top-0 z-[9999] h-[58px] w-full bg-white slg:hidden",
    mobileScrolledSection:
        "mobile-scrolled-section / absolute px-[16px] py-[12px] gap-[16px] flex bg-white h-[58px] " + "md:w-full",
    mobileProgressBar: "absolute bottom-[-2px] left-0 right-0 bg-primary h-[2px]",
    mobileButton: "flex items-center whitespace-nowrap flex-1",
    mobileIcon: "min-w-[28px] flex justify-center items-center w-[28px] h-[28px] rounded-full border border-primary",
    mobileTitle: "ml-[4px] flex self-center font-bite text-[14px] leading-[16px] text-left",
    mobileIconsSize: "w-[20px] h-[20px]",
}
