export const defaultStyle = {
    wrapper: "bg-modalBg w-full h-full fixed top-0 left-0 py-[50px] z-[100] overflow-x-hidden overflow-y-auto",
}

export const NEWSLETTER_MODAL = {
    ...defaultStyle,
    gap: "flex justify-center items-center",
    container: "relative max-w-[90%] w-[1036px] max-h-[90%]",
    content:
        "pt-[68px] pb-[40px] px-[10px] md:px-[20px] lg:pt-[75px] lg:pb-[53px] lg:px-[105px] rounded-[40px] bg-warmWhite",
    closeButton: "absolute top-[30px] right-[30px] lg:top-[40px] lg:right-[40px] w-[20px] h-[20px] cursor-pointer z-2",
}

export const HOMEPAGE_VIDEO_MODAL = {
    ...defaultStyle,
    closeButton: "close-icon w-[20px] h-[20px] absolute top-[40px] right-[10px] lg:right-[40px] cursor-pointer z-2",
}
