import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import RichTextModel from "../RichTextModel/RichTextModel"
import Typography from '../UI/Typography/Typography'
import useScreenSize from "../../hooks/useScreenSize"
import { useGlobalContext } from "../../context/GlobalContext"
import * as styles from "./Accordion-tw-styles"

//Added component for the icon in case they replace the icons with custom images.
const AccordionIcon = ({ style, expanded }) => (
    <div className={expanded ? style?.accordionIconClosed : style?.accordionIconOpen} />
)

AccordionIcon.propTypes = {
    style: PropTypes.object,
    expanded: PropTypes.bool,
}

const AccordionItem = props => {
    const { name, content, contentLong, isOpen, setOpenedIndex, index, variant, desktopContent } = props
    const style = styles[variant] || styles.defaultStyle
    const [height, setHeight] = useState()
    const expandedContent = useRef()
    const windowSize = useScreenSize()
    const labels = useGlobalContext()

    useEffect(() => {
        if (isOpen) {
            setHeight(expandedContent.current?.getBoundingClientRect().height)
        } else {
            setHeight(0)
        }
    }, [isOpen, windowSize])

    const toggleExpandedState = () => {
        setOpenedIndex(!isOpen ? index : -1)
    }
    if ((!content && !contentLong) || !name) return null

    if (variant === "READ_MORE") {
        return (
            <>
                <div className={clsx(style?.accordionContainer, desktopContent ? style?.mobileContainer : "")}>
                    {name} {!isOpen && "..."}
                    {!isOpen && (
                        <button
                            onClick={toggleExpandedState}
                            className={clsx(
                                style?.button,
                                labels.getLabel("eventClasses.readMoreClick", "event_button_click")
                            )}
                            data-action-detail={labels.getLabel("buttons.readMore", "Read More")}
                        >
                            {labels.getLabel("buttons.readMore", "Read More")}
                        </button>
                    )}
                    {isOpen && (
                        <div className="inline [&>span>*]:inline [&>span>*>a]:underline">
                            {content && <p className="inline" dangerouslySetInnerHTML={{ __html: content }} />}
                            {contentLong && <Typography component="span" content={contentLong} />}
                            <button
                                onClick={toggleExpandedState}
                                className={clsx(
                                    style?.button,
                                    labels.getLabel("eventClasses.readMoreClick", "event_button_click")
                                )}
                                data-action-detail={labels.getLabel("buttons.hide", "Hide")}
                            >
                                {labels.getLabel("buttons.hide", "Hide")}
                            </button>
                        </div>
                    )}
                </div>
                {desktopContent && (
                    <div className={style?.desktopContainer}>
                        <RichTextModel style={style.richTextModel} richText={desktopContent} />
                    </div>
                )}
            </>
        )
    }

    return (
        <div
            className={style?.accordionContainer}
            itemScope
            itemProp="mainEntity"
            itemType="https://schema.org/Question"
        >
            <button
                className={clsx(
                    isOpen ? style?.accordionHeader : style?.accordionHeaderOpen,
                    labels.getLabel("eventClasses.accordionItemClick", "event_button_click")
                )}
                onClick={toggleExpandedState}
                data-action-detail={name}
            >
                <AccordionIcon style={style} expanded={isOpen} />
                <h3 className={style?.accordionQuestion} itemProp="name">
                    {name}
                </h3>
            </button>
            <div
                className={clsx(
                    style?.expandedContainer,
                    labels.getLabel("eventClasses.accordionItemClick", "event_button_click")
                )}
                onClick={toggleExpandedState}
                style={{ height }}
                data-action-detail={name}
            >
                <div
                    ref={expandedContent}
                    className={style?.expandedTextContainer}
                    itemScope
                    itemProp="acceptedAnswer"
                    itemType="https://schema.org/Answer"
                >
                    <RichTextModel itemProp="text" style={style.richTextModel} richText={content} />
                </div>
            </div>
        </div>
    )
}

AccordionItem.propTypes = {
    name: PropTypes.string,
    content: PropTypes.string,
    contentLong: PropTypes.string,
    isOpen: PropTypes.bool,
    setOpenedIndex: PropTypes.func,
    index: PropTypes.number,
    variant: PropTypes.string,
    desktopContent: PropTypes.object,
}

export default AccordionItem
