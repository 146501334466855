export const desktop = {
    imageBorder: "border-[2px] border-pea rounded-full",
    wrapper: "accent-body-xs cursor-pointer relative pointer-events-auto min-w-[122px]",
    button: "flex items-center w-full px-4 py-3 justify-between transition-all duration-300",
    buttonOpened: "bg-lightBeige rounded-t-[24px]",
    buttonTitle: "text-left",
    buttonIcon: "duration-300 transition-transform border-t-[2px] border-r-[2px] w-[8px] h-[8px]",
    expandedSection: " absolute w-full top-full text-black overflow-y-auto bg-lightBeige rounded-b-[24px] grid grid-rows-[0fr] expandable-transition",
    item: "flex w-full hover:bg-extraLightGray transition-colors relative flex items-center text-primary overflow-hidden group",
    countryName: "p-[12px_24px] w-full text-center block group-hover:bg-darkBeige group-hover:rounded-[24px]",
    linksWrapper: "overflow-hidden px-4 pb-[8px] pt-0"
}
