export const defaultStyle = {
    imageBoxWraper:
        "container overflow-x-hidden relative col-12 py-[80px] lg:pt-[100px] flex flex-col justify-center items-center " +
        "after:bg-cp-grains after:absolute after:bg-no-repeat after:bg-[length:100%_100%] after:h-[70px] after:w-[70px] lg:after:h-[100px] lg:after:w-[100px] after:bottom-[0] after:right-[30px]",
    imageBoxWrpr: "imageBoxWrpr w-full flex justify-center items-center ",
    videoWrapper: "rounded-[30px] overflow-auto relative flex justify-center items-center",
    imgWrpr: "mx-auto w-[100%] object-cover",
    imgContainer: "",
    contentWrapr: "text-center",
    contentWrapper: "xm:px-[30px] md:col-8 lg:px-[0] lg:max-w-[411px] py-[32px]",
    heading: "text-[14px] md:text-[20px] font-poppins font-[500] leading-[35px] text-center text-primary",
}
