export const defaultStyles = {
    wrapper: "relative overflow-hidden",

    placeholder: "opacity-0",
    item: "absolute left-0 top-0 transition-[transform] ease-in-out opacity-0",

    top: "translate-y-[-110%] opacity-100",
    active: "translate-y-0 opacity-100",
    bottom: "translate-y-[110%]",
}
