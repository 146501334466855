import React from "react"
import ListComponent from "../../UI/ListComponent/ListComponent"
import { convertToId, get, scrollOnTop } from "../../../helpers/utils"
import PropTypes from "prop-types"
import * as styles from "./Footer-tw-styles"
import { useGlobalContext } from "../../../context/GlobalContext"
import mondelezLogo from "../../../images/mondelezLogo.png"
import harmonyLogo from "../../../images/harmony-logo.svg"
import topButton from "../../../assets/icons/top-button.svg"
import Link from "../../UI/Link/Link"
import CountrySelector from "../CountrySelector/CountrySelector"
import useUUID from "../../../hooks/useUUID"
import clsx from "clsx"

const Footer = props => {
    const {
        countrySelectorCollection,
        languageCode,
        mainLinksCollection,
        stickyMenuItemsCollection,
        helpLinksCollection,
        stickyMenuText,
        helpText,
        isHygienePage
    } = props
    const labels = useGlobalContext().labels
    const style = styles.defaultStyle
    const { footerLinksMob, copyrightContainer } = style
    const mainLinks = mainLinksCollection && get(mainLinksCollection, "items", [])
    const stickyLinks = stickyMenuItemsCollection && get(stickyMenuItemsCollection, "items", [])
    const helpLinks = helpLinksCollection && get(helpLinksCollection, "items", [])
    const mainLinksKeys = useUUID(mainLinks?.length)
    const stickyLinksKeys = useUUID(stickyLinks?.length)
    const helpLinksKeys = useUUID(helpLinks?.length)

    return (
        <footer className={footerLinksMob}>
            {isHygienePage && <div className={style?.beforeFooter} />}
            <div className={clsx(style?.container, isHygienePage && style?.additionalContainer)}>
                <div className={style?.mobileLogoWrapper}>
                    <img src={harmonyLogo} className={style?.harmonyLogo} />
                    <Link url={labels?.footer?.mondelezLogoLink} target="_blank">
                        <img src={mondelezLogo} className={style?.mondelezLogo} alt="mondelez" />
                    </Link>
                </div>
                <div className={style?.contentWrapper}>
                    <div className={style?.languageWrapper}>
                        <div>
                            <button
                                type="button"
                                onClick={scrollOnTop}
                                className={labels?.eventClasses?.backToTop}
                                data-action-detail={labels?.eventLabels?.backToTop}
                                aria-label={labels?.arias?.backToTop}
                            >
                                <img src={topButton} alt={labels?.arias?.backToTop} />
                            </button>
                        </div>
                        <div className={style?.countrySelectorWrapper}>
                            <CountrySelector languageCode={languageCode} {...countrySelectorCollection} />
                        </div>
                    </div>
                    <div className={style?.menuWrapper}>
                        <div className="flex">
                            <div className="flex-2">
                                <div className={style?.menuText}>{stickyMenuText && stickyMenuText}</div>
                                <ul className="slg:columns-2 max-slg:flex max-slg:flex-col">
                                    {stickyLinks?.map((item, index) => (
                                        <li key={stickyLinksKeys[index]} className="mb-[24px] last-of-type:mb-0">
                                            <Link
                                                href={`/${languageCode}`}
                                                scrollToElementId={convertToId(item?.title, true)}
                                                className={style?.menuItemText}
                                                gaEventClass={labels?.eventClasses?.footerStickyLinkClick}
                                                gaEventLabel={convertToId(item?.title)}
                                            >
                                                {convertToId(item?.title)}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className={style?.logoWrapper}>
                                <img src={harmonyLogo} className={style?.harmonyLogo} />
                                <Link
                                    url={labels?.footer?.mondelezLogoLink}
                                    target="_blank"
                                    gaEventClass={labels?.eventClasses?.footerMondelezLogoClick}
                                    gaEventLabel={labels?.eventLabels?.footerMondelezLogoClick}
                                >
                                    <img src={mondelezLogo} className={style?.mondelezLogo} alt="mondelez" />
                                </Link>
                            </div>
                        </div>
                        {helpLinks?.length && (
                            <div className="max-md:mt-[48px]">
                                {helpText && <div className={clsx("md:text-right", style?.menuText)}>{helpText}</div>}

                                {helpLinks?.map((link, index) => {
                                    const linksCollection = get(link, "linksCollection.items", [])
                                    return (
                                        <ListComponent
                                            linksCollection={linksCollection}
                                            label={link.label}
                                            variant={"footerHelpLinks"}
                                            key={helpLinksKeys[index]}
                                        />
                                    )
                                })}
                            </div>
                        )}
                    </div>
                    {mainLinks?.length && (
                        <div className="w-full md:mt-[48px]">
                            {mainLinks?.map((link, index) => {
                                const linksCollection = get(link, "linksCollection.items", [])
                                return (
                                    <ListComponent
                                        linksCollection={linksCollection}
                                        label={link.label}
                                        variant={"footerMainLinks"}
                                        key={mainLinksKeys[index]}
                                    />
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
            {/* site copyRight text */}
            <div className={copyrightContainer}>
                <span className="mr-[5px]">
                    &copy; {new Date().getFullYear()} {labels?.footer?.copyRight?.text1}
                </span>
                <span className="">{labels?.footer?.copyRight?.text2}</span>
            </div>
        </footer>
    )
}

Footer.propTypes = {
    mainLinksCollection: PropTypes.object,
    countrySelectorCollection: PropTypes.object,
    languageCode: PropTypes.string,
    stickyMenuItemsCollection: PropTypes.object,
    helpLinksCollection: PropTypes.object,
    stickyMenuText: PropTypes.string,
    helpText: PropTypes.string,
    isHygienePage: PropTypes.bool
}

export default Footer
