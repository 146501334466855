export const setDataLayer = (dataLayer, seoData, pageType = "others") => {
    if (typeof window !== "undefined") {
        const dataLayerObj = {
            ...dataLayer,
            page: {
                url: window.location.href,
                title: seoData?.title || seoData?.metaTitle,
            },
            content: {
                type: pageType,
                category: "",
            },
        }
        window.gtmDataLayer = dataLayerObj
    }
}

export const trackPageView = (href) => {
    if (typeof window !== "undefined" && Array.isArray(window.dataLayer)) {
        dataLayer.push({
            event: "virtualPageView",
            page: href,
        })
    }
}
