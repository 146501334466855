const query = (id, isPreview) => `{
    videoSection(id: "${id}", preview: ${isPreview}) {
        text
        video {
            placeholderImage {
                title
                description
                url
                width
                height
            }
            video {
                title
                description
                url
            }
            variant
            elementId
            gaEventClass
            gaEventLabel
        }
        gaEventClass
        gaEventLabel
    }
}
`

module.exports = { query }
