const query = isPreview => `{
  countrySelectorCollection(preview: ${isPreview}, limit: 1) {
    items {
      internalTitle
      title
      countriesCollection {
        items {
          name
          page {
            slug
            languageCode
          }
          icon {
            url
            width
            height
          }
        }
      }
    }
  }
}

  `
module.exports = { query }
