import PropTypes from "prop-types"

export const IMAGES_PROPTYPES = {
    altText: PropTypes.string,
    imageDesktop: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    imageMobile: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
    }),
}

export const HERO_SLIDE_PROPTYPES = {
    titleTag: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.object,
    logoImage: PropTypes.shape(IMAGES_PROPTYPES),
}
