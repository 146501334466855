import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"
import { fillStringTemplate, get } from "../helpers/utils"

/**
 * @typedef GlobalContextObject
 * @type {Object}
 * @property {Object} labels
 * @property {function(label: String, defaultValue: *=, params: Object=): (String | null)} getLabel
 * @property {string} locale
 */

/** @type {React.Context<GlobalContextObject>} */
const GlobalContext = createContext()

const GlobalProvider = props => {
    const { children, labels, locale } = props

    const getLabel = (label, defaultValue, params) => {
        let value = get(labels, label, defaultValue)
        if (params && typeof params === "object") {
            value = fillStringTemplate(value, params)
        }
        return value
    }

    const providerValues = {
        labels,
        getLabel,
        locale,
    }

    return <GlobalContext.Provider value={providerValues}>{children}</GlobalContext.Provider>
}

GlobalProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    labels: PropTypes.shape(),
    locale: PropTypes.string,
}

export default GlobalProvider
export const useGlobalContext = () => useContext(GlobalContext)
