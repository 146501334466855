const query = (id, isPreview) => `
{
  stickyProgressMenu(id: "${id}", preview: ${isPreview}) {
    menuItemsCollection {
        items {
            icon {
                title
                description
                url
                width
                height
            }
            title
        }
    }
  }
}
`
module.exports = { query }
