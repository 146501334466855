import React from "react"
import clsx from "clsx"

const ArrowLeftUp = () => (
    <>
        <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.9635 4.24383C6.35066 3.63162 5.35781 3.63162 4.74466 4.24383C4.13199 4.85667 4.13199 5.84986 4.74466 6.4627L8.23039 9.94849C8.53664 10.2546 8.9383 10.408 9.3398 10.408C9.74114 10.408 10.1425 10.2546 10.4491 9.94849C11.0616 9.3358 11.0616 8.34246 10.4491 7.72977L6.9635 4.24383Z"
                fill="#0B612D" />
            <path
                d="M8.06948 14.6658C8.06948 13.8819 7.41028 13.2466 6.5974 13.2466H1.97207C1.15934 13.2466 0.5 13.8818 0.5 14.6658C0.5 15.4495 1.15919 16.0851 1.97207 16.0851H6.5974C7.41028 16.085 8.06948 15.4495 8.06948 14.6658Z"
                fill="#0B612D" />
            <path
                d="M7.28537 19.3176L3.79945 22.3056C3.18664 22.8305 3.18664 23.682 3.79945 24.2071C4.1057 24.4696 4.50687 24.6009 4.90867 24.6009C5.31 24.6009 5.71133 24.4696 6.01757 24.2071L9.50365 21.2192C10.1163 20.6942 10.1163 19.8426 9.50365 19.3176C8.89131 18.7926 7.89818 18.7926 7.28537 19.3176Z"
                fill="#0B612D" />
            <path
                d="M15.1653 7.56948C15.949 7.56948 16.5846 6.91031 16.5846 6.09746V1.47187C16.5846 0.659169 15.9491 0 15.1653 0C14.3815 0 13.7461 0.659021 13.7461 1.47187V6.09761C13.7461 6.91031 14.3813 7.56948 15.1653 7.56948Z"
                fill="#0B612D" />
            <path
                d="M20.7703 9.46216C21.1147 9.46216 21.4587 9.30926 21.7213 9.00284L24.7089 5.51699C25.2342 4.90429 25.2342 3.91093 24.7089 3.29839C24.1839 2.68569 23.3322 2.68569 22.8074 3.29839L19.8196 6.78424C19.2945 7.39678 19.2945 8.3903 19.8196 9.00284C20.0822 9.30926 20.4263 9.46216 20.7703 9.46216Z"
                fill="#0B612D" />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M24.6992 23.9466C24.6992 22.7065 25.7045 21.7012 26.9446 21.7012H26.9492C28.1918 21.7012 29.1992 22.7086 29.1992 23.9512V30.4767C29.1992 32.3603 27.6012 34.6966 24.9199 34.6966H19.1801C17.7069 34.6966 16.3312 33.5987 15.3891 32.4281C14.4347 31.2421 13.6992 29.7037 13.6992 28.4466C13.6992 26.9298 14.1882 25.6863 15.3082 24.919C15.6499 24.6849 16.1167 24.7722 16.3508 25.1139C16.5849 25.4556 16.4976 25.9224 16.1559 26.1565C15.5654 26.561 15.1992 27.2567 15.1992 28.4466C15.1992 29.2196 15.7075 30.4312 16.5577 31.4877C17.4203 32.5595 18.4099 33.1966 19.1801 33.1966H24.9199C26.6299 33.1966 27.6992 31.6823 27.6992 30.4767V23.9512C27.6992 23.537 27.3634 23.2012 26.9492 23.2012H26.9446C26.5329 23.2012 26.1992 23.5349 26.1992 23.9466C26.1992 24.3608 25.8634 24.6966 25.4492 24.6966C25.0349 24.6966 24.6992 24.3608 24.6992 23.9466Z"
                  fill="#0B612D" stroke="#0B612D" strokeWidth="0.5" strokeLinecap="round" />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M17.9382 15.7007C17.5246 15.7007 17.1894 16.0359 17.1894 16.4495V26.6691C17.1894 27.0833 16.8536 27.4191 16.4394 27.4191C16.0252 27.4191 15.6894 27.0833 15.6894 26.6691V16.4495C15.6894 15.2075 16.6962 14.2007 17.9382 14.2007L17.9444 14.2007C17.9446 14.2007 17.9448 14.2007 17.945 14.2007C19.1889 14.2045 20.1952 15.2139 20.1952 16.4578V24.2425C20.1952 24.6567 19.8595 24.9925 19.4452 24.9925C19.031 24.9925 18.6952 24.6567 18.6952 24.2425V16.4578C18.6952 16.0405 18.3576 15.7018 17.9403 15.7007L17.9397 15.7007C17.9396 15.7007 17.9392 15.7007 17.9382 15.7007Z"
                  fill="#0B612D" stroke="#0B612D" strokeWidth="0.5" strokeLinecap="round" />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M20.953 21.1963C20.5345 21.1963 20.1953 21.5356 20.1953 21.954V23.9505C20.1953 24.3647 19.8595 24.7005 19.4453 24.7005C19.031 24.7005 18.6953 24.3647 18.6953 23.9505V21.954C18.6953 20.7071 19.7061 19.6963 20.953 19.6963C22.1999 19.6963 23.2108 20.7071 23.2108 21.954V23.9505C23.2108 24.3647 22.875 24.7005 22.4608 24.7005C22.0465 24.7005 21.7108 24.3647 21.7108 23.9505V21.954C21.7108 21.5356 21.3715 21.1963 20.953 21.1963Z"
                  fill="#0B612D" stroke="#0B612D" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M23.9492 21.6982C23.5349 21.6982 23.1992 22.034 23.1992 22.4482V23.8044C23.1992 24.2186 22.8634 24.5544 22.4492 24.5544C22.0349 24.5544 21.6992 24.2186 21.6992 23.8044V22.4482C21.6992 21.2056 22.7065 20.1982 23.9492 20.1982C25.1918 20.1982 26.1992 21.2056 26.1992 22.4482V23.9482C26.1992 24.3625 25.8634 24.6982 25.4492 24.6982C25.0349 24.6982 24.6992 24.3625 24.6992 23.9482V22.4482C24.6992 22.034 24.3634 21.6982 23.9492 21.6982Z"
                  fill="#0B612D" stroke="#0B612D" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

        <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.45946 4.69183C5.84666 4.07965 4.85385 4.07965 4.24073 4.69183C3.62809 5.30465 3.62809 6.29778 4.24073 6.9106L7.72629 10.3962C8.03253 10.7023 8.43417 10.8557 8.83565 10.8557C9.23697 10.8557 9.63829 10.7023 9.94485 10.3962C10.5573 9.78356 10.5573 8.79026 9.94485 8.17761L6.45946 4.69183Z"
                fill="#0B612D" />
            <path
                d="M7.5652 15.1134C7.5652 14.3296 6.90604 13.6943 6.0932 13.6943H1.4681C0.655405 13.6943 -0.00390625 14.3295 -0.00390625 15.1134C-0.00390625 15.8971 0.655257 16.5327 1.4681 16.5327H6.0932C6.90604 16.5325 7.5652 15.8971 7.5652 15.1134Z"
                fill="#0B612D" />
            <path
                d="M6.77736 19.7649L3.29162 22.7528C2.67884 23.2776 2.67884 24.129 3.29162 24.6541C3.59785 24.9166 3.999 25.048 4.40078 25.048C4.80209 25.048 5.2034 24.9166 5.50963 24.6541L8.99553 21.6664C9.60816 21.1414 9.60816 20.2899 8.99553 19.7649C8.38323 19.2399 7.39014 19.2399 6.77736 19.7649Z"
                fill="#0B612D" />
            <path
                d="M14.6574 8.01738C15.4411 8.01738 16.0767 7.35824 16.0767 6.54544V1.92007C16.0767 1.10741 15.4412 0.448273 14.6574 0.448273C13.8736 0.448273 13.2383 1.10726 13.2383 1.92007V6.54559C13.2383 7.35824 13.8735 8.01738 14.6574 8.01738Z"
                fill="#0B612D" />
            <path
                d="M20.2624 9.90962C20.6068 9.90962 20.9508 9.75673 21.2133 9.45032L24.2009 5.96464C24.7261 5.35197 24.7261 4.35866 24.2009 3.74615C23.6759 3.13348 22.8242 3.13348 22.2995 3.74615L19.3118 7.23183C18.7867 7.84434 18.7867 8.83781 19.3118 9.45032C19.5743 9.75673 19.9184 9.90962 20.2624 9.90962Z"
                fill="#0B612D" />
            <path
                d="M16.8578 15.8135C16.4453 15.401 15.7765 15.401 15.3641 15.8135C14.9516 16.226 14.9516 16.8948 15.3641 17.3073L29.0464 30.9895C29.4589 31.402 30.1276 31.402 30.5401 30.9895C30.9526 30.577 30.9526 29.9083 30.5401 29.4958L16.8578 15.8135Z"
                fill="#0B612D" stroke="#0B612D" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M16.1109 15.5041C15.5276 15.5041 15.0547 15.977 15.0547 16.5604V26.2352C15.0547 26.8186 15.5276 27.2915 16.1109 27.2915C16.6943 27.2915 17.1672 26.8186 17.1672 26.2352V17.6166H25.7858C26.3691 17.6166 26.842 17.1437 26.842 16.5604C26.842 15.977 26.3691 15.5041 25.7858 15.5041H16.1109Z"
                fill="#0B612D" stroke="#0B612D" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
)

export default ArrowLeftUp
