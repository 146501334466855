const query = (id, isPreview) => `
{
  homepageHero(id: "${id}", preview: ${isPreview}) {
    stickyMenuItem {
        title
    }
    background {
      altText
      imageDesktop {
        title
        description
        url
        width
        height
      }
      imageTablet {
        title
        description
        url
        width
        height
      }
      imageMobile {
        title
        description
        url
        width
        height
      }
    }
    slidesCollection {
        items {
        logoImage {
          altText
          imageDesktop {
            title
            description
            url
            width
            height
          }
          imageTablet {
            title
            description
            url
            width
            height
          }
          imageMobile {
            title
            description
            url
            width
            height
          }
        }
        title
        titleTag
        subTitle {
          json
        }
        textWithBg
        cardsCollection {
            items {
                title
                text
                elementId
                variant
                gaEventClass
                gaEventLabel
                image {
                    url
                    width
                    height
                    title
                    description
                }
                images {
                    internalTitle
                    altText
                    imageDesktop {
                        title
                        description
                        url
                        width
                        height
                    }
                    imageTablet {
                        title
                        description
                        url
                        width
                        height
                    }
                    imageMobile {
                        title
                        description
                        url
                        width
                        height
                    }
                    bgDesktop {
                        title
                        description
                        url
                        width
                        height
                    }
                    bgMobile {
                        title
                        description
                        url
                        width
                        height
                    }
                }
                link {
                    label
                    title
                    url
                    target
                    scrollToElementId
                    gaEventClass
                    gaEventLabel
                    image {
                        title
                        description
                        url
                        width
                        height
                    }
                }
            }
        }
      }
    }
  }
}
`
module.exports = { query }
