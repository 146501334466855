import React, { useEffect } from "react"
import PropTypes from "prop-types"
import ContentfulComponents from "../helpers/ContentfulComponents"
import { getCookie, setCookie } from "../helpers/cookieHelper"
import Layout from "../components/Layout/Layout"
import GlobalContext from "../context/GlobalContext"
import { setDataLayer, trackPageView } from "../helpers/dataLayerSetter"
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head"
import StickyProgressMenu from "../components/StickyProgressMenu/StickyProgressMenu"

const Page = ({ path, pageContext }) => {
    const { globalComponents, componentProps, seoData, pageType, dataLayer, globalLabels, languageCode } = pageContext

    const finalDataLayer = dataLayer?.filter(
        item => item.environment === process.env.GATSBY_ACTIVE_ENV && item.languageCode === languageCode
    )[0]?.content

    setDataLayer(finalDataLayer, seoData, pageType)
    trackPageView(path)

    const stickyMenuData = componentProps.find(item => item?.StickyProgressMenu) || {}

    useEffect(() => {
        const languageCookie = getCookie("HARMONY_LANG")

        if (
            typeof window !== "undefined" &&
            !languageCookie &&
            (languageCode === "nl-be" || languageCode === "fr-be")
        ) {
            const browserLanguages = navigator.languages

            if (browserLanguages?.length > 0) {
                const browserLanguage = browserLanguages.find(
                    language => language.includes("nl") || language.includes("fr")
                )

                if (browserLanguage.includes("nl")) {
                    setCookie("HARMONY_LANG", "nl-be", 365)
                    window.location.href = "/nl-be/"
                } else if (browserLanguage.includes("fr")) {
                    setCookie("HARMONY_LANG", "fr-be", 365)
                    window.location.href = "/fr-be/"
                }
            }
        }
    }, [languageCode])

    return (
        <GlobalContext labels={globalLabels} locale={languageCode}>
            <Layout
                seoData={seoData}
                globalComponents={globalComponents}
                languageCode={languageCode}
                pageType={pageType}
            >
                {stickyMenuData && <StickyProgressMenu {...stickyMenuData.StickyProgressMenu} />}
                <div className="main-wrapper / relative">
                    <ContentfulComponents componentsProps={componentProps} pageType={pageType} />
                </div>
            </Layout>
        </GlobalContext>
    )
}
export const Head = () => {
    const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}
    return (
        <>
            <script
                id="first-unique-id"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
                }}
            />
            <script async type="text/javascript" dangerouslySetInnerHTML={{ __html: gTagScriptConsent }} />
        </>
    )
}

Page.propTypes = {
    path: PropTypes.string,
    pageContext: PropTypes.shape({
        globalComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        componentProps: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        pageType: PropTypes.string,
        dataLayer: PropTypes.array,
        seoData: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            ogTitle: PropTypes.string,
            ogDescription: PropTypes.string,
            ogType: PropTypes.string,
            twitterTitle: PropTypes.string,
            twitterDescription: PropTypes.string,
            canonicalLink: PropTypes.string,
            metaTitle: PropTypes.string,
            featuredImage: PropTypes.shape({
                url: PropTypes.string,
            }),
        }),
        globalLabels: PropTypes.object,
        languageCode: PropTypes.string,
    }),
}

export default Page
