import React, { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import RichTextModel from "../RichTextModel/RichTextModel"
import { get } from "../../helpers/utils"
import { defaultStyles as styles } from "./CircleCarousel-tw-styles"
import clsx from "clsx"
import useImage from "../../hooks/useImage"
import Image from "../UI/Image/Image"
import { useGlobalContext } from '../../context/GlobalContext'
import arrowLeft from "../../assets/icons/arrow-left.png"
import arrowRight from "../../assets/icons/arrow-right.png"
import CircleCarouselText from "./CircleCarouselText/CircleCarouselText"
import useUUID from "../../hooks/useUUID"
import { CIRCLE_SECTION_TITLE } from "../RichTextModel/RichTextModel-tw-styles"
import useScreenSize from "../../hooks/useScreenSize"
import useIsScrolledToSection from "../../hooks/useIsScrolledToSection"

const Wrapper = ({ children, isUnderDesktop }) => {
    return isUnderDesktop ? (
        <div className={styles.mobileWrapper}>{children}</div>
    ) : <>{children}</>
}

Wrapper.propTypes = {
    children: PropTypes.node,
    isUnderDesktop: PropTypes.bool,
}

const CircleCarousel = (props) => {
    const { title, itemsCollection } = props
    const circleWrapper = useRef(null)
    const circleMenuItems = get(itemsCollection, "items", [])
    const circleMenuItemsLength = circleMenuItems?.length
    const [activeItem, setActiveItem] = useState(0)
    const circleMenuKeys = useUUID(circleMenuItemsLength)
    const screen = useScreenSize()
    const isUnderDesktop = screen.width <= 991
    const circleSectionTitle = useRef(null)
    const labels = useGlobalContext()

    useEffect(() => {
        if (!circleWrapper.current || isUnderDesktop) return

        const optsCircleWrapper = {
            items: circleWrapper.current.querySelectorAll(".circle-item"),
        }

        let angle = 210
        const dAngle = 360 / optsCircleWrapper.items.length

        optsCircleWrapper.items.forEach((item) => {
            angle += dAngle
            item.style.transform = `rotate(${angle}deg) translate(${
                circleWrapper.current.clientWidth / 2
            }px) rotate(-${angle}deg)`
        })
    }, [isUnderDesktop])

    useIsScrolledToSection(circleSectionTitle, 'anim-on')

    const handleNext = () => {
        if (activeItem === circleMenuItemsLength - 1) {
            setActiveItem(0)
        } else {
            setActiveItem(prev => prev + 1)
        }
    }

    const handlePrev = () => {
        if (activeItem === 0) {
            setActiveItem(circleMenuItemsLength - 1)
        } else {
            setActiveItem(prev => prev - 1)
        }
    }

    const handleClick = (index) => {
        setActiveItem(index)
    }


    return (
        <div ref={circleSectionTitle} className={styles.wrapper}>
            <div className={styles.mainTitle}>
                <RichTextModel richText={title} style={CIRCLE_SECTION_TITLE} />
            </div>
            <div ref={circleWrapper} className={styles.circleWrapper}>
                <div className={styles.arrowWrapper}>
                    <button
                        className={clsx(
                            styles.leftBtn,
                            labels.getLabel("eventClasses.carouselArrowLeftClick", "arrow_button_click")
                        )}
                        onClick={handlePrev}
                        data-action-detail={labels.getLabel("eventLabels.carouselArrowLeftClick", "clickArrowLeft")}
                        aria-label={labels.getLabel("arias.carouselArrowLeft", "Previous slide")}
                    >
                        <img src={arrowLeft} alt="" />
                    </button>
                    <button
                        className={clsx(
                            styles.rightBtn,
                            labels.getLabel("eventClasses.carouselArrowRightClick", "arrow_button_click")
                        )}
                        onClick={handleNext}
                        data-action-detail={labels.getLabel("eventLabels.carouselArrowRightClick", "clickArrowRight")}
                        aria-label={labels.getLabel("arias.carouselArrowRight", "Next slide")}
                    >
                        <img src={arrowRight} alt="" />
                    </button>
                </div>
                <div className={styles.circle}>
                    <CircleCarouselText activeItem={circleMenuItems[activeItem]} />
                </div>
                <Wrapper isUnderDesktop={isUnderDesktop}>
                    <button className={clsx(styles.leftBtn, "slg:hidden")} onClick={handlePrev}>
                        <img src={arrowLeft} alt="" />
                    </button>
                    {Array.isArray(circleMenuItems) &&
                        circleMenuItems.map((item, index) => {
                            const image = useImage(item?.backgroundImage)
                            const hoverImage = useImage(item?.backgroundImageHover)
                            return (
                                <div key={circleMenuKeys[index]} className={styles.buttonWrapper}>
                                    <button
                                        type="button"
                                        className={styles.circleBtn}
                                        onClick={() => handleClick(index)}
                                    >
                                        <Image
                                            contentfulImage={
                                                activeItem === index ? hoverImage?.foregroundImg : image?.foregroundImg
                                            }
                                            className="will-change-auto transition-all duration-300"
                                        />
                                        {item?.title && (
                                            <div
                                                className={clsx(
                                                    styles.circleTitle,
                                                    `circle-title-${item.titleBackgroundColor}`
                                                )}
                                            >
                                                <RichTextModel richText={item.title} />
                                            </div>
                                        )}
                                    </button>
                                </div>
                            )
                        })}
                    <button className={clsx(styles.rightBtn, "slg:hidden")} onClick={handleNext}>
                        <img src={arrowRight} alt="" />
                    </button>
                </Wrapper>
            </div>
        </div>
    )
}

export default CircleCarousel

CircleCarousel.propTypes = {
    title: PropTypes.object,
    itemsCollection: PropTypes.object,
}
