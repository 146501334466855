export const defaultStyle = {
    videoframe: "videoframe px-10 lg:px-0",
    videoItem: "md:min-h-[398px] lg:min-h-[640px] min-h-[200px] lg:rounded-[30px] rounded-[20px]",
}

export const FULL_WIDTH_VIDEO_WRPR = {
    videoframe: " px-10 lg:px-0",
    videoItem: "md:min-h-[398px] lg:min-h-[640px] min-h-[200px] lg:rounded-[30px] rounded-[20px] max-w-full",
}

export const RECIPE_VIDEO = {
    videoframe: "px-10 lg:px-0 lg:w-[615px] lg:h-[346px] mx-auto my-[72px]",
    videoItem: "md:min-h-full lg:min-h-full min-h-full lg:rounded-[30px] rounded-[20px]",
}
export const videoSlider = {
    playButton: "absolute bg-arrowBg top-[40%] left-[45%] rounded-full md:mt-[15%] lg:mt-auto",
    showImg: "w-full h-full ",
    hideImg: "hidden",
    videoFrame:
        "lg:h-[403px] videoCrsl w-[720px] child:rounded-20 lg:h-full child:w-full child:mx-auto child:my-0 mb-10",
    videoFrameHide:
        "lg:px-10 lg:px-0 lg:h-[405px] w-[720px] child:rounded-20 child:h-full w-full child:mx-auto child:my-0 pb-10 max-w-full flex",
}
