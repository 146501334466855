import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useGlobalContext } from '../../../context/GlobalContext'
import useImage from "../../../hooks/useImage"
import RichTextModel from "../../RichTextModel/RichTextModel"
import NextBtn from "../../../assets/icons/NextBtn"
import { HOMEPAGE_HERO } from "../../RichTextModel/RichTextModel-tw-styles"
import { HERO_SLIDE_PROPTYPES } from "../../../constants/shapes.constants"
import ImageCard from "../../UI/ImageCard/ImageCard"
import { get } from "../../../helpers/utils"
import useUUID from "../../../hooks/useUUID"
import { anim } from "../../../helpers/anim.helpers"
import { defaultStyles as styles } from "./HomepageHeroItem-tw-styles"
import useIsScrolledToSection from "../../../hooks/useIsScrolledToSection"

const HomepageHeroItem = ({ handleClick, slidesCount, index, slide }) => {
    const Title = slide?.titleTag ? slide.titleTag : "h1"
    const { foregroundImg } = useImage(slide?.logoImage)
    const cards = get(slide?.cardsCollection, "items", [])
    const cardsCount = cards?.length
    const cardsKey = useUUID(cardsCount)
    const isLastItem = index === slidesCount - 1
    const imageCardsWrapperRef = useRef(null)
    const labels = useGlobalContext()
    const heroWrapper = useRef(null)

    useIsScrolledToSection(heroWrapper, 'anim-on')

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)

        if (!imageCardsWrapperRef.current) return

        ScrollTrigger.create({
            trigger: imageCardsWrapperRef.current,
            animation: anim(imageCardsWrapperRef.current, ".hero-card"),
        })
    },[])

    return (
        <div
            ref={heroWrapper}
            id={`hero-section-${index}`}
            className={clsx(
                styles.wrapper,
                index === 0 && styles.firstWrapper,
                cardsCount > 0 ? "mlg:h-[calc(100vh_+_250px)] max-mlg:min-h-[1140px]" : "h-screen"
            )}
            style={{ clipPath: "inset(0)", top: `${100 * index}vh` }}
        >
            <div
                className={clsx(
                    "w-full",
                    cardsCount > 0 ? styles.cardWrapperWithCards : styles.cardWrapperNoCards,
                    isLastItem ? "h-full" : "h-[calc(100%_-_72px)]"
                )}
            >
                {foregroundImg && <img src={foregroundImg?.url} alt={foregroundImg?.alt} className={styles.image} />}
                {slide?.title && <Title className="anim-title / mb-[16px]">{slide?.title}</Title>}
                {slide?.subTitle && <RichTextModel richText={slide?.subTitle} style={HOMEPAGE_HERO} />}
                {slide?.textWithBg && (
                    <div className={styles.textWithBgWrapper}>
                        <div className={styles.textWithBg}>{slide.textWithBg}</div>
                    </div>
                )}
                {Array.isArray(cards) && (
                    <div ref={imageCardsWrapperRef} className={styles.imageCardWrapper}>
                        {cards.map((card, index) => (
                            <ImageCard key={cardsKey[index]} {...card} />
                        ))}
                    </div>
                )}
            </div>
            {!isLastItem && (
                <div className={styles.buttonWrapper}>
                    <button
                        onClick={() => handleClick(index)}
                        className={clsx(
                            "animation-bounce / mlg:max-[1280px]:hidden",
                            labels.getLabel("eventClasses.heroArrowClick", "event_button_click")
                        )}
                        data-action-detail={labels.getLabel("eventLabels.heroArrowClick", "event_informational_action")}
                        aria-label={labels.getLabel("arias.heroArrow", "Scroll down")}
                    >
                        <NextBtn />
                    </button>
                </div>
            )}
        </div>
    )
}

HomepageHeroItem.propTypes = {
    index: PropTypes.number,
    handleClick: PropTypes.func,
    slidesCount: PropTypes.number,
    slide: PropTypes.shape(HERO_SLIDE_PROPTYPES)
}

export default HomepageHeroItem
