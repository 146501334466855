import React from "react"

const QuoteIcon = () => (
    <svg width="56" height="48" viewBox="0 0 56 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.2676 43.9246L22.4652 44.9311C22.4652 45.535 23.2676 45.535 22.6658 46.1388C19.4565 46.944 15.846 45.9375 13.439 46.7427C12.8373 46.944 13.439 46.3401 12.8373 46.5414C8.62505 46.5414 5.81689 46.1388 1.80524 46.1388C1.20349 45.535 1.40408 45.1324 0.80233 44.9311L0.200583 32.8534C0.200583 30.6391 0.601748 28.4249 0.601748 26.2107C0.601748 25.6068 0 26.0094 0 25.4055C0.200583 22.1848 1.2035 20.3731 2.00583 17.3537L6.8198 1.65269L7.62214 0.64622C10.8315 0.0423318 14.8431 0.243636 18.6542 0.243636C19.2559 0.847512 19.6571 1.0488 19.6571 2.05529C19.6571 9.1006 17.2501 16.9511 17.0495 23.1912H22.8664C23.4682 23.7951 23.8693 24.6003 23.8693 25.4055C23.8693 31.243 22.6658 38.4896 23.2676 43.9246ZM52.1514 1.65269C51.9509 5.07471 50.7474 8.69802 50.5468 11.7174C49.945 15.7433 50.3462 18.7628 49.7444 22.5874C51.5497 22.5874 53.3549 22.5874 55.5613 22.7886C56.5643 26.6132 55.5613 32.2495 55.3608 36.4767C55.1602 39.0935 55.5613 41.9116 54.9596 44.5285H54.5584C54.3578 44.5285 54.3578 44.5285 54.3578 44.7298C54.3578 44.9311 55.1602 45.1324 54.5584 45.7363C48.3404 47.1453 40.1165 47.3466 33.0961 47.1453C33.2967 44.1259 32.6949 41.7104 32.4944 38.6909C32.2938 33.8599 32.6949 29.2301 32.4944 24.399C32.4944 23.7951 33.0961 24.1977 33.2967 23.5938C35.5031 16.5485 37.5089 9.90578 39.3142 2.65917C39.7153 2.25658 40.1165 1.85399 40.3171 1.25011C43.727 0.64622 48.541 0.847512 50.7474 0.847512C50.9479 1.4514 51.3491 1.65269 52.1514 1.65269Z"
            fill="white"
        />
    </svg>
)

export default QuoteIcon
