module.exports = `
    metaTitle
    metaDescription
    metaKeywords
    ogType
    ogTitle
    ogUrl
    title
    twitterDescription
    twitterTitle
    canonicalLink
    hreflang {
        internal {
        content
      }
    }
`
