import React from "react"
import PropTypes from "prop-types"
import Typography from "../UI/Typography/Typography"
import Link from "../UI/Link/Link"
import Image from "../UI/Image/Image"
import useImage from "../../hooks/useImage"
import clsx from "clsx"
import VideoIcon from "../../assets/icons/VideoIcon"
import ExternalPageIcon from "../../assets/icons/ExternalPageIcon"

const ImageWithText = props => {
    const {
        heading,
        description,
        alignmentDescription,
        images,
        imageDescription,
        link,
        style,
        isReversed,
        headingIsLink,
        hasContent,
        elementId = "",
        showBackground,
        backgroundImage,
        variant
    } = { ...props }
    const { foregroundImg } = useImage(images)

    const desc = typeof description === "object" ? description?.description : description
    const noBr = variant === "FARMERS" || "TEXT_HERO_404"

    const bgStyle = {}
    if (showBackground && backgroundImage) {
        bgStyle.backgroundImage = `url(${backgroundImage?.url})`
    }
    return (
        <div className={style?.wrapper} style={bgStyle}>
            {backgroundImage && !showBackground && (
                <div className={style?.bgImg}>
                    <Image contentfulImage={backgroundImage} />
                </div>
            )}
            <div className={clsx(style?.imgTxtContainer, isReversed ? style?.reverse : "")}>
                {foregroundImg && (
                    <div className={style?.imgWrapper}>
                        <span className={style?.gradBg}></span>
                        <div className={style?.subimgWrpr}>
                            <Image contentfulImage={foregroundImg} className={style?.textImg} />
                            {imageDescription && <Typography className={style?.imageDesc} content={imageDescription} />}
                        </div>
                    </div>
                )}
                <div className={clsx(hasContent ? style?.textContent : style?.textWrpr)} id={elementId}>
                    {heading ? (
                        headingIsLink ? (
                            <Link {...link} className={style?.textLink}>
                                {heading}
                            </Link>
                        ) : (
                            <Typography className={style?.textHead} content={heading} />
                        )
                    ) : null}
                    {desc && !noBr &&
                        <Typography
                            content={desc}
                            className={clsx(style?.textDesc, style[alignmentDescription])}
                            allow_br_tag
                        />
                    }
                    {desc && noBr &&
                        <Typography
                            content={desc}
                            className={clsx(style?.textDesc, style[alignmentDescription])}
                            br_allowed={false}
                        />
                    }
                    {link && !link?.variant && <Link {...link} className={style?.textLink} />}
                    {link && link?.variant &&
                        <Link {...link} className={style?.textLink}>
                            <span className="flex items-center">
                                {link?.variant === "VIDEO" && <VideoIcon />}
                                {link?.variant === "EXTERNAL_PAGE" && <ExternalPageIcon />}
                                <span className="max-md:max-w-[250px]">{link?.title || link?.label}</span>
                            </span>
                        </Link>
                    }
                </div>
            </div>
        </div>
    )
}

export default ImageWithText

ImageWithText.propTypes = {
    heading: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    headingIsLink: PropTypes.bool,
    link: PropTypes.object,
    foregroundImg: PropTypes.object,
    style: PropTypes.object,
    isReversed: PropTypes.bool,
    hasContent: PropTypes.bool,
    backgroundImage: PropTypes.object,
    elementId: PropTypes.string,
    showBackground: PropTypes.bool
}
