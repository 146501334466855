import React, { useEffect, useState, useRef } from "react"
import gsap from "gsap"
import PropTypes from "prop-types"
import Image from "../../Image/Image"
import Typography from "../../Typography/Typography"
import useImage from "../../../../hooks/useImage"
import ArrowLeftUp from "../../../../assets/icons/ArrowLeftUp"
import QuoteIcon from "../../../../assets/icons/QuoteIcon"
import useScreenSize from "../../../../hooks/useScreenSize"
import * as styles from "./FlipCard-tw-styles"

const FlipCard = props => {
    const { variant, text, title, images, image, gaEventClass, gaEventLabel } = props
    const { foregroundImg } = useImage(images)
    const style = styles[variant] || styles.defaultStyle
    const [isActive, setIsActive] = useState(false)
    const windowSize = useScreenSize()
    const cardWrapper = useRef(null)
    const cardContainer = useRef(null)
    const cardFront = useRef(null)
    const cardBack = useRef(null)

    const tl = useRef(new gsap.timeline({ paused: true }))

    useEffect(() => {
        gsap.set(cardWrapper.current, { perspective: 800 })
        gsap.set(cardContainer.current, { transformStyle: "preserve-3d" })
        gsap.set(cardBack.current, { rotationY: -180 })
        gsap.set([cardBack.current, cardFront.current], { backfaceVisibility: "hidden" })

        tl.current.to(cardContainer.current, 1, { ease: "power1.out", rotationY: -180 })
    }, [])

    useEffect(() => {
        if (isActive) {
            tl.current.play()
        } else {
            tl.current.reverse()
        }
    }, [isActive, windowSize])

    const activateCard = () => {
        setIsActive(true)
    }

    const deactivateCard = () => {
        setIsActive(false)
    }

    if (variant === 'FLIP_TEXT_CARD') {
        return (
            <div ref={cardWrapper} className={style?.wrapper} onMouseOut={deactivateCard} onMouseOver={activateCard}>
                <div ref={cardContainer}>
                    <div ref={cardFront} className={style?.front}>
                        <div className={style?.frontContainer}>
                            <Typography className={style?.name} content={title} />
                        </div>
                        <div className={style?.arrowIcon}>
                            <ArrowLeftUp />
                        </div>
                    </div>

                    <div ref={cardBack} className={style?.back}>
                        <Typography className={style?.description} content={text} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div ref={cardWrapper} className={style?.wrapper} onMouseOut={deactivateCard} onMouseOver={activateCard}>
            <div ref={cardContainer}>
                <div ref={cardFront} className={style?.front}>
                    <div className={style?.frontContainer}>
                        <div className={style?.quoteIcon}>
                            <QuoteIcon />
                        </div>

                        <Image
                            contentfulImage={image || foregroundImg}
                            className={style?.frontImage}
                            eventClass={gaEventClass}
                            eventLabel={gaEventLabel}
                        />

                        <div className={style?.arrowIcon}>
                            <ArrowLeftUp />
                        </div>
                    </div>
                </div>

                <div ref={cardBack} className={style?.back}>
                    <Typography className={style?.description} content={text} />
                    <Typography className={style?.name} content={title} />
                </div>
            </div>
        </div>
    )
}

FlipCard.propTypes = {
    variant: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.object,
    images: PropTypes.object,
    gaEventClass: PropTypes.string,
    gaEventLabel: PropTypes.string,
}

export default FlipCard
