const query = (id, isPreview) => {
    return `
    {
      accordion(id: "${id}", preview: ${isPreview}) {
        title
        elementId
        accordionItemsCollection(limit: 10) {
          items {
            name
            sys {
              id
            }
            content {
              json
              links {
                entries {
                  inline {
                    sys {
                      id
                    }
                    ... on Link {
                      label
                      url
                      target
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    
    `
}
module.exports = { query }
