import * as React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"
import useSiteMetadata from "../../hooks/useSiteMetadata"
import {useGlobalContext} from "../../context/GlobalContext"
import {deslashify, deslashifyStart, get} from "../../helpers/utils"
const SeoHead = ({
                     title,
                     metaDescription,
                     ogTitle,
                     ogDescription,
                     ogType,
                     ogUrl,
                     featuredImage,
                     metaKeywords,
                     canonicalLink,
                     hreflang,
                 }) => {
    const siteMetadata = useSiteMetadata()
    const labels = useGlobalContext().labels
    const seo = {
        title: title || siteMetadata?.title,
        description: metaDescription || siteMetadata?.description,
        ogType: ogType || "",
        ogUrl: `${siteMetadata?.siteUrl}${ogUrl || ""}`,
        ogTitle: ogTitle || title || siteMetadata.title,
        ogDescription: ogDescription || metaDescription || siteMetadata.description,
    }

    const hreflangRaw = get(hreflang, "internal.content")
    const parsedHreflang = hreflangRaw ? JSON.parse(hreflangRaw) : {}

    const preparedTitle = seo?.title?.includes(labels?.pageTitle) ? seo.title : `${labels.pageTitle} | ${seo.title}`

    const getCanonicalHref = () => {
        if (!canonicalLink) return ''

        else if (canonicalLink.includes("http") || canonicalLink.includes("www"))
            return canonicalLink

        else return `${deslashify(siteMetadata?.siteUrl)}/${deslashifyStart(canonicalLink)}`
    }

    const robotsContent = process.env.GATSBY_ACTIVE_ENV === "production" ? "index, follow" : "noindex, nofollow"

    return (
        <Helmet>
            <title>{preparedTitle}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <meta property="http-equiv" content="text/html; charset=utf-8"/>
            <meta name="description" content={seo.description}/>
            {metaKeywords && <meta name="keywords" content={metaKeywords}/>}
            <meta property="og:title" content={seo.ogTitle}/>
            <meta property="og:description" content={seo.ogDescription}/>
            <meta property="og:type" content="website"/>
            <meta name="og:image" content={featuredImage?.url}/>
            <meta name="og:url" content={seo.ogUrl}/>
            <meta name="og:site_name" content={labels?.pageTitle}/>
            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:creator" content={siteMetadata?.author || ``}/>
            <meta name="twitter:title" content={ogTitle}/>
            <meta name="twitter:description" content={ogDescription}/>
            <meta name="twitter:image" content={featuredImage?.url || ""}/>
            <meta name="robots" content={robotsContent}/>
            <link rel="canonical" href={getCanonicalHref()}/>
            {parsedHreflang && Object.keys(parsedHreflang).length > 0 &&
                Object.keys(parsedHreflang).map((key) => <link rel='alternate' key={key} hrefLang={key}
                                                               href={parsedHreflang[key]}/>)
            }
        </Helmet>
    )
}

SeoHead.propTypes = {
    title: PropTypes.string,
    metaDescription: PropTypes.string,
    ogTitle: PropTypes.string,
    ogDescription: PropTypes.string,
    ogType: PropTypes.string,
    ogUrl: PropTypes.string,
    featuredImage: PropTypes.object,
    url: PropTypes.string,
    metaKeywords: PropTypes.string,
    canonicalLink: PropTypes.string,
    hreflang: PropTypes.object,
}

export default SeoHead
