import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import useScreenSize from "../../../hooks/useScreenSize"
import useImage from '../../../hooks/useImage'
import Image from "../../UI/Image/Image"
import ArrowLeftUp from "../../../assets/icons/ArrowLeftUp"
import Typography from "../../UI/Typography/Typography"
import RichTextModel from "../../RichTextModel/RichTextModel"
import { PILLAR_CARD_DESCRIPTION, PILLAR_CARD_DESCRIPTION_EXPANDED } from "../../RichTextModel/RichTextModel-tw-styles"
import { defaultStyles as styles } from "./PillarCard-tw-styles"

const PillarCard = props => {
    const { titleImg, description, iconImg, iconLabel } = props
    const [originalHeight, setOriginalHeight] = useState(null)
    const [contentHeight, setContentHeight] = useState(null)
    const [textStyles, setTextStyles] = useState(PILLAR_CARD_DESCRIPTION)
    const [isActive, setIsActive] = useState(false)
    const [shouldCountNewOrigHeight, setSholdCountNewOrigHeight] = useState(false)
    const contentRef = useRef()
    const textRef = useRef()
    const { foregroundImg: titleImage } = useImage(titleImg)
    const { foregroundImg: icon } = useImage(iconImg)
    const windowSize = useScreenSize()

    useEffect(() => {
        setOriginalHeight(textRef.current?.getBoundingClientRect().height)
    }, [])

    useEffect(() => {
        if (isActive) {
            setTextStyles(PILLAR_CARD_DESCRIPTION_EXPANDED)
        } else {
            setContentHeight(originalHeight)

            setTimeout(() => {
                setTextStyles(PILLAR_CARD_DESCRIPTION)
            }, 300)
        }
    }, [isActive])

    useEffect(() => {
        if (isActive) {
            setContentHeight(contentRef.current?.getBoundingClientRect().height)
        } else {
            if (shouldCountNewOrigHeight) {
                setOriginalHeight(textRef.current?.getBoundingClientRect().height)
                setSholdCountNewOrigHeight(false)
            }
        }
    }, [textStyles])

    useEffect(() => {
        if (isActive) {
            setContentHeight(contentRef.current?.getBoundingClientRect().height)
            setSholdCountNewOrigHeight(true)
        } else {
            setOriginalHeight(textRef.current?.getBoundingClientRect().height)
            setContentHeight(textRef.current?.getBoundingClientRect().height)
        }
    }, [windowSize])

    return (
        <div className={styles?.wrapper} onClick={() => setIsActive(!isActive)}>
            <div className={styles?.imageWrapper}>
                <Image contentfulImage={titleImage} className={styles?.image} />
            </div>

            <div className={styles?.contentWrapper} style={{ height: contentHeight || originalHeight }}>
                <div ref={contentRef}>
                    <div ref={textRef}>
                        <RichTextModel richText={description} style={textStyles} />
                    </div>

                    {icon && iconLabel && (
                        <div className={styles?.label}>
                            <Image contentfulImage={icon} className={styles?.labelIcon} />
                            <Typography className={styles?.labelText} content={iconLabel} />
                        </div>
                    )}
                </div>
            </div>

            <div className={styles?.actionIcon}>
                <ArrowLeftUp />
            </div>
        </div>
    )
}

PillarCard.propTypes = {
    titleImg: PropTypes.object,
    description: PropTypes.object,
    iconImg: PropTypes.object,
    iconLabel: PropTypes.string
}

export default PillarCard
