const query = (id, isPreview) => `
{
    sectionWrapper(id: "${id}", preview: ${isPreview}) {
        stickyMenuItem {
            title
        }
        variant
        backgroundImage {
            imageDesktop {
                url
                width
                height
            }
            imageMobile {
                url
                width
                height
            }
        }
        decorImage {
            url
            width
            height
        }
        content: contentCollection {
            items {
                ... on Entry {
                    sys { id }
                    __typename
                }
            }
        }
    }
}
`
module.exports = { query }
