const query = (id, isPreview) => `
{
    pillars(id: "${id}", preview: ${isPreview}) {
        title {
            richText {
                json
            }
        }
        logoImg {
            altText
            imageDesktop {
                description
                url
                width
                height
            }
        }
        textWithBg {
            json
        }
        cardsCollection {
            items {
                titleImg {
                    altText
                    imageDesktop {
                        description
                        url
                        width
                        height
                    }
                }
                description {
                    json
                }
                iconImg {
                    altText
                    imageDesktop {
                        description
                        url
                        width
                        height
                    }
                }
                iconLabel
            }
        }
    }
}
`
module.exports = { query }
