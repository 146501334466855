/**
 * returns value from object by keys path as an array
 * e.g.
 * obj = { a: { b: 'b value', c: 'c value' }}; keysArray = ['a', 'c'];
 * getValueByKeysArray(obj, keysArray); // will returns 'c value'
 *
 * @export
 * @param {Object} obj Object to be passed for a value
 * @param {Array} keysArray Array of keys
 * @returns
 */
export function getValueByKeysArray(obj, keysArray) {
    let val = obj
    if (Array.isArray(keysArray)) {
        keysArray.forEach(key => {
            if (key > "") {
                val = val && val[key]
            }
        })
    }

    return val
}

/**
 * get value from object by dot notation string
 * e.g.
 * from object
 * {
 *   addresses: [
 *     street: 'Street Name',
 *   ]
 * }
 *
 * return for dot notation string 'addresses[0].street' value 'Street Name'
 *
 * @export
 * @param {Object} obj Object to be passed for a value
 * @param {String} path Dot notation string to be parsed and used to get value from object
 * @param {*} defaultValue Default value if value can't be found - optional
 * @returns
 */
export const get = (obj, path, defaultValue) => {
    if (typeof path != "string") {
        throw new Error("path must be string value")
    }

    const keysArr = path
        .replace(/(\[)|(\].?)/g, ".")
        .replace(/\.$/, "")
        .split(".")
    const value = getValueByKeysArray(obj, keysArr)

    // undefined can't be serialized, so null value has to returns
    return value || (defaultValue != null || defaultValue !== undefined ? defaultValue : null)
}

export const fillStringTemplate = (template, vars) =>
    Object.entries(vars).reduce((acc, [key, value]) => acc.replace(new RegExp(`{${key}}`, "g"), value), template)

export const deslashify = origUrl => {
    const url = origUrl?.trim().endsWith("/") ? origUrl.slice(0, -1) : origUrl
    return url
}

export const deslashifyStart = origUrl => {
    const url = origUrl?.trim().startsWith("/") ? origUrl.slice(1) : origUrl
    return url
}

export const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate()
}

export const getArrayWithRangeOfNumbers = (start, end) => {
    const arr = []

    for (let i = start; i <= end; i++) {
        arr.push(i)
    }

    return arr
}

export const getDateForNewsletter = (day, month, year) => {
    if (!day || !month || !year) return ""

    const dayString = day.toString()
    const finalDay = dayString.length === 1 ? `0${dayString}` : dayString
    const monthString = month.toString()
    const finalMonth = monthString.length === 1 ? `0${monthString}` : monthString

    return `${year}-${finalMonth}-${finalDay}`
}

export const isDate18orMoreYearsOld = (day, month, year) => {
    return new Date(parseInt(year) + 18, parseInt(month) - 1, parseInt(day)) <= new Date()
}

export const convertToSlug = (name) => {
    return name.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, "") //remove diacritics
        .toLowerCase()
        .replace(/\s+/g, '-') //spaces to dashes
        .replace(/&/g, '-and-') //ampersand to and
        .replace(/[^\w\-]+/g, '') //remove non-words
        .replace(/\-\-+/g, '-') //collapse multiple dashes
        .replace(/^-+/, '') //trim starting dash
        .replace(/-+$/, ''); //trim ending dash
}

export const scrollOnTop = (event) => {
    event.preventDefault()

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
}

export const convertToId = (title, link = false) => {
    const string = title.replace(/<[^>]*>/g, ' ')

    if (!link) {
        return string
    }

    return convertToSlug(string)
}
