const fragment = `
    title
    text
    subtext
    variant
    isSlider
    link {
        label
        title
        url
        target
        scrollToElementId
        gaEventClass
        gaEventLabel
        image {
            title
            description
            url
            width
            height
        }
    }
    decorImage {
        url
        width
        height
    }
    cardsCollection {
        items {
            __typename
            ... on ImageCard {
                title
                text
                elementId
                variant
                gaEventClass
                gaEventLabel
                image {
                    url
                    width
                    height
                    title
                    description
                }
                images {
                    internalTitle
                    altText
                    imageDesktop {
                        title
                        description
                        url
                        width
                        height
                    }
                    imageTablet {
                        title
                        description
                        url
                        width
                        height
                    }
                    imageMobile {
                        title
                        description
                        url
                        width
                        height
                    }
                    bgDesktop {
                        title
                        description
                        url
                        width
                        height
                    }
                    bgMobile {
                        title
                        description
                        url
                        width
                        height
                    }
                }
                link {
                    label
                    title
                    url
                    target
                    scrollToElementId
                    gaEventClass
                    gaEventLabel
                    image {
                        title
                        description
                        url
                        width
                        height
                    }
                    variant
                }
            }
        }
    }
`

const query = (id, isPreview) => `
{
    imageCardsLayout(id: "${id}", preview: ${isPreview}) {
        ${fragment}
    }
}
`
module.exports = { query, fragment }
