const query = (id, isPreview) => `
{
  circleCarousel(id: "${id}", preview: ${isPreview}) {
    title {
        json
    }
    itemsCollection {
        items {
            title {
                json
            }
            titleBackgroundColor
            description {
                json
            }
            descriptionImagesCollection {
                items {
                    internalTitle
                    altText
                    imageDesktop {
                        title
                        description
                        url
                        width
                        height
                    }
                    imageTablet {
                        title
                        description
                        url
                        width
                        height
                    }
                    imageMobile {
                        title
                        description
                        url
                        width
                        height
                    }
                }
            }
            backgroundImage {
                internalTitle
                    altText
                    imageDesktop {
                        title
                        description
                        url
                        width
                        height
                    }
                    imageTablet {
                        title
                        description
                        url
                        width
                        height
                    }
                    imageMobile {
                        title
                        description
                        url
                        width
                        height
                    }

            }
             backgroundImageHover {
                internalTitle
                    altText
                    imageDesktop {
                        title
                        description
                        url
                        width
                        height
                    }
                    imageTablet {
                        title
                        description
                        url
                        width
                        height
                    }
                    imageMobile {
                        title
                        description
                        url
                        width
                        height
                    }

            }
        }
    }
  }
}
`
module.exports = { query }
