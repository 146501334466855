import { useEffect } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"

const useIsScrolledToSection = (wrapperRef, classname, start = "top 50%") => {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)

        const ctx = gsap.context(() => {
            ScrollTrigger.create({
                trigger: wrapperRef.current,
                start: start,
                end: `+=${wrapperRef.current.offsetHeight}px`,
                scrub: 0.3,
                onEnter: () => {
                    if (!wrapperRef.current) return
                    classname && wrapperRef.current.classList.add(classname)
                }
            })
        }, wrapperRef.current)

        return () => ctx.revert()
    }, [])
}

export default useIsScrolledToSection
