const subscribeEvent = (eventName, listener) => {
    document.addEventListener(eventName, listener)
}

const unsubscribeEvent = (eventName, listener) => {
    document.removeEventListener(eventName, listener)
}

const fireEvent = (eventName) => {
    const event = new Event(eventName)
    document.dispatchEvent(event)
}

export { fireEvent, subscribeEvent, unsubscribeEvent }
