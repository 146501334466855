export const setCookie = (name, value, hours = 8760) => {
    if (hours === null) {
        document.cookie = `${name}=${value}; path=/`
    } else {
        const expirationDate = new Date()

        expirationDate.setTime(expirationDate.getTime() + hours * 60 * 60 * 1000)

        document.cookie = `${name}=${value}; expires=" + ${expirationDate.toUTCString()} + "; path=/`
    }
}

export const getCookie = item => {
    if (typeof document !== "undefined" && document.cookie) {
        const cookiearray = document.cookie.split(";")

        for (let i = 0; i < cookiearray.length; i += 1) {
            const name = cookiearray[i].split("=")[0]
            const value = cookiearray[i].split("=")[1]

            if (name.trim() === `${item}`.trim()) {
                return value
            }
        }
    }

    return undefined
}
