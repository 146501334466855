import React, { useState } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import useImage from "../../../hooks/useImage"
import Image from "../../UI/Image/Image"
import { defaultStyles as styles } from "./StatisticsTabs-tw-styles"
import StatisticsTab from "./StatisticsTab"

const StatisticsTabs = ({ tabs, tabsImage }) => {
    const [current, setCurrent] = useState(0)
    const { foregroundImg } = useImage(tabsImage)

    if (!Array.isArray(tabs) || tabs.length <= 0) return null

    return (
        <div className={styles?.wrapper}>
            <ul className={styles?.list}>
                {tabs.map((tab, index) => (
                    <li
                        key={tab.sys.id}
                        className={clsx(styles?.listItem, current === index ? styles?.listItemActive : "")}
                    >
                        <button type="button" className={styles?.listItemButton} onClick={() => setCurrent(index)}>
                            {tab.label}
                        </button>
                    </li>
                ))}
            </ul>

            <StatisticsTab
                key={tabs[current].iconImg?.altText}
                icon={tabs[current].iconImg}
                iconLabel={tabs[current].iconLabel}
                columns={tabs[current]?.columnsCollection?.items}
            />

            <Image contentfulImage={foregroundImg} className={styles?.bottomImage} />
        </div>
    )
}

StatisticsTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            iconLabel: PropTypes.string,
            iconImg: PropTypes.object,
            columnsCollection: PropTypes.shape({
                items: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string,
                        content: PropTypes.object,
                    })
                ),
            }),
        })
    ),
    tabsImage: PropTypes.object,
}

export default StatisticsTabs
