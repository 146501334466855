export const defaultStyles = {
    background: "relative bg-cover xlg:overflow-x-hidden",
}

export const PARTNERSHIP = {
    background:
        "relative bg-cover before:bg-section-divider before:bg-no-repeat before:block before:absolute before:bg-cover " +
        "before:-top-[40px] before:left-0 before:right-0 before:w-full before:h-[80px] md:max-slg:before:bg-section-divider-tablet " +
        "max-md:before:bg-section-divider-mobile max-sm:overflow-x-hidden",
}

export const AMBITION = {
    background:
        "bg-darkBeige bg-ambition-texture-desktop max-slg:bg-ambition-texture-mobile max-slg:bg-bottom " +
        "bg-no-repeat bg-left-bottom overflow-x-hidden relative z-[0] ",
    decorImage: "decor / absolute top-0 left-0 max-slg:-top-[10px] max-slg:max-w-[200px]",
}

export const FARMERS = {
    background:
        "bg-darkBeige relative bg-cover before:bg-section-divider-pillars before:bg-no-repeat before:block before:bg-cover before:absolute " +
        "before:-top-[40px] before:left-0 before:right-0 before:w-full before:h-[80px] md:max-slg:before:bg-section-divider-pillars-tablet " +
        "max-md:before:bg-section-divider-pillars-mobile before:z-[2] before:bg-center max-slg:before:top-0 max-slg:before:h-[50px] max-slg:before:bg-[length:100%]",
}
