export const defaultStyles = {
    wrapper:
        "relative pt-[32px] md:pt-[72px] lg:pt-[96px] xlg:pt-[120px] pb-[130px] md:pb-[210px] mlg:pb-[280px] overflow-hidden",
    container: "w-full md:max-w-[704px] mlg:max-w-[900px] lg:max-w-[1050px] mx-auto max-lg:px-[16px]",

    title: "anim-title / text-center style-h2",

    descRow: "flex items-center justify-between mlg:px-[48px] mt-[32px] mlg:mt-[48px]",
    descLeftSide: "flex items-center gap-[24px]",
    descTextBlock: "mlg:max-w-[420px]",
    descPercentage: "flex text-[32px] leading-[100%] mlg:text-[80px] font-bite",
    descTitle: "flex items-center gap-[4px] text-[20px] leading-[100%] md:text-[18px] mlg:text-[24px] font-bite",
    descText: "max-mlg:mt-[4px] text-[16px] leading-[150%] mlg:text-[20px] font-medium font-poppins",
    descImage: "hidden mlg:block mlg:relative mlg:mr-[108px]",

    greenWrapper:
        "statistics-green relative px-[16px] py-[32px] md:px-[24px] md:py-[20px] mlg:px-[48px] mlg:py-[36px] mt-[32px] mlg:mt-[48px]",
    greenImage: "absolute top-[-41px] right-[-16px] mlg:hidden w-[76px]",
    greenList: "flex flex-col md:flex-row gap-[24px] [&>li]:w-full",
    greenTitle: "text-[20px] leading-[100%] mlg:text-[40px] font-bite",
    greenText:
        "mt-[8px] mlg:mt-[16px] text-[16px] leading-[150%] mlg:text-[20px] font-medium font-poppins [&>p>strong]:text-[18px] [&>p>strong]:mlg:!text-[24px] [&>p>strong]:leading-[100%] [&>p>strong]:font-bite",

    butterfliesWrapper: "relative",
    butterflies: "statistics-butterflies",

    orangeWrapper:
        "statistics-orange flex flex-wrap md:flex-nowrap items-center justify-between mlg:gap-[50px] px-[16px] py-[34px] md:px-[24px] md:py-[32px] mlg:px-[48px] mlg:py-[56px] mt-[34px] md:mt-[16px] mlg:mt-[50px]",
    orangeContainer:
        "flex md:items-center w-full justify-between max-md:gap-[16px] [&>div]:max-md:w-full max-md:text-center",
    orangeCol: "flex flex-col md:flex-row items-center gap-[8px] md:gap-[16px]",
    orangeNumber:
        "flex shrink-0 items-center justify-center w-[48px] h-[48px] mlg:w-[56px] mlg:h-[56px] rounded-full text-[24px] leading-[100%] mlg:text-[32px] font-bite text-white",
    orangeLabel: "text-[18px] leading-[100%] mlg:text-[24px] font-bite",
    orangeText:
        "max-md:self-center text-[16px] leading-[150%] mlg:text-[20px] md:font-medium mlg:font-normal font-poppins text-center md:text-left",

    disclaimer: "mlg:px-[48px] mt-[32px] mlg:mt-[48px] text-[12px] leading-[150%] font-poppins",

    bgWrapper:
        "absolute bottom-0 right-[-400px] md:right-[-210px] slg:right-[-60px] mlg:right-[-210px] lg:right-[-150px] xlg:right-0 [&>svg]:h-[130px] [&>svg]:md:!h-[177px] [&>svg]:mlg:!h-[286px] z-[3]",
}
