import React from "react"
import PropTypes from "prop-types"
import useImage from "../../../hooks/useImage"
import Image from "../../UI/Image/Image"
import RichTextModel from "../../RichTextModel/RichTextModel"
import { defaultStyles as styles } from "./StatisticsTabs-tw-styles"

const StatisticsTab = ({ icon, iconLabel, columns }) => {
    const { foregroundImg } = useImage(icon)

    return (
        <div className={styles?.tabWrapper}>
            <div className={styles?.tabIcon}>
                <div className={styles?.tabIconLabel}>{iconLabel}</div>
                <Image contentfulImage={foregroundImg} className={styles?.tabIconImage} />
            </div>

            <div className={styles?.tabColumnsContainer}>
                {Array.isArray(columns) &&
                    columns.length > 0 &&
                    columns.map(tabCol => (
                        <div key={tabCol.sys.id} className={styles?.tabColumn}>
                            <div className={styles?.tabColumnNumber}>{tabCol.name}</div>
                            <div className={styles?.tabColumnText}>
                                <RichTextModel richText={tabCol.content} />
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
}

StatisticsTab.propTypes = {
    icon: PropTypes.object,
    iconLabel: PropTypes.string,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            content: PropTypes.object,
        })
    ),
}

export default StatisticsTab
