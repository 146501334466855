import * as React from "react"

const Twitter = (className, name) => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} data-action-detail={name}>
        <title>{"Twitter"}</title>
        <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM31.2178 7.95605L22.2833 18.3417H22.2827L32 32.4835H24.8534L18.3098 22.9604L10.1173 32.4835H8L17.3698 21.5925L8 7.95605H15.1466L21.3427 16.9738L29.1006 7.95605H31.2178ZM18.4339 20.3547L19.3833 21.7126V21.7131L25.8495 30.9621H29.1016L21.1776 19.6276L20.2282 18.2698L14.1323 9.54994H10.8802L18.4339 20.3547Z" fill="currentColor"/>
    </svg>
)

export default Twitter
