import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import PropTypes from "prop-types"
import clsx from "clsx"
import * as styles from "./ImageCardsLayout-tw-styles"
import ImageCard from "../UI/ImageCard/ImageCard"
import Link from "../UI/Link/Link"
import Typography from "../UI/Typography/Typography"
import { get } from "../../helpers/utils"
import arrowLeft from "../../assets/icons/arrow-left.png"
import arrowRight from "../../assets/icons/arrow-right.png"
import { gaEventClasses, gaEventLabels } from "../../constants/gtm.constants"
import useIsScrolledToSection from "../../hooks/useIsScrolledToSection"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { anim, decorAnim } from "../../helpers/anim.helpers"
import Image from "../UI/Image/Image"

function ArrowLeft({ onClick, className }) {
    return (
        <button
            className={clsx(
                className,
                "arrow_button_click absolute carousel-arrow bottom-0 translate-y-[-50%] left-0 mlg:left-[-68px] lg:left-[-88px] z-[1]"
            )}
            onClick={onClick}
            data-action-detail={gaEventLabels.arrowLeft}
        >
            <img src={arrowLeft} />
        </button>
    )
}

ArrowLeft.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string
}

function ArrowRight({ onClick, className }) {
    return (
        <button
            className={clsx(
                className,
                "arrow_button_click absolute carousel-arrow bottom-0 translate-y-[-50%] right-0 mlg:right-[-68px] lg:right-[-88px] z-[1]"
            )}
            onClick={onClick}
            data-action-detail={gaEventLabels.arrowRight}
        >
            <img src={arrowRight} />
        </button>
    )
}

ArrowRight.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string
}

const ImageCardsLayout = props => {
    const {
        cardsCollection = [],
        title,
        subtext,
        text,
        variant,
        link,
        isSlider,
        eventClass,
        cardItems = [],
        decorImage,
    } = props
    const sliderRef = useRef()
    const [currentSlide, setCurrentSlide] = useState(0)
    const [isDragging, setIsDragging] = useState(false)
    const finalCards = cardItems.length > 0 ? cardItems : get(cardsCollection, "items", [])
    const style = styles[variant] || styles.defaultStyle
    const cardsWrapper = useRef(null)

    useIsScrolledToSection(cardsWrapper, "anim-on")

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)

        if (!cardsWrapper.current) return

        if (variant === "AMBITION_CARDS") {
            ScrollTrigger.create({
                trigger: cardsWrapper.current,
                start: "top 70%",
                animation: anim(cardsWrapper.current, ".ambition-card")
            })
        }

        if (variant === "BRAND_LOGOS") {
            ScrollTrigger.create({
                trigger: cardsWrapper.current,
                start: "top 70%",
                animation: anim(cardsWrapper.current, ".brand-logo", .2, 100)
            })

            const decors = cardsWrapper.current.querySelectorAll(".decor")
            if (!decorImage || !decors) return
            decorAnim(decors)
        }
    }, [])

    const settings = {
        dots: true,
        arrows: true,
        swipe: true,
        swipeToSlide: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        variableWidth: false,
        infinite: false,
        prevArrow: <ArrowLeft />,
        nextArrow: <ArrowRight />,
        beforeChange: (oldIndex, newIndex) => {
            setIsDragging(true)
            setCurrentSlide(newIndex)
        },
        afterChange: currentIndex => {
            setIsDragging(false)
        },
        appendDots: dots => (
            <div>
                <ul className={style.dotList}>{dots}</ul>
            </div>
        ),
        customPaging: function(i) {
            return (
                <span
                    className={clsx(style.dotListItem, "event_slider")}
                    onClick={() => setCurrentSlide(i)}
                    data-action-detail="Arrow button"
                ></span>
            )
        },
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const carouselVariants = {
        AMBITION_CARDS: {
            ...settings,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        centerPadding: "15%",
                        infinite: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }
    }

    const sliderConfig = carouselVariants[variant] || settings

    const buildSlides = () => {
        return finalCards?.map((card, index) => {
            switch (variant) {
                case "AMBITION_CARDS":
                    return (
                        <div key={index} className={style?.linkCardsWrpr} id={card?.elementId}>
                            <ImageCard {...card} />
                        </div>
                    )
                default:
                    return (
                        <div key={index} className={style?.linkCardsWrpr} id={card?.elementId}>
                            {card?.link ? (
                                <Link {...card.link} className={style?.linkWrpr}>
                                    <ImageCard {...card} />
                                </Link>
                            ) : (
                                <ImageCard {...card} />
                            )}
                        </div>
                    )
            }
        })
    }

    const buildSlider = () => {
        if (isSlider) {
            return (
                <div className={style?.cardWrpr}>
                    <Slider {...sliderConfig} className={style?.sliderMob} ref={sliderRef}>
                        {buildSlides()}
                    </Slider>
                </div>
            )
        } else {
            return <div className={style?.cardsWrapr}>{buildSlides()}</div>
        }
    }

    return (
        <div ref={cardsWrapper} className={style?.containerWrpr}>
            {decorImage && <Image contentfulImage={decorImage} className={style?.decorImage} />}
            <div className={style?.container}>
                <div className={style?.textContentWrapper}>
                    <div className={style?.textContentContainer}>
                        {title && <Typography className={style?.heading} content={title} />}
                        {text && <Typography className={style?.description} allow_br_tag={true} content={text} />}
                        {subtext && <Typography className={style?.subtext} content={subtext} />}
                    </div>
                </div>

                {finalCards?.length > 0 && buildSlider()}

                {link?.label && (
                    <div className={style?.btnContainer}>
                        <Link {...link} className={style?.btnWrpr} />
                    </div>
                )}
            </div>
        </div>
    )
}

ImageCardsLayout.propTypes = {
    cardsCollection: PropTypes.object,
    title: PropTypes.string,
    subtext: PropTypes.string,
    text: PropTypes.string,
    variant: PropTypes.string,
    link: PropTypes.object,
    isSlider: PropTypes.bool,
    eventClass: PropTypes.string,
    cardItems: PropTypes.array,
    decorImage: PropTypes.object,
}

export default ImageCardsLayout
