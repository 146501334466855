import React, { useState } from "react"
import PropTypes from "prop-types"
import AccordionItem from "../Accordion/AccordionItem"
import * as styles from "./Accordion-tw-styles"
const Accordion = props => {
    const { accordionItemsCollection, title, elementId } = props
    const style = styles.defaultStyle
    const items = accordionItemsCollection.items
    const [openedIndex, setOpenedIndex] = useState(-1)
    if (!Array.isArray(items) || items.length === 0) {
        return null
    }

    return (
        <div id={elementId || null} className={style?.accordionWrapper} itemScope itemType="https://schema.org/FAQPage">
            <h2 className={style?.accordionTitle}>{title}</h2>
            <div className={style?.accordionInner}>
                {items.map((data, index) => (
                    <AccordionItem
                        key={data.sys.id}
                        setOpenedIndex={setOpenedIndex}
                        isOpen={openedIndex === index}
                        index={index}
                        {...data}
                    />
                ))}
            </div>
        </div>
    )
}

Accordion.propTypes = {
    title: PropTypes.string,
    accordionItemsCollection: PropTypes.object,
    elementId: PropTypes.string,
}

export default Accordion
